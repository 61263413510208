import { useQuery } from "@tanstack/react-query"
import { Modal, Spin } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import dayjs from "dayjs"
import api from "helpers/api"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { useCostCollectorStore } from "../CostCollector.store"
import { useCostCollectorAttributeStore } from "./CostCollectorAttributes.store"

type HistoryItem = {
    actionExplanation: string
    startAt: string
    value: number
    marginValue?: number
    marginFirst?: boolean
}

type HistoryResponse = HistoryItem[]

export default function CostCollectorAttributeHistoryModal() {
    const { selectedRows } = useCostCollectorStore()
    const { historyPopup, activeAttributeName } =
        useCostCollectorAttributeStore()

    const activeRow = selectedRows[0]

    const region = useSelector((state: RootState) => state.region.region)

    const { data, isFetching, refetch } = useQuery<HistoryResponse>({
        queryKey: [historyPopup.visibleFor, activeRow, region, historyPopup],
        enabled: !!historyPopup.visibleFor && !!activeRow && !!region,
        queryFn: () =>
            api.getTyped<HistoryResponse>(
                `v1/economics/attribute/price-history`,
                {
                    model: activeRow?.id,
                    attributeValue: historyPopup.visibleFor?.id,
                    region: region?.value,
                }
            ),
    })

    return (
        <Modal
            open={!!historyPopup.visibleFor}
            afterOpenChange={(open) => {
                if (open) refetch()
            }}
            title={
                <div>
                    <span>
                        История изменения цен
                        <br />
                        Значение - <b>{historyPopup.visibleFor?.name}</b>
                        <br />
                        Аттрибут - <b>{activeAttributeName}</b>
                        <br />
                        Модель - <b>{activeRow?.name}</b>
                    </span>
                </div>
            }
            onCancel={historyPopup.hide}
            cancelText="Закрыть"
            okButtonProps={{ hidden: true }}
        >
            <Flex.Col className="fw" styles={{ marginTop: 20 }}>
                {isFetching && <Spin size="large" />}
                {data && (
                    <UTable
                        data={data}
                        columns={[
                            {
                                columnName: "Дата",
                                render: (row) =>
                                    dayjs(row.startAt).format("YYYY-MM-DD"),
                                width: 25,
                            },
                            {
                                columnName: "Цена",
                                render: (row) => row.value,
                                width: 25,
                            },
                            {
                                columnName: "Процент наценки",
                                render: (row) => row.marginValue ?? "",
                                width: 25,
                            },
                            {
                                columnName: "Сперва наценка",
                                render: (row) =>
                                    typeof row.marginFirst !== "undefined" ? (
                                        <Flex.Row justify="center">
                                            <input
                                                type="checkbox"
                                                disabled
                                                checked={row.marginFirst}
                                                className="form-check-input"
                                            />
                                        </Flex.Row>
                                    ) : (
                                        ""
                                    ),
                                width: 25,
                            },
                        ]}
                    />
                )}
            </Flex.Col>
        </Modal>
    )
}
