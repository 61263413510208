import { Col, Row } from "antd"
import { ApiMultiSelect } from "components/Selects"
import { useMemo } from "react"
import { Uuid } from "types"
import { LinkingDetailItem } from "./NamingSelect"

export default function AccountingSelectSeries({
    attributes,
}: {
    activeGroupId: Uuid
    attributes: LinkingDetailItem[]
}) {
    const forNamingAttributes = useMemo(() => {
        return attributes.filter(
            (el) => el.forAccountingNaming && el.sortAccountingNaming
        )
    }, [attributes])

    const { namingDefaults } = useMemo(
        () => ({
            namingDefaults: forNamingAttributes
                .filter((v) => v.forAccountingNaming && v.sortAccountingNaming)
                .sort((a, b) => a.sortAccountingNaming - b.sortAccountingNaming)
                .map((v) => ({
                    value: v.id,
                    label: v.attribute.name,
                    sort: v.sortAccountingNaming,
                })),
        }),

        [forNamingAttributes]
    )

    return (
        <Row gutter={[15, 10]}>
            <Col span={24}>
                <Row gutter={[15, 0]}>
                    <Col md={24} xl={6}>
                        Бухгалтерское
                    </Col>
                    <Col md={24} xl={18}>
                        <ApiMultiSelect
                            defaultValue={namingDefaults}
                            options={attributes.map(({ attribute, id }) => ({
                                value: id,
                                label: attribute.name,
                                sort: attribute.sort,
                            }))}
                            urls={{
                                add: () => `v1/series/links/accounting-naming`,
                                remove: () =>
                                    `v1/series/links/accounting-naming`,
                                getAddParams(list) {
                                    return list.reduce((acc, next, i) => {
                                        return {
                                            ...acc,
                                            [next.value]: i + 1,
                                        }
                                    }, {} as Record<Uuid, number | null>)
                                },
                                getRemoveParams(list, prop) {
                                    if (!list.length) {
                                        return {
                                            [prop.removedValue!.value]: null,
                                        }
                                    }
                                    return list.reduce((acc, next, i) => {
                                        return {
                                            ...acc,
                                            [next.value]: i + 1,
                                            [prop.removedValue!.value]: null,
                                        }
                                    }, {} as Record<Uuid, number | null>)
                                },
                                remodeMethod: "post",
                                addMethod: "post",
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
