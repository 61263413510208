import { Uuid } from "types"
import { MeasureRulesItem, SelectOption } from "./Model"
import api from "helpers/api"
import { useQuery } from "@tanstack/react-query"
import Select, { DefaultOptionType, SelectProps } from "antd/es/select"
import { Col, Row } from "antd"
import { Flex } from "components/UI/Flex"
import { RemoveButton } from "components/UI"

const filterSelect: SelectProps["filterOption"] = (input, option) => {
  return (
      option?.label
          ?.toLocaleString()
          .toLowerCase()
          .includes(input.toLowerCase()) || false
  )
}

export default function ModelAdditionalDatailItem({
  index,
  data,
  options,
  onChangeRules,
  attributes,
  model,
  removeItem,
}: {
  data: MeasureRulesItem
  index: number
  options: SelectOption[]
  attributes: SelectOption[]
  model: Uuid
  removeItem: (index: number) => void
  onChangeRules: (
      metricIndex: number,
      type: keyof MeasureRulesItem,
      value: Uuid | null
  ) => void
}) {
  const { data: valuesOption, isLoading } = useQuery<DefaultOptionType[]>({
      queryKey: [data.attribute, model],
      enabled: !!data.attribute,
      queryFn: () =>
          api
              .getTyped<Record<Uuid, string>>(`v1/model/measure-unit/values-list`, {
                  model: model,
                  attribute: data.attribute,
              })
              .then((response) =>
                  Object.entries(response).map(
                      ([key, value]) =>
                          ({
                              label: value,
                              value: key,
                          } as DefaultOptionType)
                  )
              ),
      initialData: [],
  })

  return (
      <Row className="fw" gutter={[10, 10]}>
          <Col span={8}>
              <Flex.Col className="fw" align="start">
                  <label className="col-form-label">Единица измерения</label>
                  <Select
                      size="large"
                      className="fw form-input"
                      options={options}
                      value={data.id || undefined}
                      onChange={(v) => onChangeRules(index, "id", v)}
                      allowClear
                      showSearch
                      filterOption={filterSelect}
                  />
              </Flex.Col>
          </Col>
          <Col span={7}>
              <Flex.Col className="fw" align="start">
                  <label className="col-form-label">Аттрибут</label>
                  <Select
                      size="large"
                      className="fw"
                      disabled={!data.id}
                      options={attributes}
                      value={data.attribute || undefined}
                      onChange={(v) => onChangeRules(index, "attribute", v)}
                      allowClear
                      showSearch
                      filterOption={filterSelect}
                  />
              </Flex.Col>
          </Col>
          <Col span={7}>
              <Flex.Col className="fw" align="start">
                  <label className="col-form-label">Значение</label>
                  <Select
                      size="large"
                      className="fw"
                      options={valuesOption}
                      disabled={!data.attribute}
                      loading={isLoading}
                      value={data.value || undefined}
                      onChange={(v) => onChangeRules(index, "value", v)}
                      allowClear
                      showSearch
                      filterOption={filterSelect}
                  />
              </Flex.Col>
          </Col>
          <Col span={2}>
              <Flex.Col align="center" justify="end" fullWidth fullHeight>
                  <RemoveButton
                      onClick={() => {
                          removeItem(index)
                      }}
                  />
              </Flex.Col>
          </Col>
      </Row>
  )
}