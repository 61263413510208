import { useQuery } from "@tanstack/react-query"
import { Col, Row } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { AttributesChooser } from "./AttributesChooser"
import { AttributeValuesChooser } from "./AttributeValuesChooser"
import { ModelsChooser } from "./ModelsChooser"
import { ActiveEntityType, EntityType, ModelAttribute } from "./types"

interface ModelsValuesBindingChoosersProps {
    groupId: Uuid
    producerId: Uuid
    onChangeActiveSeries: (id?: Uuid) => void
}

export const ModelsValuesBindingChoosers: React.FC<
    ModelsValuesBindingChoosersProps
> = ({ groupId, producerId, onChangeActiveSeries }) => {
    const [activeEntity, setActiveEntity] = useState<
        ActiveEntityType | undefined
    >()

    const [activeAttribute, setActiveAttribute] = useState<Uuid | undefined>()
    const [activeLink, setActiveLink] = useState<ModelAttribute | undefined>()
    const [activeSeries, setActiveSeries] = useState<Uuid | null>(null)

    useEffect(() => setActiveEntity(undefined), [groupId, producerId])
    const { refetch: refetchAttribute } = useQuery<ModelAttribute>({
        queryKey: [
            "model-link",
            activeAttribute,
            activeEntity,
            groupId,
            producerId,
        ],
        onSuccess: (data: any) => setActiveLink(data),
        enabled: !!activeEntity && !!activeAttribute,
        onSettled: () => onChangeActiveSeries(activeEntity?.parentId),
        queryFn: () =>
            activeEntity?.entity === EntityType.model
                ? api
                      .post<any, any>(
                          `${activeEntity!.entity}/get-attribute-link`,
                          {},
                          {
                              attribute: activeAttribute!,
                              target: activeEntity!.id,
                              series: activeEntity?.parentId,
                              itemsGroup: groupId,
                              producer: producerId,
                          }
                      )
                      .then(({ data }) => data)
                : api.getTyped<ModelAttribute>(
                      `v1/${activeEntity?.entity}/${
                          activeEntity?.id
                      }/items-group/${
                          "" + groupId
                      }/attribute/${activeAttribute}/link`
                  ),
    })

    const { data = [], refetch: refetchAttributeList } = useQuery<
        ModelAttribute[]
    >({
        queryKey: ["model-links", activeEntity],
        enabled: !!activeEntity,
        queryFn: () =>
            api
                .get<any>(
                    activeEntity?.entity === "series"
                        ? `v1/series/${activeEntity.id}/items-group/${groupId}/attribute-links`
                        : `v1/series/${activeEntity?.parentId}/items-group/${groupId}/attribute-links`
                )
                .then((response) => response.data),
    })

    const handleAttributeConfigurability = async (id: Uuid, state: boolean) => {
        api.post(
            `${
                activeEntity!.entity
            }/attribute-link-set-attribute-configurability`,
            {},
            {
                id,
                state,
            }
        )
        refetchAttributeList()
    }

    useEffect(() => {
        setActiveLink(undefined)
        if (activeEntity?.entity === "series") {
            setActiveSeries(activeEntity.id)
        }
    }, [activeEntity])

    useEffect(() => {
        setActiveSeries(null)
    }, [groupId, producerId])

    const entityData = useMemo(() => {
        return {
            group: groupId,
            producer: producerId,
            series: activeSeries,
        }
    }, [groupId, producerId, activeSeries])

    return (
        <Row gutter={[10, 10]} className="mb-3">
            <Col span={24} xl={{ span: 12 }}>
                <ModelsChooser
                    active={activeEntity}
                    onChangeActive={(entity) => {
                        setActiveAttribute(undefined)
                        setActiveEntity(entity)
                    }}
                    activeLink={activeLink}
                    groupId={groupId}
                    producerId={producerId}
                />
            </Col>
            <Col span={24} xl={{ span: 12 }}>
                {activeEntity && data && (
                    <AttributesChooser
                        attributes={activeEntity?.entity === "series" ? data : data.filter(({ series }) => !!series)}
                        activeLink={activeLink}
                        active={activeAttribute}
                        entityType={activeEntity.entity}
                        entityData={entityData}
                        onChangeActive={(id) => setActiveAttribute(id)}
                        onToggleActiveRowConfigurability={
                            handleAttributeConfigurability
                        }
                        setActiveLink={setActiveLink}
                    />
                )}
            </Col>

            <Col span={24} xl={{ span: 24 }}>
                {activeLink && (
                    <AttributeValuesChooser
                        activeLink={activeLink}
                        groupId={groupId}
                        refetchAttribute={refetchAttribute}
                        activeEntity={activeEntity}
                    />
                )}
            </Col>
        </Row>
    )
}
