type ObjectColumnData = {
    value: any
}

export default function AttributeValueCell({ value }: ObjectColumnData) {
    if (!value) return <></>

    if (typeof value === "string") {
        return <span>{value === "null" ? "" : value}</span>
    }

    if (typeof value === "object") {
        return <span>{value.id ? value.id : ""}</span>
    }

    return <></>
}
