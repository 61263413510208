import { Checkbox, Tag } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import dayjs from "dayjs"
import { useAttributeValuesPricesStore } from "./AttributeValuesPrices.store"
import { GetAttributeValuesListingItem } from "./AttributeValuesPricesForm"

export default function AttributeValuesPricesListing({
    loading,
    data,
    tableKey,
}: {
    data: GetAttributeValuesListingItem[]
    loading: boolean
    tableKey: number
}) {
    const { activeListingValue, setActiveListingValue } =
        useAttributeValuesPricesStore()

    return (
        <Flex.Col fullWidth gap={20} align="start">
            <h5>Созданные комбинации</h5>
            <UTable
                key={tableKey}
                loading={loading}
                tableProps={{
                    fontSize: 14,
                    noCopy: true,
                }}
                isRowActive={(row) => activeListingValue?.id === row.id}
                onRowClick={(row) =>
                    setActiveListingValue(
                        activeListingValue?.id === row.id ? null : row
                    )
                }
                data={data}
                columns={[
                    {
                        columnName: "Сочетания значений",
                        render: (row) => (
                            <Flex.Row
                                fullWidth
                                justify="start"
                                styles={{ overflowX: "auto" }}
                            >
                                {row.attributesWithValuesMap.map((el) => {
                                    return (
                                        <Tag key={el.attributeValueId}>
                                            {el.attributeName}-{" "}
                                            <b>{el.value}</b>
                                        </Tag>
                                    )
                                })}
                            </Flex.Row>
                        ),
                    },
                    {
                        columnName: "Дата",
                        render: (row) =>
                            dayjs(row.startAt).format("DD-MM-YYYY hh:mm"),
                        width: 12,
                    },
                    {
                        columnName: "Цена",
                        render: (row) => row.value,
                        width: 8,
                    },
                    {
                        columnName: "% наценки",
                        render: (row) => row.marginValue,
                        width: 8,
                    },
                    {
                        columnName: "Сначала процент",
                        render: (row) => <Checkbox checked={row.marginFirst} />,
                        width: 8,
                    },
                    {
                        columnName: "Учитывать только сочетание",
                        render: (row) => (
                            <Checkbox checked={row.calculateOnlyCombination} />
                        ),
                        width: 8,
                    },
                ]}
            />
        </Flex.Col>
    )
}
