/** LISTING */
const initialState = {
    isEmptyFilter: false,
    initDataRecieved: false
}

export const listing = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SET_LISTING":
            return {
                ...state,
                initDataRecieved: true,
                ...action.payload,
            }

        case "CLEAR_LISTING":
            return {
                initDataRecieved: state.initDataRecieved,
                isEmptyFilter: state.isEmptyFilter,
            }
        case "CHANGE_VALUE": {
            const id = action.payload.id
            const name = action.payload.name
            const value = action.payload.value

            const result = state.entities.map((item: any) => {
                if (item.id === id) {
                    item.fields.map((item: any) => {
                        if (item.name === name) {
                            return (item.value = value)
                        }
                        return item
                    })
                }
                return item
            })

            return {
                ...state,
                entities: result,
            }
        }

        case "SET_CATEGORY_ID": {
            return {
                ...state,
                categoryID: action.payload,
            }
        }

        case "DEL_ROW": {
            const id = action.payload.id
            const result = state.entities.filter((item: any) => item.id !== id)

            return {
                ...state,
                entities: result,
            }
        }

        case "DEL_GROUP_ROW": {
            const id = action.payload.id

            let result: any = [...state.entities]
            id.map(
                (id: any) =>
                    (result = result.filter((item: any) => item.id !== id))
            )

            return {
                ...state,
                entities: result,
            }
        }

        case "SET_EMPTY_FILTER":
            return {
                ...state,
                isEmptyFilter: true,
            }

        case "CLEAR_EMPTY_FILTER":
            return {
                ...state,
                isEmptyFilter: false,
            }

        default:
            return state
    }
}
