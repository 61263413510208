import React from 'react';
import { InputText } from './components/InputText';
import { InputSelect } from './components/InputSelect';

interface AttributeComponentSelecterProps {
  field: { [key: string]: any };
  onClickHandler?: any;
}

const renderAssociate: { [key: string]: any } = {
  'number': InputText,
  'string': InputText,
  'list': InputSelect,
};

export const AttributeComponentSelecter: React.FC<AttributeComponentSelecterProps> = ({ field, onClickHandler }) => {
  for (const key in renderAssociate) {
    const ComponentName = renderAssociate[key];
    if (key === field.type) return <ComponentName {...field} onClickHandler={onClickHandler} />;
  }

  return null;
};
