import { TableProps } from "antd";

const tableLocale: TableProps<any>['pagination'] = {
  locale: {
    page: "Страница",
    next_page: "Следующая страница",
    prev_page: "Предыдущая страница",
    items_per_page: "на странице",
  }
}

export const tablePaginationLocale = tableLocale.locale