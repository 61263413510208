import { useMutation } from "@tanstack/react-query"
import { Modal } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { isDefined } from "helpers/checkType"
import { useNotifications } from "hooks/useNotifications"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { Uuid } from "types"
import { useCostCollectorStore } from "./CostCollector.store"

type PostPriceItem = {
    model: Uuid
    region: string
    value: number
    action_explanation: string
    start_at: string | null
}

type PostPriceData = {
    payload: PostPriceItem[]
}

export default function CostCollectorModelsModal({
    refetch,
}: {
    refetch: () => void
}) {
    const {
        sendModalVisible,
        hideSendModal,
        seriesTableData,
        modelPrecision,
        modelDate,
        producer,
        currentCurrency,
        afterRefetchModels,
    } = useCostCollectorStore()

    const { showNotification } = useNotifications()

    const currentRegion = useSelector((state: RootState) => state.region.region)

    const tableData = useMemo(() => {
        return seriesTableData
            .filter((el) => el.newPrice)
            .map((el) => {
                const newPrice = el.newPrice!
                return {
                    ...el,
                    newPrice,
                }
            })
    }, [seriesTableData, modelPrecision])

    const { mutate, isLoading } = useMutation({
        mutationKey: [modelDate, tableData, currentRegion],
        mutationFn: () => {
            return api.post<PostPriceData, any>(
                "v1/economics/model/prices",
                {},
                {
                    payload: tableData.map((el) => ({
                        model: el.id,
                        region: currentRegion!.value,
                        value: el.newPrice,
                        action_explanation: "",
                        start_at: modelDate?.format("YYYY-MM-DD") ?? null,
                    })),
                }
            )
        },
        onSuccess: () => {
            showNotification({
                message: "Сохранено",
                type: "success",
            })
            refetch()
            hideSendModal()
            afterRefetchModels()
        },
        onError: (error: any) => {
            showNotification({
                message: error.message,
                type: "danger",
            })
        },
    })

    return (
        <>
            <Modal
                visible={sendModalVisible}
                onCancel={hideSendModal}
                onOk={() => mutate()}
                okButtonProps={{
                    loading: isLoading,
                    className: "fw",
                }}
                okText="Добавить изменение цен на указанные модели"
                title={"Просмотр цен"}
                cancelButtonProps={{
                    hidden: true,
                }}
            >
                <Flex.Col gap={10}>
                    <Flex.Row gap={10} className="fw" justify="start">
                        <span>Дата установки цен -</span>
                        <span style={{ fontWeight: "bold" }}>
                            {modelDate?.format("YYYY-MM-DD")}
                        </span>
                    </Flex.Row>
                    <Flex.Row gap={10} className="fw" justify="start">
                        <span>Поставщик - </span>
                        <span style={{ fontWeight: "bold" }}>
                            {producer?.label}
                        </span>
                    </Flex.Row>
                    <Flex.Row gap={10} className="fw" justify="start">
                        <span>Валюта - </span>
                        <span style={{ fontWeight: "bold" }}>
                            {currentCurrency?.name}
                        </span>
                    </Flex.Row>
                    <UTable
                        data={tableData}
                        columns={[
                            {
                                columnName: "Модель",
                                render: (row) => row.name,
                                width: 50,
                            },
                            {
                                columnName: "Старая цена",
                                render: (row) => row.oldPrice!,
                                width: 25,
                            },
                            {
                                columnName: "Новая цена",
                                render: (row) => row.newPrice!,
                                width: 25,
                            },
                        ]}
                    />
                </Flex.Col>
            </Modal>
        </>
    )
}
