import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import api from "../../../helpers/api"
import { usePresetHelper } from "../../../hooks/usePresetHelper"
interface PresetControlProps {
    categoryID: number
    resetHandler: () => void
    activePreset: number
    setActivePreset: any
    preFIlteringHandler: () => void
}

type preset = {
    id?: number
    presetName: string
    presetQuery: string
    categoryId: number | null
}

const PresetControl: React.FC<PresetControlProps> = ({
    categoryID,
    resetHandler,
    activePreset,
    setActivePreset,
    preFIlteringHandler,
}) => {

    const resolveRoute = usePresetHelper()
    const location: { [key: string]: any } = useLocation()

    const pageID: string = location.state.pageID || ""

    const currentValuesFilter = useSelector(
        (state: { [key: string]: any }) => state.fields?.currentValues.filter
    )
    const currentValuesAttributeFilter = useSelector(
        (state: { [key: string]: any }) =>
            state.fields?.currentValues.attributeFilter
    )
    const compareType = useSelector(
        (state: { [key: string]: any }) => state.compareType
    )

    const [preset, setPreset] = React.useState<any>([])

    const [presetName, setPresetName] = React.useState<string>("")
    const [isShowInput, setIsShowInput] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>("")

    const parsingPresetQuery = (id: number) => {
        const currentPreset = preset.find((item: any) => item.id === id)
        const presetQuery: any = currentPreset?.presetQuery

        setActivePreset(id)
        resolveRoute(presetQuery, currentPreset.categoryId)
    }

    const savePresetHandler = () => {
        preFIlteringHandler()

        const sliceSemicolon = (endPoint: string): string => {
            if (endPoint.slice(-1) === ";") {
                return endPoint.slice(0, -1)
            } else {
                return endPoint
            }
        }

        let stringifyFilter = ""
        for (const key in currentValuesFilter) {
            if (currentValuesFilter[key].length > 0) {
                stringifyFilter +=
                    (compareType[key] === "eq"
                        ? `${key}=${currentValuesFilter[key]};`
                        : `${key}--${
                              compareType[key] ? compareType[key] : "eq"
                          }--${currentValuesFilter[key]};`)
            }
        }

        let stringifyAttributeFilter = ""

        for (const key in currentValuesAttributeFilter) {
            if (typeof currentValuesAttributeFilter[key] === "string") {
                stringifyAttributeFilter += `${
                    currentValuesAttributeFilter[key] !== ""
                        ? `${key}--${
                              compareType[key] ? compareType[key] : "eq"
                          }--${currentValuesAttributeFilter[key]};`
                        : ""
                }`
            } else {
                stringifyAttributeFilter += `${
                    !currentValuesAttributeFilter[key].includes("", 0)
                        ? `${key}--${
                              compareType[key]
                          }--${currentValuesAttributeFilter[key].join("|")};`
                        : ""
                }`
            }
        }

        const filterParams = stringifyFilter ? `&filter=${stringifyFilter}` : ""
        const attributeFilterParams = stringifyAttributeFilter
            ? `&attributeFilter=${stringifyAttributeFilter}`
            : ""
        const cat = categoryID > 1 ? `&cat=${categoryID}` : ""

        const params =
            cat +
            sliceSemicolon(filterParams) +
            sliceSemicolon(attributeFilterParams)

        const presetPayload: preset = {
            categoryId: categoryID > 1 ? categoryID : null,
            presetName,
            presetQuery: params,
        }

        api.post(`filter_preset/${pageID}`, {}, presetPayload)
            .then((res: any) => {
                if (res.status === 200) {
                    presetPayload.id = res.data
                    setPreset([...preset, presetPayload])
                    setPresetName("")
                    setIsShowInput(false)
                    setActivePreset(res.data)
                }
            })
            .catch((err: any) => {
                setError(err.response.data)
                const timeoutError = setTimeout(() => {
                    setError("")
                }, 3000)
                return () => clearTimeout(timeoutError)
            })
    }

    const deletePresetHandler = (id: number) => {
        api.get(`filter_preset/${pageID}/${id}/delete`)
            .then((res: any) => {
                if (res.status === 200) {
                    setPreset(preset.filter((item: any) => item.id !== id))
                }
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

    React.useEffect(() => {
        api.get(`filter_preset/${pageID}`)
            .then((res: any) => {
                setPreset(res.data)
            })
            .catch((err: any) => {
                console.log(err)
            })
    }, [pageID])

    return (
        <div className="d-flex flex-column align-items-start justify-content-between  h-100">
            <div className="d-flex flex-column align-items-start">
                <div className="d-flex align-items-center">
                    <button
                        type="button"
                        className={`btn btn-link ${
                            !activePreset || activePreset === 0
                                ? "preset-active"
                                : ""
                        }`}
                        onClick={() => {
                            if (activePreset) {
                                resetHandler()
                                setActivePreset(0)
                            }
                        }}
                    >
                        По умолчанию
                    </button>
                    {/* <button className="btn btn-link d-none preset-active-close">&times;</button> */}
                </div>

                {preset.map((item: any) => (
                    <div
                        key={item.id}
                        className="d-flex align-items-center preset-item"
                    >
                        <button
                            type="button"
                            className={`btn btn-link ${
                                activePreset === item.id ? "preset-active" : ""
                            }`}
                            onClick={() =>
                                activePreset !== item.id
                                    ? parsingPresetQuery(item.id)
                                    : null
                            }
                        >
                            {item.presetName}
                        </button>
                        <button
                            className={`btn btn-link d-none ${
                                activePreset === item.id
                                    ? ""
                                    : "preset-active-close"
                            }`}
                            onClick={() => deletePresetHandler(item.id)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>

            <div className="d-block">
                <span className="text-danger">
                    <small>{error}</small>
                </span>
                {!isShowInput ? (
                    <>
                        {/* <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => setIsShowInput(!isShowInput)}
                        >
                            + Сохранить фильтр
                        </button> */}
                    </>
                ) : (
                    <>
                        <div className="d-flex">
                            <input
                                className="form-control"
                                type="text"
                                value={presetName}
                                onChange={(event) =>
                                    setPresetName(event.target.value)
                                }
                                placeholder="Название фильтра"
                            />
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={!presetName}
                                onClick={savePresetHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default PresetControl
