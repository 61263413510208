import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Uuid } from 'types';

interface TextProps {
  id: Uuid;
  value: any;
  page: string;
  options: { label: string; value: string }[]
  listing: { [key: string]: any };
}

export const Select: React.FC<TextProps> = ({ id, value, listing = {}, page, options }) => {
  const { isSearchResult } = useSelector((state: any) => state.isSearchResult);
  const href = isSearchResult ? `good/update-${id}` : `${page}/update-${id}`;

  const label = options.find(({ value: optVal }) => optVal === value)?.label ?? value;

  return listing.isLinkToDetail && listing.isLinkToDetail !== 'undefined' ? (
    <Link className="" to={href}>
      {label}
    </Link>
  ) : (
    <span>{label}</span>
  );
};
