import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_VALUES } from '../../store/fields/actions';
import { InputProps } from '../../types/interfaces';

interface Data {
  entity: string;
  entityLabel: string;
  right: string;
  rightLabel: string;
  isActive: boolean;
}

export interface Right {
  right: string;
  rightLabel: string;
  isActive: boolean;
}

export interface PrepareData {
  entity: string;
  entityLabel: string;
  rights: Right[];
}

const RolesCheckboxes: React.FC<InputProps> = ({ propertyContainer, group }) => {
  const dispatch = useDispatch();

  const data: Array<Data> =
    useSelector((state: { [key: string]: any }) => state.fields.currentValues[group].data) || [];

  const cloneData = data.map((arr: {}) => ({ ...arr }));

  const prepareData: any = [];

  data.forEach((item: Data) => {
    const index = prepareData.findIndex((el: PrepareData) => el.entity === item.entity);
    if (index === -1) {
      prepareData.push({
        entity: item.entity,
        entityLabel: item.entityLabel,
        rights: [
          {
            right: item.right,
            rightLabel: item.rightLabel,
            isActive: item.isActive,
          },
        ],
      });
    } else {
      prepareData[index].rights.push({
        right: item.right,
        rightLabel: item.rightLabel,
        isActive: item.isActive,
      });
    }
  });

  const onChangeGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    cloneData.map((item: any) => {
      if (item.entity === event.target.dataset.entity) {
        return (item.isActive = event.target.checked);
      }

      return null;
    });

    dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: cloneData }, group));
  };

  const onChangeRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    cloneData.map((item: any) => {
      if (item.right === event.target.dataset.right && item.entity === event.target.dataset.entity) {
        return (item.isActive = event.target.checked);
      }

      return null;
    });

    dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: cloneData }, group));
  };

  return (
    <div className="row mb-3">
      <label className="col-xl-3 col-form-label">{propertyContainer.get('label')}</label>
      <div className="col-xl-9">
        {prepareData.map((item: any, index: number) => {
          return (
            <div className="parent-check mb-4" key={index}>
              <input
                type="checkbox"
                className="form-check-input me-2"
                id={`${item.entity}-${index}`}
                checked={item.rights.every((right: Right) => right.isActive)}
                data-entity={item.entity}
                onChange={(event) => {
                  onChangeGroup(event);
                }}
              />
              <label htmlFor={`${item.entity}-${index}`}>{item.entityLabel}</label>
              {item.rights.map((right: Right, index: number) => {
                return (
                  <div className="child-check" key={index}>
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      id={`${item.entity}-${right.right}-${index}`}
                      checked={right.isActive}
                      data-entity={item.entity}
                      data-right={right.right}
                      onChange={(event) => {
                        onChangeRight(event);
                      }}
                    />
                    <label htmlFor={`${item.entity}-${right.right}-${index}`}>{right.rightLabel}</label>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RolesCheckboxes;
