import { Country } from "types/api"
import { defaultPaginator } from "types/global.settings"
import { PaginatorState, QueryBulderState } from "types/interfaces"
import { create } from "zustand"
import { RequesiteType } from "./Requesites.settings"

type RequesitesStore = {
    selectedRequisite: RequesiteType | null
    setSelectedRequisite: (v: RequesiteType | null) => void
    regions: Country[]
    setRegion: (v: Country) => void
    isSetRegion: (v: Country) => boolean
    setProps: (v: string | string[] | null) => void
    props: string | string[] | null
} & QueryBulderState &
    PaginatorState

export const useRequisitesStore = create<RequesitesStore>((set, get) => ({
    selectedRequisite: null,
    setSelectedRequisite: (v) =>
        set((state) => ({
            ...state,
            selectedRequisite: v,
            props: v === "stock" ? [] : null,
        })),
    query: undefined,
    setQuery: (v) =>
        set((state) => ({
            ...state,
            query: v,
        })),
    regions: [],
    setRegion: (v: Country) => {
        return set((state) => {
            const regions = state.regions.some((el) => el.id === v.id)
                ? state.regions.filter((el) => el.id !== v.id)
                : [...state.regions, v]
            return {
                ...state,
                regions: regions,
                props: regions.map((el) => el.id),
            }
        })
    },
    isSetRegion(v) {
        return get().regions.some((el) => el.id === v.id)
    },
    paginator: defaultPaginator,
    setPaginator(v) {
        return set((state) => ({
            ...state,
            paginator: v,
        }))
    },
    props: null,
    setProps(v) {
        return set((state) => {
            return {
                ...state,
                props: v,
            }
        })
    },
}))
