import { useLocation } from "react-router-dom"
import { ModelEntity } from "types/api"
import ModelAdditionalDatail from "./Model"
import React from "react"
import ModelAttributes from "./ModelAttributes"

export const builders = {
    model: (data: any) => ModelAdditionalDatail({ data }),
} as Record<string, (data: any) => JSX.Element>

export const DetailAdditionalData = ({ data }: { data: ModelEntity }) => {

    const location = useLocation()
    const state = location.state as { pageID?: string }
    const pageID = state.pageID
    if (!pageID) return null

    const Component = builders[pageID]

    return Component ? Component(data) : null
}

export const additionInputsBuilder = {
    model: (data: any) => React.createElement(ModelAttributes, { data, }),
} as Record<string, (data: any) => JSX.Element>

export const DetailAdditionalFields = ({ data }: { data: ModelEntity }) => {

    const location = useLocation()
    const state = location.state as { pageID?: string }
    const pageID = state.pageID
    if (!pageID) return null

    const Component = additionInputsBuilder[pageID]

    return Component ? Component(data) : null
}
