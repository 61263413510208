import { SelectEntity } from "components/Selects/types"
import { useEffect, useState } from "react"
import Select from "react-select"

const stallerModels: SelectEntity[] = [
    {
        value: "addd11cd-b34f-11e8-8118-005056bb2657",
        label: "Сталлер 2К ВН",
    },
    {
        value: "01900922-9d2c-7abc-adbe-7f87d684886c",
        label: "Сталлер 2К",
    },
    {
        value: "01900923-09ef-745c-a214-648da66f93e8",
        label: "Сталлер 3К",
    },
]

interface IStallerSelectModel {
    setModel: (v: SelectEntity) => void
}

export default function StallerSelectModel({ setModel }: IStallerSelectModel) {
    const [selectedModel, setSelectedModel] = useState(stallerModels[0])

    useEffect(() => {
        setModel(selectedModel)
    }, [selectedModel])

    return (
        <Select
            className="fw"
            options={stallerModels}
            value={selectedModel}
            onChange={(v) => setSelectedModel(v as SelectEntity)}
        />
    )
}
