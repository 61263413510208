import classes from './QueryBuilder.module.sass'

const QueryBuilderAddItem = ({ onAddItem } : { onAddItem: () => void }) => {

  return (
    <div className={classes.addItem} onClick={onAddItem}>
      <div className={classes.addItemCircle}>
        <div className={classes.addItemCircle__vertical} />
        <div className={classes.addItemCircle__horizontal} />
      </div>
      <div className={classes.addItemLine} />
    </div>
  )
}

export default QueryBuilderAddItem