import { PropsWithChildren } from "react"

interface GridProps {
    direction?: "row" | "column"
    align?: "center" | "start" | "end"
    justify?: "center" | "start" | "end" | "between" | "around"
    fullWidth?: boolean
    fullHeight?: boolean
    gap?: number
    cols?: string
    rows?: string
    as?: "div" | "tr"
    selected?: boolean
    onClick?: () => void
}

type ComponentProps = PropsWithChildren<GridProps>

const getStyles = (props: ComponentProps): React.CSSProperties => {
    const properties: React.CSSProperties = {}

    if (props.cols) {
        properties.gridTemplateColumns = props.cols
    }

    if (props.rows) {
        properties.gridTemplateRows = props.rows
    }

    if (props.gap) {
        properties.gap = props.gap
    }

    return properties
}

const Grid = (props: ComponentProps) => {
    const {
        align = "center",
        justify = "center",
        direction = "row",
        fullWidth,
        fullHeight,
        onClick,
        selected,
        as,
    } = props

    const directionClassName = `grid-${direction}`
    const alignClassName = `align-${align}`
    const justifyClassName = `justify-${justify}`
    const fullWidthClassName = fullWidth ? "fw" : null
    const fullHeightClassName = fullHeight ? "fh" : null
    const hoverClassName = as === "tr" && onClick ? "row--hover" : null
    const selectedClassName = as === "tr" && selected ? "row--selected" : null

    const className = [
        "grid",
        directionClassName,
        alignClassName,
        justifyClassName,
        fullWidthClassName,
        fullHeightClassName,
        hoverClassName,
        selectedClassName,
    ]
        .filter(Boolean)
        .join(" ")

    switch (props.as) {
        case "tr":
            return (
                <tr
                    className={className}
                    style={getStyles(props)}
                    onClick={onClick}
                >
                    {props.children}
                </tr>
            )
        default:
            return (
                <div
                    className={className}
                    style={getStyles(props)}
                    onClick={onClick}
                >
                    {props.children}
                </div>
            )
    }
}

export default Grid
