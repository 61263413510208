import { getListing } from "./../store/listing/actions"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { SAVE_FOR_TEMP } from "../store/bufferTemp/actions"
import {
    RESET_COMPARE_TYPE,
    SET_COMPARE_TYPE,
} from "../store/compareType/actions"
import {
    RESET_CURRENT_VALUES,
    SET_CURRENT_VALUES,
} from "../store/fields/actions"

export const usePresetHelper = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const location: { [key: string]: any } = useLocation()
    const apiPath: string = location.state.apiPath || ""
    const sortedSettings =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.sortedColumns
        ) || []
    const columnName = sortedSettings.columnName
    const sortMethod = sortedSettings.sortMethod

    return (filterQuery: string, categoryID: number) => {
        dispatch(RESET_CURRENT_VALUES())
        dispatch(RESET_COMPARE_TYPE({}))
        dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "" }))
        dispatch(SET_CURRENT_VALUES({ category: categoryID }))

        let query = `count_on_page=20&method=${sortMethod}&page=1&sort=${columnName}`
        let filter = ""
        let attributes = ""
        let cat = 0

        if (filterQuery.length > 0) {
            const decodedSearch = decodeURI(filterQuery)

            if (decodedSearch.includes("cat=")) {
                cat = parseInt(decodedSearch.split("cat=")[1].split("&")[0])
                dispatch(SET_CURRENT_VALUES({ category: cat }))
            } else {
                cat = 0
            }

            if (decodedSearch.includes("&filter=")) {
                const setFilter = `${
                    decodedSearch.split("&filter=")[1].split("&")[0]
                }`
                dispatch(SAVE_FOR_TEMP({ filter: setFilter }))
                filter = "&filter=" + setFilter

                const inputs = setFilter
                    .split(";")
                    .reduce((acc: any, inputObj: any) => {
                        acc[inputObj.split("--")[0]] = inputObj.split("--")[2]
                        dispatch(
                            SET_COMPARE_TYPE({
                                [inputObj.split("--")[0]]:
                                    inputObj.split("--")[1],
                            })
                        )
                        return acc
                    }, {})

                dispatch(SET_CURRENT_VALUES(inputs, "filter"))
            }

            if (decodedSearch.includes("attributeFilter=")) {
                const decodedQuery = decodedSearch
                    .split("&attributeFilter=")[1]
                    .split("&")[0]
                dispatch(SAVE_FOR_TEMP({ attributes: decodedQuery }))
                attributes = "&attributeFilter=" + decodedQuery
                const inputs = decodedQuery
                    .split(";")
                    .reduce((acc: any, inputObj: any) => {
                        acc[inputObj.split("--")[0]] = inputObj
                            .split("--")[2]
                            .split("|")
                        dispatch(
                            SET_COMPARE_TYPE({
                                [inputObj.split("--")[0]]:
                                    inputObj.split("--")[1],
                            })
                        )
                        return acc
                    }, {})

                dispatch(SET_CURRENT_VALUES(inputs, "attributeFilter"))
            }

            let resRoute = ""

            if (categoryID) {
                resRoute = `?cat=${categoryID}&` + query + filter + attributes
                dispatch(getListing(`${apiPath}${resRoute}`))
                history.push(resRoute)
            } else {
                resRoute = "?" + query + filter + attributes
                dispatch(getListing(`${apiPath}${resRoute}`))
                history.push(resRoute)
            }
        }
    }
}
