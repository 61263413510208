import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_GLOBAL_ERROR } from '../store/errors/actions';


const FatalError = () => {
  const [showed, setShowed] = useState<boolean>(false);

  const dispatch = useDispatch();
  const state = useSelector((state: { [key: string]: any }) => state);
  const error = state.errors;

  useEffect(() => window.addEventListener('FatalError', () => setShowed(true)));

  if (!showed) return null;
  return (
    <div className="modal-unclosed">
      <div className="modal-unclosed__inner">
        <div className="modal-unclosed__content  pt-3">
          <button type="button"
            className="btn-close ms-auto"
            aria-label="Close"
            onClick={() => { setShowed(false); dispatch(RESET_GLOBAL_ERROR()) }}
          ></button>
          <h3 className="mb-0">Критическая ошибка сервера</h3>
          <div className="modal-unclosed__mess mb-4">
            {error.text || 'Перезагрузите страницу, чтобы продолжить работу'}
          </div>
          <button className="btn btn-primary mx-5" onClick={() => { window.location.reload(); dispatch(RESET_GLOBAL_ERROR()) }}>
            Перезагрузить
          </button>
        </div>
      </div>
    </div>
  );
};

export default FatalError;
