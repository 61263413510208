import { useQuery } from "@tanstack/react-query"
import {
    Collapse,
    List,
    Pagination,
    Progress,
    ProgressProps,
    TablePaginationConfig,
    Typography,
} from "antd"
import { tablePaginationLocale } from "components/props/tableLocale"
import { RefreshButton } from "components/UI/buttons"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useState } from "react"
import { Uuid } from "types"
import { Entity, ListingResponse } from "types/api"

enum Statuses {
    Failed = "failed",
    Finished = "finished",
    Running = "running",
    Scheduled = "scheduled",
}

const getStatusMessage = (status: string, progress: number) => {
    if(progress === 100) return "финиш"
    
    const base = ""
    switch (status) {
        case Statuses.Failed:
            return base + "ошибка"
        case Statuses.Finished:
            return base + "закончено"
        case Statuses.Running:
            return base + "запущено"
            case Statuses.Scheduled:
                return base + "ожидает запуска"
        default:
            return base + `неизвестно(${status})`
    }
}

const getProgressStatus = (status: string, progress: number): ProgressProps["status"] => {
    if(progress === 100) return "success"

    switch (status) {
        case Statuses.Failed:
            return "exception"
        case Statuses.Finished:
            return "success"
        default:
            return "normal"
    }
}

const getStatusType = (status: string, progress: number) => {
    if(progress === 100) return "success"
    
    switch (status) {
        case Statuses.Failed:
            return "danger"
        case Statuses.Finished:
            return "success"
        case Statuses.Running:
            return "warning"
        default:
            return undefined
    }
}

const ResultItem = ({
    id,
    status,
    log = "",
    progress,
    createdAt,
}: {
    id: Uuid
    status: string
    log: string
    progress: number
    createdAt?: string
}) => {
    return (
        <Collapse className="fw">
            <Collapse.Panel
                key={"1"}
                header={
                    <Flex.Row
                        gap={20}
                        justify="start"
                        align="center"
                        styles={{ flexWrap: "wrap" }}
                    >
                        <Typography.Text style={{ minWidth: 280 }}>
                            {id}
                        </Typography.Text>
                        <Typography.Text
                            type={getStatusType(status, progress)}
                            style={{ minWidth: 200 }}
                        >
                            {getStatusMessage(status, progress)}
                        </Typography.Text>
                        <Progress
                            size={[200, 10]}
                            status={getProgressStatus(status, progress)}
                            percent={progress}
                            style={{ maxWidth: 300 }}
                        />
                        {createdAt && (
                            <Typography.Text>
                                {new Date(createdAt).toLocaleString()}
                            </Typography.Text>
                        )}
                    </Flex.Row>
                }
            >
                <Flex.Col className="fw">
                    {log.split("\n").map((el, idx) => (
                        <Typography.Text
                            className="fw"
                            style={{ textAlign: "start" }}
                            key={idx}
                        >
                            {el}
                        </Typography.Text>
                    ))}
                </Flex.Col>
            </Collapse.Panel>
        </Collapse>
    )
}

export default function MassGenerationHisotry() {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        pageSize: 20,
        current: 1,
        total: 0,
    })

    const { data, isLoading, refetch, isFetching } = useQuery<Entity[]>({
        queryKey: [pagination.pageSize, pagination.current],
        refetchInterval: 5000,
        queryFn: () =>
            api
                .getTyped<ListingResponse | []>(
                    "listing/nomenclature/mass-generate-result",
                    {
                        count_on_page: pagination.pageSize,
                        page: pagination.current,
                        sort: "createdAt",
                        method: "desc",
                    }
                )
                .then((response) => {
                    if (Array.isArray(response)) return [] as Entity[]
                    if (response.pagination) {
                        setPagination((prev) => ({
                            ...prev,
                            total: response.pagination.totalCount,
                        }))
                    }
                    return response.entities
                }),
        initialData: [],
    })

    return (
        <Flex.Col
            gap={10}
            className="col-md-12 col-xl-10"
            styles={{ paddingRight: 10 }}
        >
            <Flex.Row justify="start" relative fullWidth>
                <Typography.Title level={5}>История задач</Typography.Title>
                <Flex.Col absolute top={0} right={10}>
                    <RefreshButton
                        tooltip="Обновить список"
                        loading={isLoading || isFetching}
                        onClick={() => refetch()}
                        type="primary"
                    />
                </Flex.Col>
            </Flex.Row>

            <List
                loading={isLoading}
                className="fw"
                dataSource={data}
                prefixCls="mass-generation"
                renderItem={(data) => {
                    return (
                        <Flex.Row gap={10} className="fw">
                            <ResultItem
                                key={data.id}
                                id={data.id}
                                log={getFieldValue(data.fields, "log")!}
                                status={getFieldValue(data.fields, "status")!}
                                progress={
                                    getFieldValue<number>(
                                        data.fields,
                                        "progress"
                                    )!
                                }
                                createdAt={getFieldValue<string>(
                                    data.fields,
                                    "createdAt"
                                )}
                            />
                        </Flex.Row>
                    )
                }}
            />

            <Pagination
                style={{ marginTop: 10 }}
                {...pagination}
                showSizeChanger
                locale={tablePaginationLocale}
                onChange={(page, pageSize) => {
                    setPagination((prev) => ({
                        ...prev,
                        current: page,
                        pageSize,
                    }))
                }}
            />
        </Flex.Col>
    )
}
