import { useQuery } from "@tanstack/react-query"
import { DefaultOptionType } from "antd/es/select"
import { Required, TextError } from "components/UI"
import api from "helpers/api"
import { useDispatch, useSelector } from "react-redux"
import { SET_CURRENT_VALUES } from "store/fields/actions"
import { ListingResponse } from "types/api"
import { InputProps } from "types/interfaces"

export default function RegionInput({
    propertyContainer,
    textError,
    group,
}: InputProps) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-region"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/region", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: (
                                    el.fields.find(
                                        (field) => field.name === "country"
                                    )!.value as any
                                )?.name,
                                value: el.fields.find(
                                    (field) => field.name === "id"
                                )!.value,
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    const value = useSelector((state: any) => {
        const region = state.fields.currentValues?.main?.region
        return region?.id ?? region?.code ?? region
    }) as string | null

    const dispatch = useDispatch()

    return (
        <div className="row mb-3">
            <label className="col-xl-3 col-form-label">
                {propertyContainer.get("required") ? <Required /> : null}
                {propertyContainer.get("label")}
            </label>
            <div className="col-xl-9">
                <select
                    className="form-select"
                    onChange={(e) => {
                        console.log(e.currentTarget.value)
                        dispatch(
                            SET_CURRENT_VALUES(
                                { region: e.target.value || null },
                                group
                            )
                        )
                    }}
                    value={value || ""}
                    disabled={isFetching}
                >
                    <option value={""}>
                        {isFetching ? "Загрузка..." : "Для всех регионов"}
                    </option>
                    {data.map((el) => (
                        <option
                            selected={value === el.value}
                            key={el.value!}
                            value={el.value!}
                        >
                            {el.label}
                        </option>
                    ))}
                </select>
                {textError && <TextError text={textError} />}
            </div>
        </div>
    )
}
