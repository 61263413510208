import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import api from '../../../helpers/api';
import { compare } from '../../../helpers/compare';
import { useSortingHelper } from '../../../hooks/useSortingHelper';
import { SET_USER_SETTINGS } from '../../../store/saveData/actions';

interface IHeader {
  columnName: string;
  columnLabel: string;
}

export const TableHeader = () => {
  const dispatch = useDispatch();
  const resolveRoute = useSortingHelper();
  const location: { [key: string]: any } = useLocation();

  const tableHeaders = useSelector((state: { [key: string]: any }) => state.listing?.headers) || [];
  const settings = useSelector((state: { [key: string]: any }) => state.savedUserSettings?.showedColumns) || [];
  const sortedSettings = useSelector((state: { [key: string]: any }) => state.savedUserSettings?.sortedColumns) || [];

  const apiPath = location.state.apiPath || '';
  const pageID = location.state.pageID || '';

  const column = sortedSettings.columnName;
  const method = sortedSettings.sortMethod;
  const mRef = useRef(sortedSettings.columnName);
  const isLoaderActive = useSelector((state: { [key: string]: any }) => state.loaderShowed?.loaderShowed) || false;

  const tableControls = tableHeaders.reduce((acc: { [key: string]: string }, inputObj: IHeader) => {
    acc[inputObj.columnName] =
      inputObj.columnName === sortedSettings.columnName ? sortedSettings.sortMethod : 'm-hidden';
    return acc;
  }, {});

  const [array, setArray] = useState(tableControls);

  const handleClick = (header: IHeader) => {
    if (!isLoaderActive) {
      const style = array[header.columnName];
      let sortMethod = '';
      if (style === 'asc' || style === 'm-hidden') {
        sortMethod = 'desc';
        dispatch(SET_USER_SETTINGS({ sortedColumns: { columnName: header.columnName, sortMethod: sortMethod } }));
        resolveRoute(apiPath, header.columnName, sortMethod);
        api.post(
          `listing/saved-data/${pageID}`,
          {},
          { sortedColumns: { columnName: header.columnName, sortMethod: sortMethod } }
        );
      }

      if (style === 'desc') {
        sortMethod = 'asc';
        dispatch(SET_USER_SETTINGS({ sortedColumns: { columnName: header.columnName, sortMethod: sortMethod } }));
        resolveRoute(apiPath, header.columnName, sortMethod);
        api.post(
          `listing/saved-data/${pageID}`,
          {},
          { sortedColumns: { columnName: header.columnName, sortMethod: sortMethod } }
        );
      }
    }
  };

  useEffect(() => {
    const temp = array;
    for (let key in temp) {
      temp[key] = 'm-hidden';
    }
    if (!isLoaderActive) {
      setArray({ ...temp, [column]: method });
      mRef.current = method;
    }
    if (isLoaderActive && mRef.current === 'desc') {
      setArray({ ...temp, [column]: 'desc' });
    }
    if (isLoaderActive && mRef.current === 'asc') {
      temp[column] = 'asc';
      setArray({ ...temp, [column]: 'asc' });
    }
  }, [isLoaderActive]);

  return compare(settings.length === 0 ? tableHeaders : settings, settings).map((header: IHeader) => {
    return (
      <th key={header.columnName} scope="col" className="listing-column">
        <span className={`list-toggle-link ${array[header.columnName]}`}></span>
        <span className="list-toggle-link label" onClick={() => handleClick(header)}>
          {header.columnLabel}
        </span>
      </th>
    );
  });
};
