import { useQuery } from "@tanstack/react-query"
import { Card, Collapse, Typography } from "antd"
import { RefetchButton } from "components/UI/buttons"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { stringFormatter } from "helpers/stringFormatter"
import { useRegion } from "hooks/useRegion"
import { useState } from "react"
import { Uuid } from "types"

type HistoryRecord = {
    currency: {
        id: string
        isoCode: string
        name: string
    }
    deliverySum: number
    markupPercentage: number
    otherExpensesSum: 0
    packingSum: number
    price: number
    priceIncludesVAT: boolean
    priceWithVat: number
    provider: {
        id: Uuid
        name: string
    }
    signProvider: boolean
    startAt: string
    typeOfPrice: { id: Uuid; name: string } | null
    updatedAt: string
}

type PriceRecalculateHistoryDataItem = {
    calculationType: string
    nomenclatureName: string
    nomenclatureSeries: string
    historyRecord: HistoryRecord
}

export type PriceRecalulateHistoryItem = {
    id: Uuid
    startAt: string
    createdAt: string
    count: number
    data: PriceRecalculateHistoryDataItem[]
}

const positionCount = stringFormatter.count("позиция", "позиции", "позиций")

type HistoryItemResponse = {
    data: any[]
    count: number
    startAt: string
}

export default function PricesRecalculateHistory({
    data,
    isFetching,
    refetch,
}: {
    data: PriceRecalulateHistoryItem[]
    isFetching: boolean
    refetch: () => void
}) {
    const [activeItem, setActiveItem] = useState("")
    const { activeRegion } = useRegion()
    const { data: activeItemRows, isFetching: activeItemIsLoading } =
        useQuery<HistoryItemResponse>({
            queryKey: ["price-recalculation-history", activeItem, activeRegion],
            enabled: !!activeItem,
            queryFn: () =>
                api.getTyped<HistoryItemResponse>(
                    "nomenclature/get-price-recounter-log/" + activeItem
                ),
        })

    return (
        <Card className="fw">
            <Flex.Col fullWidth align="start">
                <Flex.Row gap={10} justify="start" padding={[20, 0]}>
                    <h6>История пересчета цен</h6>
                    <RefetchButton
                        onClick={refetch}
                        disabled={isFetching}
                        loading={isFetching}
                    />
                </Flex.Row>
                {data.map((el, idx) => {
                    return (
                        <Collapse
                            className="fw price-recalculate-history-collapse"
                            key={"collapse" + idx}
                            onChange={() => setActiveItem(el.id)}
                            accordion={true}
                        >
                            <Collapse.Panel
                                key={"1"}
                                header={
                                    <Flex.Row gap={20} justify="start">
                                        <Typography.Text>
                                            {stringFormatter.time(el.createdAt)}
                                        </Typography.Text>
                                        <Typography.Text>
                                            {`Обновлено ${positionCount(
                                                el.count
                                            )}`}
                                        </Typography.Text>
                                    </Flex.Row>
                                }
                            >
                                <UTable
                                    loading={activeItemIsLoading}
                                    data={activeItemRows?.data ?? []}
                                    maxRowsVisible={20}
                                    height={
                                        (activeItemRows?.data?.length ?? 0) *
                                            35 <
                                        700
                                            ? (activeItemRows?.data?.length ??
                                                  0) * 35
                                            : 700
                                    }
                                    search={el.data.length > 20}
                                    searchCondition={(row, search) => {
                                        const target =
                                            row.nomenclatureName +
                                            " " +
                                            row.nomenclatureSeries
                                        return target
                                            .toLowerCase()
                                            .includes(search)
                                    }}
                                    columns={[
                                        {
                                            columnName: "Дата установки цены",
                                            render: (row) =>
                                                stringFormatter.time(
                                                    activeItemRows!.startAt
                                                ),
                                            width: 15,
                                        },
                                        {
                                            columnName: "Имя номенклатуры",
                                            render: (row) =>
                                                row.nomenclatureName,
                                            sorter(rowA, rowB) {
                                                return rowA.nomenclatureName.localeCompare(
                                                    rowB.nomenclatureName
                                                )
                                            },
                                        },
                                        {
                                            columnName: "Серия номенклатуры",
                                            render: (row) =>
                                                row.nomenclatureSeries,
                                            width: 10,
                                        },
                                        {
                                            columnName: "Тип цены",
                                            render: (row) =>
                                                row.typeOfPrice || "",
                                            width: 15,
                                        },
                                        {
                                            columnName: "Значение цены",
                                            render: (row) => row.price,
                                            width: 10,
                                        },
                                        {
                                            columnName: "Валюта",
                                            render: (row) => row.currency,
                                            width: 15,
                                        },
                                    ]}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    )
                })}
            </Flex.Col>
        </Card>
    )
}
