import React from "react"
import dayjs from "dayjs"
import { Uuid } from "types"

interface TextProps {
    id: Uuid
    value: any
    page: string
    listing: { [key: string]: any }
}

export const DateTime: React.FC<TextProps> = ({ value }) => {
    return <span>{dayjs(value).format("DD.MM.YYYY в HH:mm")}</span>
}
