import { Currency } from "pages/CostCollector/СostCollectorModelTable"
import { Uuid } from "types"

type Value = {
    currency: Currency
    id: Uuid
    name: string
}

export const CalculationTypeCell = ({ value }: { value: Value | null }) => {
    return <span>{value?.name || ""}</span>
}
