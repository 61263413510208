import { useCallback, useState } from "react"

export const useSaveOrAcceptProps = () => {
    const [isLoading, _setLoading] = useState<"accept" | "save" | null>(null)

    const clearLoading = useCallback(() => {
        _setLoading(null)
    }, [_setLoading])

    const setLoading = useCallback(
        (value: "accept" | "save") => {
            _setLoading(value)
        },
        [_setLoading]
    )

    const withLoading = useCallback(
        <T extends unknown>(
                asyncFunction: (...props: any) => Promise<T>,
                value: "save" | "accept",
                debounce?: number
            ) =>
            async (...props: any) => {
                setLoading(value)
                try {
                    const response = await asyncFunction(...props)
                    return response
                } finally {
                    debounce
                        ? setTimeout(clearLoading, debounce)
                        : clearLoading()
                }
            },
        [setLoading, clearLoading]
    )

    return {
        withLoading,
        isLoading,
    }
}
