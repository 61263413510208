import { Modal, Tag } from "antd"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useState } from "react"
import { Uuid } from "types"

export type SaveAllToAllData = {
    attributeValues: Uuid[]
    attributeValuesNames: string[]
    group: Uuid
    model: Uuid
    attribute: Uuid
    onClose: () => void
}

export default function AllToAllModal({
    data,
}: {
    data: SaveAllToAllData | null
}) {
    const [loading, setLoading] = useState(false)

    const { showNotification } = useNotifications()

    const allToAllHandler = async () => {
        if (!data) return
        setLoading(true)
        const requestData = { ...data } as any
        delete requestData.attributeValuesNames
        try {
            await api.post(
                "attribute/mutual-compatibility/match-all",
                {},
                requestData
            )
            showNotification({
                type: "success",
                message: "Успешно сохранено",
            })
        } catch (error: any) {
            showNotification({
                type: "danger",
                message: error.message,
            })
        } finally {
            setLoading(false)
            data.onClose()
        }
    }

    return (
        <Modal
            open={!!data}
            title="Привязать все значения и свойства для значений:"
            okText="Сохранить"
            cancelText="Отмена"
            onCancel={data?.onClose}
            okButtonProps={{ loading }}
            onOk={allToAllHandler}
        >
            {data && (
                <Flex.Col align="start">
                    <Flex.Row gap={10} fullWidth justify="start">
                        {data.attributeValuesNames.map((el) => (
                            <Tag color="cyan">{el}</Tag>
                        ))}
                    </Flex.Row>
                </Flex.Col>
            )}
        </Modal>
    )
}
