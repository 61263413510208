import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { uniqueEvents } from '../../helpers/uniqueEvents';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";
import { Required } from '../UI/Required';

let selectedValues: any = []; // * Выделенные категории при редактировании

export const SelectCategories: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const state = useSelector((state: { [key: string]: any }) => state);
  const value = state.fields.currentValues[group]?.[propertyContainer.getName()];
  const categories = state.category;
  const selectedCategories = state.fields.currentValues.main?.categories || [];

  const location: { [key: string]: any } = useLocation();
  const pageID: string = location.state.pageID || '';
  const dispatch = useDispatch();

  const isInvalid = textError && "is-invalid";

  const optionHandler = (event: any) => {
    selectedValues = [];
    let value: any;

    if (propertyContainer.get('multiple')) {
      value = []
      const option: any = Array.from(event.target.selectedOptions);
      option.forEach((element: any) => {
        value.push(Number(element.value));
      });
    }
    value = value ? value : event.target.value;

    dispatch(uniqueEvents(event, 'OnChange', pageID));
    dispatch(SET_CURRENT_VALUES([value], group));
  }

  useEffect(() => {
    dispatch(SET_CURRENT_VALUES([selectedValues], group));
  }, [dispatch, group])

  return (
    <div className="row mb-3">
      <label className="col-xl-3 col-form-label">
        {propertyContainer.get('required') ? <Required /> : null}
        {propertyContainer.get('label')}
      </label>
      <div className="col-xl-6">
        <select className={`form-select ${isInvalid}`}
          disabled={propertyContainer.get('readonly')}
          value={value}
          size={8}
          onChange={(event => { optionHandler(event) })}
          multiple={propertyContainer.get('multiple')}
          name={propertyContainer.getName()}
          id={propertyContainer.getName()} aria-label="Default select">
          {!categories.length
            ? <option value=''>Загрузка...</option>
            : categories.map((item: any) => {

              //TODO always object
              if (typeof item !== 'object') {
                item = { 'value': item, 'label': item };
              }

              return (
                <option
                  selected={selectedCategories.find((itm: any) => {
                    if (Number(item.id) === itm.id) {
                      selectedValues.push(itm.id);
                      return true;
                    }
                    return false;
                  })
                  }
                  id={item.id} key={item.id} value={item.id}
                >{item.title}</option>
              )
            })
          }
        </select>
        {textError && <TextError text={textError} />}
      </div>
    </div>
  );
};
