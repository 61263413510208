import { Typography } from "antd"
import cls from "classnames"
import { PropsWithChildren } from "react"

export default function DetailFormItem({
    label,
    children,
    required,
    error,
}: PropsWithChildren<{ label: string; required?: boolean; error?: string }>) {
    return (
        <div className="row mb-3 align-items-center">
            <label
                className={cls(["col-xl-3", "col-form-label"], {
                    error: error,
                })}
            >
                {required && (
                    <span style={{ color: "rgb(220, 53, 69)" }}>* </span>
                )}
                {label}
            </label>
            <div className="col-xl-9">{children}</div>
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
        </div>
    )
}
