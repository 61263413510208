import { AxiosError } from "axios"
import { Dispatch } from "react"
import api from "../../helpers/api"
import { User } from "./types"

export enum UserAction {
    SET_USER = "SET_USER",
    SET_PENDING = "SET_PENDING",
    SET_ERROR = "SET_STATUS",
}

type GetUserResponse = {
    data: any
    user: User
}

/** ATTRIBUTES */
export const setUser = (payload: User | null) => ({
    type: UserAction.SET_USER,
    payload,
})

export const setPending = (payload: boolean) => ({
    type: UserAction.SET_PENDING,
    payload,
})

export const setError = (payload: string | null) => ({
    type: UserAction.SET_ERROR,
    payload,
})

/** THUNK */
export const fetchUser: any = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(setPending(true))
        dispatch(setError(null))
        await api
            .getTyped<GetUserResponse>(`user/get`)
            .then((response) => {
                dispatch(setUser(response.user))
            })
            .catch((error: AxiosError) => {
                dispatch(setError(error.message))
            })
            .finally(() => {
                dispatch(setPending(false))
            })
    }
}
