import React from 'react';
import fileDownload from 'js-file-download';
import api from '../../../helpers/api';
import { Uuid } from 'types';
interface FileDownloadProps {
  value: any,
  id: Uuid,
}

export const FileDownload: React.FC<FileDownloadProps> = ({ value, id }) => {
  const fileID = value.id;
  const download = (event: any) => {
    event.preventDefault();
    api.get<any>(`file/${fileID}/download`, {
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `example-${id}.csv`);
    });
  }

  return (
    <a href="!#" onClick={(event) => { download(event) }} download>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
        <polyline points="7 10 12 15 17 10"></polyline>
        <line x1="12" y1="15" x2="12" y2="3"></line>
      </svg>
    </a >
  )
}
