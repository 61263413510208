import { ArgsProps } from "antd/es/message"
import useMessage from "antd/es/message/useMessage"
import { ReactElement, useCallback } from "react"

export interface WithShowMessage {
    showMessage: (data: ArgsProps) => void
}

export default function withMessage<T extends object>(
    Component: React.ComponentType<T & WithShowMessage>
) {
    return (props: T): ReactElement => {
        const [messageApi, contextHolder] = useMessage()

        const showMessage = useCallback(
            (data: ArgsProps) => {
                messageApi.open(data)
            },
            [messageApi]
        )

        return (
            <>
                {contextHolder}
                <Component {...props} showMessage={showMessage} />
            </>
        )
    }
}
