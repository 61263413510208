import { Uuid } from "types"
import { formElementComponents, FormElementType } from "./parseSettings"

export default class FormElementContainer {
    private name: string
    private type: string
    private id: Uuid
    private container: { [key: string]: any }

    constructor(name: string, type: string, container: { [key: string]: any }) {
        this.name = name
        this.type = type
        this.container = container
        this.id =
            "content-form-element-" + container.id
                ? container.id
                : container.name
    }

    public getName(): string {
        return this.name
    }

    public getType(): FormElementType {
        const processedType = this.type.replace(/(\..{1})/, (_, p1) => {
            return p1.slice(-1).toUpperCase()
        })
        return processedType as FormElementType
    }

    public getId(): Uuid {
        return this.id
    }

    public get(name: string): any {
        return this.container[name]
    }

    public has(name: string): boolean {
        return this.container.hasOwnProperty(name)
    }

    public getComponent() {
        if (this.name === "marginType")
            return formElementComponents.marginTypeSelect

        if (this.name === "itemsGroups") {
            return formElementComponents.itemsGroupMulti
        }

        return (
            formElementComponents[this.getType()] ??
            formElementComponents.notFound
        )
    }
}
