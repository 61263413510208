import { Uuid } from "types"

export const tranformers: Record<string, (v: any) => void> = {
    itemsGroups: (data: Array<{ label: string; value: Uuid }>) => {
        return data.map((el) => el.value)
    },
}

export const transformSendData = (data: Record<string, any>) => {
    const newData = { ...data }
    Object.keys(data).forEach((key) => {
        if (tranformers[key]) {
            newData[key] = tranformers[key](data[key])
        }
    })
    return newData
}
