import { useQuery } from "@tanstack/react-query"
import { Spin, Switch } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useNotifications } from "hooks/useNotifications"
import { useCallback, useState } from "react"
import { Uuid } from "types"
import { Country, ListingResponse, NomenclatureItem } from "types/api"

type TargetKey = keyof Pick<
    NomenclatureItem,
    "nomenclatureRegionStock" | "nomenclatureRegionDiscontinued"
>

const requestParamNames: Record<TargetKey, string> = {
    nomenclatureRegionStock: "regionStock",
    nomenclatureRegionDiscontinued: "discontinued",
}

const labelNames: Record<TargetKey, string> = {
    nomenclatureRegionStock: "Складская позиция",
    nomenclatureRegionDiscontinued: "Архивная позиция",
}

const actionPaths: Record<TargetKey, { put: string; delete: string }> = {
    nomenclatureRegionStock: {
        put: "update-stock",
        delete: "delete-stock",
    },
    nomenclatureRegionDiscontinued: {
        put: "discontinued",
        delete: "discontinued",
    },
}

interface NomenclatureDetailInStockProps {
    defaultValues: NomenclatureItem[TargetKey]
    nomenclatureId: Uuid
    targetKey: TargetKey
}

export default function NomenclatureDetailRequesiteRegions({
    defaultValues,
    nomenclatureId,
    targetKey,
}: NomenclatureDetailInStockProps) {
    const { handleApiError, showSuccess } = useNotifications()
    const [loading, setLoading] = useState<Uuid | null>(null)

    const { data, isFetching } = useQuery({
        queryFn: () =>
            api.getTyped<ListingResponse>("listing/region").then((response) =>
                response.entities.map((entity) => ({
                    id: getFieldValue<Uuid>(entity.fields, "id"),
                    label: getFieldValue<Country>(entity.fields, "country")
                        ?.name,
                }))
            ),
        initialData: [],
    })

    const getRequestValues = useCallback(
        (region: Uuid, value: boolean) => {
            switch (targetKey) {
                case "nomenclatureRegionStock":
                    return region
                case "nomenclatureRegionDiscontinued":
                    return {
                        [region]: value,
                    }
            }
        },
        [targetKey, defaultValues, data]
    )

    const onSwitchChange = (region: Uuid, value: boolean) => {
        setLoading(region)
        const actions = actionPaths[targetKey]
        const action = value ? actions.put : actions.delete
        api.put(
            `v1/nomenclature/${action}/${nomenclatureId}`,
            {},
            {
                [requestParamNames[targetKey]]: getRequestValues(region, value),
            }
        )
            .then(showSuccess)
            .catch(handleApiError)
            .finally(() => setLoading(null))
    }

    return (
        <div className="row mt-2">
            <label className="col-xl-4">{labelNames[targetKey]}</label>
            <div className="col-xl-8 d-flex align-items-center">
                <Flex.Col gap={10} fullWidth>
                    {isFetching && (
                        <Flex.Row fullWidth justify="center">
                            <Spin />
                        </Flex.Row>
                    )}
                    {!isFetching && (
                        <>
                            <UTable
                                data={data}
                                columns={[
                                    {
                                        columnName: "Регион",
                                        render: (row) => row.label ?? "",
                                        width: 80,
                                    },
                                    {
                                        columnName: "",
                                        render: (region) => (
                                            <Switch
                                                loading={loading === region.id}
                                                disabled={isFetching}
                                                defaultChecked={defaultValues.some(
                                                    (el) => el.id === region.id
                                                )}
                                                onChange={(e) =>
                                                    onSwitchChange(
                                                        region.id!,
                                                        e
                                                    )
                                                }
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </>
                    )}
                </Flex.Col>
            </div>
        </div>
    )
}
