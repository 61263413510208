import { useQuery } from "@tanstack/react-query"
import { Modal, Spin } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import dayjs from "dayjs"
import api from "helpers/api"
import { useCostCollectorStore } from "./CostCollector.store"

type HistoryItem = {
    actionExplanation: string
    startAt: string
    value: number
    shortRepresentation: {
        createdBy: {
            first_name: string
            last_name: string
        }
    }
}

type HistoryResponse = HistoryItem[]

export default function CostCollectorHistoryModal({
    regionId,
}: {
    regionId: string
}) {
    const { showHistoryModal, toggleHistoryModal } = useCostCollectorStore()

    const { data, isFetching } = useQuery<HistoryResponse>({
        queryKey: [showHistoryModal, regionId],
        enabled: !!showHistoryModal && !!regionId,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<HistoryResponse>(`v1/economics/model/price-history`, {
                model: showHistoryModal!.id,
                region: regionId,
            }),
    })

    return (
        <Modal
            visible={!!showHistoryModal}
            title={`История изменения цен модели - ${showHistoryModal?.name}`}
            onCancel={() => toggleHistoryModal(null)}
            cancelText="Закрыть"
            okButtonProps={{ hidden: true }}
        >
            <Flex.Col className="fw" justify="start" styles={{ minHeight: 500 }}>
                {isFetching && <Spin size="large" />}
                {data && (
                    <UTable
                        data={data}
                        columns={[
                            {
                                columnName: "Дата",
                                render: (row) =>
                                    dayjs(row.startAt).format("YYYY-MM-DD"),
                                width: 25,
                            },
                            {
                                columnName: "Цена",
                                render: (row) => row.value,
                                width: 25,
                            },
                            {
                                columnName: "Пользователь",
                                render: (row) =>
                                    [
                                        row.shortRepresentation.createdBy.first_name,
                                        row.shortRepresentation.createdBy.last_name,
                                    ].join(" "),
                            },
                        ]}
                    />
                )}
            </Flex.Col>
        </Modal>
    )
}
