import { Col, Row, Select } from "antd"
import { AsyncSelect, Text } from "components/UI"
import { SelectEntity } from "../types"
import { Uuid } from "types"
import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { ListingResponse } from "types/api"
import { DefaultOptionType, SelectProps } from "antd/es/select"
import { getOnSelectData } from "./onSelect"
import { filterOption } from "components/inputs/helpers"

interface ProducerSelectProps {
    activeGroupId?: Uuid
    activeProducer: SelectEntity | null
    setActiveProducer: (v: SelectEntity | null) => void
    required?: boolean
    layout?: 'vertical' | 'horizontal'
    skipValues?: Uuid[]
    size?: SelectProps['size']
}

export function ProducerSelect({
    activeGroupId,
    activeProducer,
    setActiveProducer,
    required,
    layout = 'horizontal',
    size
}: ProducerSelectProps) {

    const { data, isLoading, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: [activeGroupId],
        enabled: !!activeGroupId,
        queryFn: () => api.getTyped<ListingResponse>('listing/producer', {
            itemsGroups: activeGroupId,
            method: "asc",
            sort: "name",
            count_on_page: Number.MAX_SAFE_INTEGER,
        }).then(response => {
            return response.entities.map(el => ({
                label: el.fields.find(field => field.name === 'name')!.value,
                value: el.fields.find(field => field.name === 'id')!.value,
            } as DefaultOptionType))
        }),
        initialData: []
    })

    const labelSpan = layout === 'horizontal' ? 5 : 24;
    const inputSpan = layout === 'horizontal' ? 19 : 24;

    const onChange = (v: any) => {
        setActiveProducer(v ? getOnSelectData(v) : null)
    }

    return (
        <Row gutter={[0, 10]}>
            <Col xs={24} md={labelSpan}>
                <Text.FormLabel required={required}>Поставщик</Text.FormLabel>
            </Col>
            <Col xs={24} md={inputSpan}>
                <Select
                    size={size}
                    loading={isLoading || isFetching}
                    showSearch
                    filterOption={filterOption}
                    className="fw"
                    placeholder="Выберите поставщика"
                    options={data}  
                    value={activeProducer}
                    onChange={(_, option) => onChange(option)}
                    allowClear
                    disabled={!activeGroupId}
                />
            </Col>
        </Row>
    )
}
