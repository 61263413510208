import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_VALUES } from '../../../store/fields/actions';
import { InputFilterProps } from './interfaces';

import CompareTypeSelector from './CompareTypeSelector';

export const InputText: React.FC<InputFilterProps> = ({ name, label, onClickHandler }) => {
  const dispatch = useDispatch();
  
  const value: any = useSelector((state: { [key: string]: any }) => state.fields.currentValues['filter']?.[name]) || '';
  const compareType = useSelector((state: { [key: string]: any }) => state.compareType[name]) || '';
  const currentValuesFilter = useSelector((state: { [key: string]: any }) => state.fields?.currentValues.filter);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onClickHandler();
    }
  };

  const handleChange = (event: any) => {
    if (event.target.value.length === 0){
      const copy = currentValuesFilter
      delete copy[name]
      dispatch(SET_CURRENT_VALUES(copy, 'filter'))
    } else {
      dispatch(SET_CURRENT_VALUES({ [name]: event.target.value }, 'filter'))
    }
  }

  return (
    <div className="row mb-3">
      <label htmlFor={name} className="col-xl-2 col-form-label">
        {label}
      </label>
      <div className="col-xl-10">
        <div className="row">
          <div className="col-xl-4">
            <CompareTypeSelector name={name} inputType={'text'} />
          </div>
          {compareType !== 'not_empty' && compareType !== '!not_empty' && (
            <div className="col-xl-8">
              <input
                className="form-control"
                type="text"
                id={name}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event)
                }
                onKeyUp={handleKeyPress}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
