import { useQuery } from "@tanstack/react-query"
import { Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { memo, useMemo } from "react"
import { ValueEditorProps } from "react-querybuilder"
import { Uuid } from "types"

function DeliveryTimeValueSelector(
    props: ValueEditorProps
) {
    const { value, handleOnChange, field } = props

    const { data: options, isLoading } = useQuery<DefaultOptionType[]>({
        queryKey: ['delivery', field],
        queryFn: () =>
            api
                .getTyped(`v1/query-builder/delivery-time-filter/values-list`, {
                    attribute: field
                })
                .then((response) =>
                    Object.entries(response).map(
                        ([key, value]) =>
                            ({
                                label: value,
                                value: key,
                            } as DefaultOptionType)
                    )
                ),
        initialData: [],
    })

    return (
        <Select
            showSearch
            style={{ minWidth: 300 }}
            options={options}
            value={value}
            onChange={(e) => handleOnChange(e)}
            loading={isLoading}
            filterOption={(input, option) => {
                return (
                    option?.label
                        ?.toLocaleString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) || false
                )
            }}
        />
    )
}

export default DeliveryTimeValueSelector