import { useQuery } from "@tanstack/react-query"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { defaultSearchCondition } from "helpers/defaultSearchCondition"
import { getFieldsValues } from "helpers/getFieldValue"
import { useMemo } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"

type NomenclatureFiltersTableItem = {
    id: Uuid
    name: string
}

export const NomenclatureFiltersTable = ({
    selectedRows,
    onRowClick,
    showAll,
    initialData,
}: {
    selectedRows?: Uuid[]
    initialData?: Uuid[]
    onRowClick?: (row: NomenclatureFiltersTableItem) => void
    showAll: boolean
}) => {
    const { data, isFetching } = useQuery<NomenclatureFiltersTableItem[]>({
        queryKey: ["price-calculations-rule"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>(
                    "listing/price-nomenclature-filter",
                    {
                        method: "asc",
                        sort: "name",
                        count_on_page: Number.MAX_SAFE_INTEGER,
                    }
                )
                .then((response) =>
                    response.entities.map((el) => {
                        return getFieldsValues(el.fields, "id", "name")
                    })
                ),
        initialData: [],
    })

    const tableData = useMemo(() => {
        if (showAll) return data
        return data.filter((el) => initialData?.includes(el.id))
    }, [showAll, data, initialData])

    return (
        <UTable
            search
            searchCondition={defaultSearchCondition}
            onRowClick={onRowClick}
            isRowActive={(row) => !!selectedRows?.includes(row.id)}
            data={tableData}
            columns={[
                {
                    columnName: "N",
                    render(_, id) {
                        return id + 1
                    },
                    width: 5,
                },
                {
                    columnName: "Фильтр",
                    render: (row) => row.name,
                },
            ]}
            loading={isFetching}
        />
    )
}
