import { InputNumber } from "antd"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import { useRequisitesStore } from "./Requisites.store"

export default function RequesitesDeliveryTime() {
    const { props, setProps } = useRequisitesStore()

    return (
        <Flex.Col fullWidth gap={20}>
            <FormLine label="Срок поставки">
                <InputNumber
                    min={0}
                    controls={false}
                    className="fw"
                    value={props ? +props : ""}
                    onChange={(v) => {
                        setProps("" + v)
                    }}
                />
            </FormLine>
        </Flex.Col>
    )
}
