import { Listing } from "components/Listing"
import { AttributeCombination } from "pages/AttributeCombination"
import SetNomenclaturePrices from "pages/SetNomenclaturePrices"
import Staller from "pages/Staller"
import { CreateRouteData } from "./tools"

export const economicRoutes: CreateRouteData[] = [
    {
        pathPrefix: "economic",
        element: Listing,
        pageID: "attributeCombination",
        apiPathPrefix: "listing",
    },
    {
        pathPrefix: "economic",
        element: AttributeCombination,
        pageID: "attributeCombination",
        isCreate: true,
        isUpdate: true,
    },
    {
        pageID: "staller",
        pathPrefix: "economic",
        apiPathPrefix: "listing",
        element: Staller,
    },
    {
        pageID: "setNomenclaturePrices",
        pathPrefix: "economic",
        element: SetNomenclaturePrices,
    },
]
