import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

export const GlobalAction = ({
    noCreate,
    editOrCreatePath,
}: {
    noCreate?: boolean
    editOrCreatePath?: string
}) => {
    const globalActionsValues =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.global_actions
        ) || []

    const filtredActions = noCreate
        ? globalActionsValues.filter((el: any) => el.name !== "create")
        : globalActionsValues

    return filtredActions.map((action: any) => {
        return (
            <Link
                key={action.name}
                className="btn btn-primary ms-1"
                to={editOrCreatePath ?? action.action}
            >
                {action.label}
            </Link>
        )
    })
}
