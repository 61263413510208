import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import api from "../../../helpers/api"
import { SET_INLINE_DATA } from "../../../store/inlineData/actions"
import { useNotifications } from "../../../hooks/useNotifications"
import { Uuid } from "types"

interface InputProps {
    id: Uuid
    name: string
    value: any
    listing: { [key: string]: any }
}

export const Input: React.FC<InputProps> = ({ id, name, listing = {} }) => {
    const entities =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.entities
        ) || []
    const initState = entities
        .find((item: any) => item.id === id)
        .fields.find((item: any) => item.name === name).value
    const sendingData =
        useSelector((state: { [key: string]: any }) => state?.inlineData) || {}

    const { showNotification } = useNotifications()

    const dispatch = useDispatch()
    const [value, setValue] = React.useState(initState || "")

    const location: { [key: string]: any } = useLocation()
    const pageID: string = location.state.pageID || ""

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            event.target.blur()
            api.put(`listing/update/${pageID}/${id}`, {}, { ...sendingData })
                .then(() => {
                    dispatch(SET_INLINE_DATA({ [name]: event.target.value }))
                    showNotification({
                        type: "success",
                        message: "Успешно обновлено!",
                    })
                })
                .catch(() => {
                    showNotification({
                        type: "danger",
                        message: "Ошибка обновления!",
                    })
                })
        }
    }

    return (
        <input
            title="Для изменения, введите значение и нажмите Enter"
            className="form-control without-border"
            name={name + id}
            type={listing.inputType}
            value={value || ""}
            disabled={listing.isReadOnly}
            onChange={(event: any) => {
                dispatch(SET_INLINE_DATA({ [name]: event.target.value }))
                setValue(event.target.value)
            }}
            onKeyUp={handleKeyPress}
        />
    )
}
