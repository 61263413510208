import { MeasureRulesItem } from "components/DatailAdditional/Model"
import { DetailAdditionalAction, DetailAdditionalPaylod } from "./actions"

export type DetailAdditionalStore = {
    measureUnits: {
        units: MeasureRulesItem[]
    }
}

const initialState = {
    measureUnits: {
        units: []
    }
} as DetailAdditionalStore

export const detailAdditionalReducer = (state: DetailAdditionalStore = initialState, action: DetailAdditionalPaylod) => {
    switch (action.type) {
        case DetailAdditionalAction.SET_MEASURE_UNITS:
            return {
                ...state,
                measureUnits: {
                    units: action.payload,
                }
            }
        default:
            return state
    }
}
