import { useSelector } from "react-redux"
import { RootState } from "../store/types"

export const useRegion = () => {
    const activeRegion = useSelector((state: RootState) => state.region.region)

    return {
        activeRegion,
    }
}
