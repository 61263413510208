import { Typography } from "antd"
import { PropsWithChildren } from "react"

const FormLabel = ({
    children,
    required,
    formLabelFontSize,
}: PropsWithChildren<{ required?: boolean; formLabelFontSize?: number }>) => {
    return (
        <>
            {required && (
                <Typography.Text
                    className="text-form-label__required"
                    style={{
                        fontSize: formLabelFontSize ?? 12,
                    }}
                >
                    *
                </Typography.Text>
            )}
            <Typography.Text
                className="text-form-label"
                style={{
                    fontSize: formLabelFontSize ?? 12,
                }}
            >
                {children}
            </Typography.Text>
        </>
    )
}

export const Text = {
    FormLabel,
}
