import { useQuery } from "@tanstack/react-query"
import { Spin } from "antd"
import base64 from "base-64"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { Uuid } from "types"
import { ItemsGroupEntity, ModelEntity, Producer, SeriesEnity } from "types/api"
import { create } from "zustand"
import { GeneralConfigurator } from "."

export type DefaultConfiguratorData = {
    series?: Uuid
    itemsGroup?: Uuid
    producer?: Uuid
    model?: Uuid
    attributes?: Record<Uuid, Uuid>
}

export interface ConfiguratorParams {
    producer: Uuid
    group: Uuid
    model: SelectEntity
    series: Uuid
}

type StateConfiguratorParams = {
    producer?: Uuid
    group?: Uuid
    model?: SelectEntity
    series?: Uuid
}

interface ConfiguratorStore {
    activeGroup: SelectEntity | null
    activeProducer: SelectEntity | null
    activeSeries: SelectEntity | null
    activeModel: SelectEntity | null
    setActiveGroup: (v: SelectEntity | null) => void
    setActiveModel: (v: SelectEntity | null) => void
    setActiveProducer: (v: SelectEntity | null) => void
    setActiveSeries: (v: SelectEntity | null) => void
    isLoading: boolean
    setIsLoading: (v: boolean) => void
    apply: boolean
    setApply: (v: boolean) => void
    params: StateConfiguratorParams | null
    setParams: (v: StateConfiguratorParams | null) => void
    filledAttributes: Record<Uuid, Uuid>
    activeParams: ConfiguratorParams | null
    setFilledAttribute: (v: Record<Uuid, Uuid>) => void
    resetFilledAttributes: () => void
}

export const useConfiguratorStore = create<ConfiguratorStore>((set) => ({
    activeGroup: null,
    activeProducer: null,
    activeSeries: null,
    activeModel: null,
    isLoading: false,
    apply: false,
    params: null,
    activeParams: null,
    filledAttributes: {},
    setIsLoading(v) {
        return set((state) => ({
            ...state,
            isLoading: v,
        }))
    },
    setActiveGroup(v) {
        return set((state) => {
            const newParams = state.params
                ? {
                      ...state.params,
                      group: v?.value,
                  }
                : {
                      group: v?.value,
                  }
            const isFilled =
                Object.entries(newParams).length === 4 &&
                Object.values(newParams).every(Boolean)
            return {
                ...state,
                activeGroup: v,
                params: newParams,
                activeParams: isFilled
                    ? (newParams as ConfiguratorParams)
                    : null,
            }
        })
    },
    setActiveProducer(v) {
        return set((state) => {
            const newParams = state.params
                ? {
                      ...state.params,
                      producer: v?.value,
                  }
                : {
                      producer: v?.value,
                  }
            const isFilled =
                Object.entries(newParams).length === 4 &&
                Object.values(newParams).every(Boolean)

            return {
                ...state,
                activeProducer: v,
                params: newParams,
                activeParams: isFilled
                    ? (newParams as ConfiguratorParams)
                    : null,
            }
        })
    },
    setActiveSeries(v) {
        return set((state) => {
            const newParams = state.params
                ? {
                      ...state.params,
                      series: v?.value,
                  }
                : {
                      series: v?.value,
                  }
            const isFilled =
                Object.entries(newParams).length === 4 &&
                Object.values(newParams).every(Boolean)
            return {
                ...state,
                activeSeries: v,
                params: newParams,
                activeParams: isFilled
                    ? (newParams as ConfiguratorParams)
                    : null,
            }
        })
    },
    setActiveModel(v) {
        return set((state) => {
            const newParams = state.params
                ? {
                      ...state.params,
                      model: v || undefined,
                  }
                : {
                      model: v || undefined,
                  }
            const isFilled =
                Object.entries(newParams).length === 4 &&
                Object.values(newParams).every(Boolean)
            return {
                ...state,
                activeModel: v,
                params: newParams,
                activeParams: isFilled
                    ? (newParams as ConfiguratorParams)
                    : null,
            }
        })
    },
    setApply(v) {
        return set((state) => ({
            ...state,
            apply: v,
        }))
    },
    setParams(v) {
        return set((state) => ({
            ...state,
            params: v,
        }))
    },
    setFilledAttribute(v) {
        return set((state) => ({
            ...state,
            filledAttributes: {
                // ...state.filledAttributes,
                ...v,
            },
        }))
    },
    resetFilledAttributes() {
        return set((state) => ({
            ...state,
            filledAttributes: {},
        }))
    },
}))

export default function GeneralConfiguratorWrapper() {
    const location = useLocation()
    const isFrame = location.pathname.startsWith("/configurator-iframe")

    const {
        setActiveGroup,
        setActiveProducer,
        setActiveModel,
        setActiveSeries,
        setApply,
    } = useConfiguratorStore()

    // useEffect(() => {
    //     if (!isFrame || !params) return
    //     if (Object.values(params).length) {
    //         setApply(true)
    //         setIsLoading(true)
    //     }
    // }, [params, isFrame, setApply, setIsLoading])

    const initialdata = useMemo(() => {
        try {
            const searchParams = new URLSearchParams(location.search)

            const payload = searchParams.get("payload") || ""
            const init = searchParams.get("init") || ""

            const bytes = base64.decode(payload)

            const data = JSON.parse(bytes) as DefaultConfiguratorData

            setActiveGroup(data.itemsGroup as any)
            setActiveModel(data.model as any)
            setActiveProducer(data.producer as any)
            setActiveSeries(data.series as any)
            setApply(true)

            return {
                payload: data,
                init,
            }
        } catch (error) {
            return {
                payload: {} as DefaultConfiguratorData,
                init: "",
            }
        }
    }, [location.search])

    const { isFetching: itemsGroupFetching } = useQuery<ItemsGroupEntity>({
        queryKey: [initialdata.payload.itemsGroup, isFrame],
        enabled: !!initialdata?.payload?.itemsGroup && !!isFrame,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<ItemsGroupEntity>(
                `reference/itemsGroup/${initialdata.payload.itemsGroup}`
            ),
        onSuccess(data) {
            setActiveGroup({
                label: data.name,
                value: data.id,
            })
        },
    })

    const { isFetching: producerFetching } = useQuery<Producer>({
        queryKey: [initialdata.payload.producer, isFrame],
        enabled: !!initialdata?.payload?.producer && !!isFrame,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<Producer>(
                `reference/producer/${initialdata.payload.producer}`
            ),
        onSuccess(data) {
            setActiveProducer({
                label: data.name,
                value: data.id,
            })
        },
    })

    const { isFetching: seriesFetching } = useQuery<SeriesEnity>({
        queryKey: [initialdata.payload.series, isFrame],
        enabled: !!initialdata?.payload?.series && !!isFrame,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<SeriesEnity>(
                `reference/series/${initialdata.payload.series}`
            ),
        onSuccess(data) {
            setActiveSeries({
                label: data.name,
                value: data.id,
            })
        },
    })

    const { isFetching: modelFetching } = useQuery<ModelEntity>({
        queryKey: [initialdata.payload.model, isFrame],
        enabled: !!initialdata?.payload?.model && !!isFrame,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<ModelEntity>(
                `reference/model/${initialdata.payload.model}`
            ),
        onSuccess(data) {
            setActiveModel({
                label: data.name,
                value: data.id,
            })
        },
    })

    const loading =
        itemsGroupFetching ||
        producerFetching ||
        seriesFetching ||
        modelFetching

    if (loading) {
        return (
            <Flex.Col fullWidth fullHeight justify="center" align="center">
                <Spin />
            </Flex.Col>
        )
    }

    return <GeneralConfigurator />
}
