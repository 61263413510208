import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { uniqueEvents } from "../../helpers/uniqueEvents"
import TextError from "../UI/TextError"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import { useQuery } from "@tanstack/react-query"
import api from "../../helpers/api"

const InputTnvedSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const location: { [key: string]: any } = useLocation()
    const pageID: string = location.state.pageID || ""
    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const optionHandler = (event: any) => {
        dispatch(uniqueEvents(event, "OnChange", pageID))
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: event.target.value },
                group
            )
        )
    }

    const { data } = useQuery({
        queryKey: ["listing/tnved"],
        queryFn: () =>
            api.getTyped("listing/tnved", {
                count_on_page: Number.MAX_SAFE_INTEGER,
                sort: 'name'
            }),
    })

    const pickListingRecordField = (record: any, field: string) => {
        return record.fields.find((v: { name: string }) => v.name === field)
            ?.value
    }

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <select
                        className={`form-select ${isInvalid}`}
                        disabled={propertyContainer.get("readonly")}
                        value={value?.id ?? value}
                        size={propertyContainer.get("size")}
                        onChange={(event) => {
                            optionHandler(event)
                        }}
                        multiple={propertyContainer.get("multiple")}
                        name={propertyContainer.getName()}
                        id={propertyContainer.getName()}
                        aria-label="Default select"
                    >
                        <option value="">Не указано</option>

                        {((data as any)?.entities ?? []).map((item: any) => {
                            return (
                                <option
                                    key={pickListingRecordField(item, "id")}
                                    value={pickListingRecordField(item, "id")}
                                >
                                    {pickListingRecordField(item, "name")}
                                </option>
                            )
                        })}
                    </select>
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputTnvedSelect
