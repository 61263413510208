import { Col, Row } from "antd"
import { AsyncSelect, Text } from "components/UI"
import { SelectEntity } from "./types"
import { Uuid } from "types"

interface SeriesSelectProps {
    activeSeries?: SelectEntity
    setActiveSeries: (v?: SelectEntity) => void
    activeGroupId?: Uuid
    activeProducerId?: Uuid
    required?: boolean
    layout?: 'vertical' | 'horizontal'
    skipValues?: Uuid[]
}

export function SeriesSelect({
    activeSeries,
    setActiveSeries,
    required,
    activeGroupId,
    activeProducerId,
    layout = 'horizontal',
    skipValues
}: SeriesSelectProps) {

    const labelSpan = layout === 'horizontal' ? 5 : 24;
    const inputSpan = layout === 'horizontal' ? 19 : 24;

    return (
        <Row gutter={[0, 15]}>
            <Col xs={24} md={labelSpan}>
                <Text.FormLabel required={required}>Серия</Text.FormLabel>
            </Col>
            <Col xs={24} md={inputSpan}>
                <AsyncSelect
                    value={activeSeries}
                    isDisabled={!activeGroupId || !activeProducerId}
                    onChange={(e) => {
                        setActiveSeries(e as typeof activeSeries)
                    }}
                    isClearable
                    dataUrl="series"
                    params={{
                        producer: activeProducerId,
                        group: activeGroupId
                    }}
                    placeholder={"Выберите серию"}
                    skipValues={skipValues}
                />
            </Col>
        </Row>
    )
}
