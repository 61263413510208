import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Uuid } from "types"

interface TextProps {
    id: Uuid
    value: any
    page: string
    listing: { [key: string]: any }
}

export const Text: React.FC<TextProps> = ({
    id,
    value,
    listing = {},
    page,
}) => {
    const { isSearchResult } = useSelector((state: any) => state.isSearchResult)
    const href = isSearchResult ? `good/update-${id}` : `${page}/update-${id}`

    if (typeof value === 'object') {
        return <span>{value?.name || ""}</span>
    }

    if (value === "") return <span>-</span>

    return listing.isLinkToDetail && listing.isLinkToDetail !== "undefined" ? (
        <Link className="" to={href}>
            {value}
        </Link>
    ) : (
        <span>{value}</span>
    )
}
