import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextError from '../UI/TextError';
import { Required } from '../UI/Required';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

export const KeyValue: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const state = useSelector((state: { [key: string]: any }) => state);
  const initialState = state.fields.currentValues[group]?.[propertyContainer.getName()] || [{ 'key': '', 'value': '' }];

  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);

  const isInvalid = textError && "is-invalid";

  const addValuesHandler = () => setValues([...values, { 'key': '', 'value': '' }]);

  const parsedValues = values && values.map((item: any, index: number) => {
    return (
      <div className="row" key={index}>
        <div className="col direct-breadcrumbs">
          <input
            className={`form-control ${isInvalid}`}
            type="text"
            value={item.key}
            placeholder="key"
            disabled={propertyContainer.get('readonly')}
            onChange={(event) => {
              values[index].key = event.target.value;
              let clonedState = JSON.parse(JSON.stringify(values));
              clonedState = [...clonedState];
              dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: clonedState }));
            }}
          />
          {textError && <TextError text={textError} />}
        </div>
        <div className="col direct-breadcrumbs">
          <input
            className={`form-control ${isInvalid}`}
            type="text"
            value={item.value}
            placeholder="value"
            disabled={propertyContainer.get('readonly')}
            onChange={(event) => {
              values[index].value = event.target.value;
              let clonedState = JSON.parse(JSON.stringify(values));
              clonedState = [...clonedState];
              dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: clonedState }));
            }}
          />
          {textError && <TextError text={textError} />}
        </div>
        <div className="col-2">
          <button type="button" className="btn btn-outline-primary " data-bs-toggle="dropdown" >...</button>
          <ul className="dropdown-menu" >
            <li className="dropdown-item" onClick={() => { console.log(index) }}>Удалить</li>
          </ul>
        </div>
      </div>
    )
  })

  return (
    <div className="row mb-3">
      <label htmlFor={propertyContainer.getId()} className="col-xl-3 col-form-label">
        {propertyContainer.get('required') ? <Required /> : null}
        {propertyContainer.get('label')}
      </label>
      <div className="col-xl-9">
        {parsedValues}
        <button onClick={addValuesHandler} type="button" className="btn btn-outline-primary">
          Еще
        </button>
      </div>
    </div>
  );
};
