import React from "react"
import { Uuid } from "types"
import { create } from "zustand"
import { ListChangesScreen } from "./ListChangesScreen"
import { SetupParentsScreen } from "./SetupParentsScreen"
import { WorkspaceScreen } from "./WorkspaceScreen"

export interface PicturesGeneratorParams {
    producer: {
        label: string
        value: Uuid
    }
    group: {
        label: string
        value: Uuid
    }
    series: {
        label: string
        value: Uuid
    }
    model?: {
        label: string
        value: Uuid
    }
}

type PictureType = Pick<File, 'name'> & { localBlobUrl: string }
export const usePicturesMassUploaderContextManager = create<{
    params: PicturesGeneratorParams | undefined
    activeScreen: "setup" | "workspace" | "changes"
    filledAttributes: Record<Uuid, string>
    changesList: Record<
        string,
        { values: Uuid[]; renders: Record<string, string[]> }
    >
    picturesList: PictureType[]
    addChange: (
        localBlobUrl: string,
        values: { values: Uuid[]; renders: Record<string, string[]> }
    ) => void
    appendPictures: (pics: PictureType[]) => void
    removeChange: (localBlobUrl: string) => void
    setParams: (params: PicturesGeneratorParams) => void
    fillAttribute: (attribute: Uuid, value?: Uuid) => void
    setActiveScreen: (activeScreen: "setup" | "workspace" | "changes") => void
    resetParams: () => void
    resetPictures: () => void
    resetChanges: () => void
}>((set) => ({
    params: undefined,
    filledAttributes: {},
    changesList: {},
    activeScreen: "setup",
    picturesList: [],
    setActiveScreen: (activeScreen: "setup" | "workspace" | "changes") =>
        set((state) => {
            return {
                ...state,
                activeScreen,
            }
        }),
    resetChanges: () =>
        set((state) => {
            return {
                ...state,
                changesList: {},
            }
        }),
    fillAttribute: (attribute: Uuid, value?: Uuid) =>
        set((state) => {
            if (!value) {
                let filled_ = state.filledAttributes
                delete filled_[attribute]

                return {
                    ...state,
                    filledAttributes: filled_,
                }
            }

            return {
                ...state,
                filledAttributes: {
                    ...state.filledAttributes,
                    [attribute]: value,
                },
            }
        }),
    addChange: (
        localBlobUrl: string,
        values: { values: Uuid[]; renders: Record<string, string[]> }
    ) =>
        set((state) => {
            return {
                ...state,
                changesList: {
                    ...state.changesList,
                    [localBlobUrl]: values,
                },
            }
        }),
    resetPictures: () =>
        set((state) => {
            return {
                ...state,
                picturesList: [],
            }
        }),
    appendPictures: (pics: PictureType[]) =>
        set((state) => {
            return {
                ...state,
                picturesList: [...state.picturesList, ...pics],
            }
        }),
    removeChange: (localBlobUrl: string) =>
        set((state) => {
            delete state.changesList[localBlobUrl]
            return state
        }),
    setParams: (params: PicturesGeneratorParams) => set(() => ({ params })),
    resetParams: () => set({ params: undefined, filledAttributes: {} }),
}))

export const PicturesMassUploader: React.FC = () => {
    document.title = "Готовые модели"
    const activeScreen = usePicturesMassUploaderContextManager(
        (state) => state.activeScreen
    )

    return {
        setup: <SetupParentsScreen />,
        workspace: <WorkspaceScreen />,
        changes: <ListChangesScreen />,
    }[activeScreen]
}
