/** BUFFER TEMP */
const initialState = {}

export const bufferTemp = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SAVE_FOR_TEMP":
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}
