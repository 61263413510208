import { useQuery } from "@tanstack/react-query"
import { Form, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { FormItemProps } from "antd/lib"
import api from "helpers/api"
import { getMarginProp, Margin } from "helpers/margin"
import { ListingResponse } from "types/api"

export function RegionFormSelect({
    ...props
}: FormItemProps & {
    onChange?: (v: string) => void
    noClear?: boolean
    margin?: Margin
    isMultiple?: boolean
}) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-region"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/region", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: (
                                    el.fields.find(
                                        (field) => field.name === "country"
                                    )!.value as any
                                )?.name,
                                value: el.fields.find(
                                    (field) => field.name === "id"
                                )!.value,
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    return (
        <Form.Item
            label={props.label ?? "Регион"}
            name={props.name ?? "region"}
            {...props}
            {...getMarginProp({ style: props.style, margin: props.margin })}
        >
            <Select
                className="region-select"
                options={data}
                mode={props.isMultiple ? "multiple" : undefined}
                loading={isFetching}
                onChange={props.onChange}
                size="large"
                allowClear={!props.noClear}
            />
        </Form.Item>
    )
}
