/** ERRORS */
export const SET_ERRORS = (payload: any) => ({
    type: "SET_ERRORS",
    payload,
})

export const SET_GLOBAL_ERROR = (payload: any) => ({
    type: "SET_GLOBAL_ERROR",
    payload,
})

export const RESET_GLOBAL_ERROR = () => ({
    type: "RESET_GLOBAL_ERROR",
})

export const RESET_ERROR = () => ({
    type: "RESET_ERROR",
})
