import { recognizePage } from "./../../helpers/recognisePage"
import api from "../../helpers/api"
import { CLEAR_LOADER, SET_LOADER } from "../loader/actions"

/** SET_LISTING */
export const SET_LISTING = (payload: any) => ({
    type: "SET_LISTING",
    payload,
})

/** CLEAR_LISTING */
export const CLEAR_LISTING = () => ({
    type: "CLEAR_LISTING",
})

/** CHANGE_VALUE */
export const CHANGE_VALUE = (payload: any) => ({
    type: "CHANGE_VALUE",
    payload,
})

/** DEL_ROW */
export const DEL_ROW = (payload: any) => ({
    type: "DEL_ROW",
    payload,
})

/** DEL_GROUP_ROW */
export const DEL_GROUP_ROW = (payload: any) => ({
    type: "DEL_GROUP_ROW",
    payload,
})

/** SET_CATEGORY_ID */
export const SET_CATEGORY_ID = (payload: any) => ({
    type: "SET_CATEGORY_ID",
    payload,
})

/** SET_EMPTY_FILTER */
export const SET_EMPTY_FILTER = () => ({
    type: "SET_EMPTY_FILTER",
})

/** CLEAR_EMPTY_FILTER */
export const CLEAR_EMPTY_FILTER = () => ({
    type: "CLEAR_EMPTY_FILTER",
})

/** THUNK */
export const processLisingResult: any = (result: any, pathname: string) => (dispatch: any) => {
    const pageName = recognizePage(pathname)
    if (Array.isArray(result.data)) {
        dispatch(
            SET_LISTING({
                entities: [],
                pageName: pageName,
            })
        )
    } else {
        dispatch(SET_LISTING(result.data))
    }
}


export const getListing: any = (route: string) => {
    return async (dispatch: any) => {
        const pageName = recognizePage(route.split("/")[1].split("?")[0])
        dispatch(SET_LOADER())
        await api
            .get(route)
            .then((result) => {
                if (Array.isArray(result.data)) {
                    dispatch(
                        SET_LISTING({
                            entities: [],
                            pageName: pageName,
                        })
                    )
                } else {
                    dispatch(SET_LISTING(result.data))
                }
            })
            .then(() => dispatch(CLEAR_LOADER()))
            .catch((error) => {
                console.log(error)
            })
    }
}
