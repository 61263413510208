import { useQuery } from "@tanstack/react-query"
import { AsyncSelect } from "components/UI"
import { Flex } from "components/UI/Flex"
import React, { useMemo } from "react"
import { Uuid } from "types"
import api from "../../../helpers/api"
import { useReadyPictureConfiguratorStore } from "./ReadyPictureConfigurator.store"
import { ReadyPictureConfiguratorPartAttributes } from "./ReadyPictureConfiguratorPartAttributes"
import ReadyPictureConfiguratorPicturePart from "./ReadyPictureConfiguratorPicturePart"

export interface PicturesGeneratorParams {
    producer: Uuid
    group: Uuid
    series: Uuid
    model?: {
        label: string
        value: Uuid
    }
}

const BLANK_PICTURE =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8+h8AAu8B9totwrcAAAAASUVORK5CYII="
export const ReadyPictureConfigurator: React.FC = () => {
    document.title = "Готовые модели"

    const {
        activeGroup,
        activeModel,
        activeProducer,
        activeSeries,
        filledAttributes,
        setFilledAttributes,
        setActiveGroup,
        setActiveModel,
        setActiveProducer,
        setActiveSeries,
        setApplied,
        reset,
        applied,
    } = useReadyPictureConfiguratorStore()

    const isFieldsFilled: boolean = useMemo(() => {
        return !(
            !activeGroup?.value ||
            !activeProducer?.value ||
            !activeSeries?.value ||
            !activeModel?.value
        )
    }, [activeSeries, activeGroup, activeProducer, activeModel])

    const { data: attributes } = useQuery<
        {
            id: Uuid
            name: string
            valuesList: Record<Uuid, string>
        }[]
    >({
        queryKey: ["attributes", activeModel],
        enabled: !!activeModel,
        queryFn: () => api.getTyped("attribute"),
    })

    const filledAttributesList = useMemo(() => {
        return Object.entries(filledAttributes).reduce<Record<Uuid, string>>(
            (prev, [attributeId, valueId]) => {
                const attribute = attributes?.find(
                    (v) => v.id.toString() === attributeId
                )
                const value = attribute?.valuesList[valueId] ?? valueId

                return {
                    ...prev,
                    [attribute?.name ?? attributeId]: value,
                }
            },
            {}
        )
    }, [filledAttributes, attributes])

    const onChangeAnyData = () => {
        setFilledAttributes({})
        setApplied(false)
    }

    return (
        <>
            <h1 className="h3 mb-3">
                Настройка сочетаний пререндеренных дверей
            </h1>
            <div className="control">
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div
                            className="control-view"
                            style={{
                                justifyContent: "stretch",
                            }}
                        >
                            <div className="control-view__main">
                                <div className="control-in">
                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Номенклатурная группа
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(e) => {
                                                    setActiveProducer(null)
                                                    setActiveSeries(null)
                                                    setActiveModel(null)
                                                    setActiveGroup(
                                                        e as typeof activeGroup
                                                    )
                                                    onChangeAnyData()
                                                }}
                                                dataUrl="items-group"
                                                page={1}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Поставщик
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={!activeGroup?.value}
                                                onChange={(e) => {
                                                    setActiveSeries(null)
                                                    setActiveModel(null)
                                                    setActiveProducer(
                                                        e as typeof activeProducer
                                                    )
                                                    onChangeAnyData()
                                                }}
                                                dataUrl="producer"
                                                params={{
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Серия
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeGroup?.value ||
                                                    !activeProducer?.value
                                                }
                                                onChange={(e) => {
                                                    setActiveSeries(
                                                        e as typeof activeSeries
                                                    )
                                                    onChangeAnyData()
                                                    setActiveModel(null)
                                                }}
                                                dataUrl="series"
                                                params={{
                                                    producer:
                                                        activeProducer?.value,
                                                    itemsGroup:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Модель
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeSeries?.value
                                                }
                                                onChange={(e) => {
                                                    setActiveModel(
                                                        e as typeof activeModel
                                                    )
                                                    onChangeAnyData()
                                                }}
                                                dataUrl="model"
                                                params={{
                                                    series: activeSeries?.value,
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => setApplied(true)}
                                            disabled={!isFieldsFilled}
                                        >
                                            Применить
                                        </button>
                                        <button
                                            className="btn btn-primary ms-3"
                                            onClick={reset}
                                        >
                                            Сбросить
                                        </button>
                                    </div>
                                </div>

                                {applied && (
                                    <ReadyPictureConfiguratorPartAttributes />
                                )}
                            </div>

                            <div
                                className="control-view__info"
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                }}
                            >
                                {applied && (
                                    <div
                                        style={{ position: "sticky", top: 20 }}
                                    >
                                        <ul>
                                            {Object.entries(
                                                filledAttributesList
                                            ).map(([key, value]) => (
                                                <li>
                                                    {key}: {value}
                                                </li>
                                            ))}
                                        </ul>
                                        <Flex.Col gap={20} fullWidth relative>
                                            <ReadyPictureConfiguratorPicturePart
                                                getPictureUrl="v1/pictures-generator/prebuilt/get"
                                                updateUrl="v1/pictures-generator/prebuilt/create-or-update"
                                                deleteUrl="v1/pictures-generator/prebuilt/delete"
                                                title="Фото"
                                                id={1}
                                            />

                                            <ReadyPictureConfiguratorPicturePart
                                                getPictureUrl="v1/pictures-generator/prebuilt/get-cover"
                                                updateUrl="v1/pictures-generator/prebuilt/create-or-update-cover"
                                                deleteUrl="v1/pictures-generator/prebuilt/delete-cover"
                                                title="Обложка"
                                                id={2}
                                            />
                                        </Flex.Col>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
