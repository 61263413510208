import { RetweetOutlined } from "@ant-design/icons"
import { Button, ButtonProps, Tooltip } from "antd"
import { PropsWithChildren } from "react"

const ToolTipWrapper = ({
  tooltip,
    children,
}: PropsWithChildren<{ tooltip?: string }>) => {
    if (tooltip) {
        return <Tooltip title={tooltip}>{children}</Tooltip>
    }

    return <>{children}</>
}

export default function RefreshButton(
    props: ButtonProps & {
      tooltip?: string
    }
) {
    return (
        <ToolTipWrapper tooltip={props.tooltip}>
            <Button
                {...props}
                style={{ padding: 10 }}
                className="flex justify-center align-center"
            >
                <RetweetOutlined />
            </Button>
        </ToolTipWrapper>
    )
}
