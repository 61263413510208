import { MeasureRulesItem } from "components/DatailAdditional/Model"
import { PayloadAction } from "store/types"

/** SET_COMPARE_TYPE */
export enum DetailAdditionalAction {
    SET_MEASURE_UNITS = 'SET_MEASURE_UNITS'
}


export const SET_MEASURE_UNITS = (payload: MeasureRulesItem[]) => ({
    type: DetailAdditionalAction.SET_MEASURE_UNITS,
    payload,
})

export type DetailAdditionalPaylod = PayloadAction<DetailAdditionalAction>