import React from 'react';
import { useDispatch } from 'react-redux';
import { DEL_FILE_FROM_CURRENT } from "../../../store/fields/actions";
import { InputMultipleFileItemProps } from "../../../types/interfaces";

export const InputMultipleFileItem: React.FC<InputMultipleFileItemProps> = ({ id, imageSrc, propertyContainer }) => {

  const dispatch = useDispatch();

  const deleteImageSrcHandler = (event: Event) => { 
    
    event.preventDefault();
    dispatch(DEL_FILE_FROM_CURRENT({ id, fieldName: propertyContainer.getName() }));
  }

  return (
    <div className="photogallery__item">
      <div className="photogallery__img">
        <img src={imageSrc} alt="" width={60} height={60} />
      </div>
      <a href="!#"
        className="photogallery__close"
        onClick={(event: any) => deleteImageSrcHandler(event)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </a>
    </div>
  )
}

