import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { uniqueEvents } from '../../helpers/uniqueEvents';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from '../../store/fields/actions';
import { InputProps } from '../../types/interfaces';
import { Required } from '../UI/Required';
import api from '../../helpers/api';

const InputSelectAccess: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const dispatch = useDispatch();
  const location: any = useLocation();
  const routerParams: { [key: string]: string } = useParams();
  const pageID: string = location.state.pageID || '';
  const isCreate = !routerParams.id;

  const values: [
    {
      name: string;
      isActive: boolean;
    }
  ] = useSelector((state: { [key: string]: any }) => state.fields.currentValues[group]?.[propertyContainer.getName()]);

  const selectedValues = values?.filter((item) => item.isActive).map((item: { name: string }) => item.name) || [];

  const isInvalid = textError && 'is-invalid';

  const optionHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value: any;
    value = [];
    if (propertyContainer.get('multiple')) {
      const option = Array.from(event.target.selectedOptions);
      option.forEach((element) => {
        value.push(element.value);
      });
    }
    value = value ? value : event.target.value;

    const prepareValues = values.map((item) => {
      if (value.includes(item.name)) {
        return {
          ...item,
          isActive: true,
        };
      } else {
        return {
          ...item,
          isActive: false,
        };
      }
    });

    dispatch(uniqueEvents(event, 'OnChange', pageID));
    dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: prepareValues }, group));
  };

  React.useEffect(() => {
    if (pageID === 'user' && isCreate) {
      api.get('listing/role').then((res: any) => {
        const result = res.data.entities
          .map((item: any) => item.fields.filter((field: any) => field.name === 'name'))
          .map((item: any) => {
            return {
              name: item[0].value,
              isActive: false,
            };
          });

        dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: result }, group));
      });
    }
  }, []);

  return (
    <>
      <div className="row mb-3">
        <label className="col-xl-3 col-form-label">
          {propertyContainer.get('required') ? <Required /> : null}
          {propertyContainer.get('label')}
        </label>
        <div className="col-xl-7">
          <select
            className={`form-select ${isInvalid}`}
            disabled={propertyContainer.get('readonly')}
            value={selectedValues}
            size={propertyContainer.get('size')}
            onChange={(event) => {
              optionHandler(event);
            }}
            multiple={propertyContainer.get('multiple')}
            name={propertyContainer.getName()}
            id={propertyContainer.getName()}
            aria-label="Default select"
          >
            {values &&
              values.map((item, index) => {
                if (item === null) return null;

                return (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          {textError && <TextError text={textError} />}
        </div>
      </div>
    </>
  );
};

export default InputSelectAccess;
