/** CATEGORY */
export const category = (state: [] = [], action: any) => {
    switch (action.type) {
        case "SET_CATEGORY":
            return [...action.payload]

        default:
            return state
    }
}
