import { useQuery } from "@tanstack/react-query"
import { Checkbox, Spin, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { Country, ListingResponse } from "types/api"
import { useRequisitesStore } from "./Requisites.store"

export default function RequisitesRegions() {
    const { isSetRegion, setRegion } = useRequisitesStore()

    const { data, isFetching } = useQuery({
        queryKey: ["listing/region"],
        queryFn: () =>
            api.getTyped<ListingResponse>("listing/region").then((response) => {
                return response.entities.map(
                    (entity) =>
                        getFieldValue<Country>(entity.fields, "country")!
                )
            }),
        initialData: [],
    })

    return (
        <FormLine label="Регионы">
            <Flex.Row
                fullWidth
                align="center"
                gap={20}
                justify="start"
                className="wrap"
            >
                {isFetching && <Spin />}
                {data.map((el) => (
                    <Flex.Row
                        gap={10}
                        styles={{ minWidth: 100, maxWidth: 180 }}
                        justify="start"
                        fullWidth
                    >
                        <Checkbox
                            checked={isSetRegion(el)}
                            onChange={() => setRegion(el)}
                        />
                        <Typography.Text style={{ fontSize: 16 }}>
                            {el?.name}
                        </Typography.Text>
                    </Flex.Row>
                ))}
            </Flex.Row>
        </FormLine>
    )
}
