import api from "../../helpers/api"
import { getListing } from "../listing/actions"
import { SET_CURRENT_VALUES } from "../fields/actions"
import { SAVE_FOR_TEMP } from "../bufferTemp/actions"
import { SET_COMPARE_TYPE } from "../compareType/actions"

/** SET_USER_SETTINGS */
export const SET_USER_SETTINGS = (payload: any) => ({
    type: "SET_USER_SETTINGS",
    payload,
})

/** THUNK */
export const getUserSettings: any = (
    route: string,
    apiPath: string,
    search: string
) => {
    return async (dispatch: any, getState: any) => {
        const currentState = getState()

        const { q } = currentState.bufferTemp

        const headers = currentState?.listing.headers ?? []

        const savedUserSettings = currentState?.savedUserSettings ?? {}
        const sortedColumns = savedUserSettings?.sortedColumns ?? {}

        const paginationSettings = currentState?.listing?.pagination ?? {}
        api.get<any>(route)
            .then((result) => {
                if (result.data.showedColumns.length === 0) {
                    dispatch(SET_USER_SETTINGS(result.data))
                    dispatch(SET_USER_SETTINGS({ showedColumns: [...headers] }))
                } else {
                    dispatch(SET_USER_SETTINGS(result.data))
                }
                dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "" }))
                return result.data
            })
            .then((data) => {
                if (search.length > 0) {
                    const decodedSearchParams = new URLSearchParams(
                        search ?? ""
                    )
                    let cat = 0
                    let filterQuery = ""
                    let attributesQuery = ""

                    dispatch(
                        SET_USER_SETTINGS({
                            sortedColumns: {
                                columnName:
                                    decodedSearchParams.get("sort") ?? "id",
                                sortMethod:
                                    decodedSearchParams.get("method") ?? "asc",
                            },
                        })
                    )

                    if (decodedSearchParams.get("cat")) {
                        cat = parseInt(decodedSearchParams.get("cat")!)
                        dispatch(SET_CURRENT_VALUES({ category: cat }))
                    } else {
                        cat = 0
                    }

                    if (decodedSearchParams.get("filter")) {
                        filterQuery =
                            "filter=" + decodedSearchParams.get("filter")
                        const setFilter = `${decodedSearchParams.get("filter")}`

                        const inputs = setFilter
                            .split(";")
                            .reduce((acc: any, inputObj: any) => {
                                dispatch(
                                    SET_COMPARE_TYPE({
                                        [inputObj.split("--")[0]]:
                                            inputObj.split("--")[1],
                                    })
                                )
                                acc[inputObj.split("--")[0]] =
                                    inputObj.split("--")[2]
                                return acc
                            }, {})

                        dispatch(SET_CURRENT_VALUES(inputs, "filter"))
                        dispatch(SAVE_FOR_TEMP({ filter: setFilter }))
                    }

                    if (search.includes("&attributeFilter=")) {
                        const decodedQuery =
                            decodedSearchParams.get("attributeFilter")

                        attributesQuery = "attributeFilter=" + decodedQuery
                        const inputs = decodeURI(search)
                            .split(";")
                            .reduce((acc: any, inputObj: any) => {
                                dispatch(
                                    SET_COMPARE_TYPE({
                                        [inputObj.split("--")[0]]:
                                            inputObj.split("--")[1],
                                    })
                                )
                                acc[inputObj.split("--")[0]] = inputObj
                                    .split("--")[2]
                                    .split("|")
                                return acc
                            }, {})

                        dispatch(SET_CURRENT_VALUES(inputs, "attributeFilter"))
                        dispatch(SAVE_FOR_TEMP({ attributes: decodedQuery }))
                    }

                }
                return data;
            })
            .catch((error) => {
                console.log(error)
            })
    }
}
