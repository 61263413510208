export function parseDimensions(str: string, cb?: (values: number[]) => void): number[] {
    const result: number[] = [];
  
    const chunks = str.split(',');
  
    for (const chunk of chunks) {
      if (chunk.includes('-') && chunk.includes('*')) {
        // если символы дефиса и звездочки есть в строке - это выражение с диапазоном и шагом
        const [start, end, step] = chunk.split(/-|\*/).map(Number);
  
        for (let i = start; i <= end; i += step) {
          result.push(i);
        }
      } else {
        // иначе просто парсим число и добавляем его в результат
        const num = Number(chunk.trim());
        if (!isNaN(num)) {
          result.push(num);
        }
      }
    }

    cb && cb(result)
  
    return result;
  }
