import React from "react"
import api from "../../helpers/api"
import toast from "react-hot-toast"

export const PicturesGeneratorFileUploader: React.FC<{
    onUploaded: (file: number) => void
}> = ({ onUploaded }) => {
    const checkMimeType = (mimeType: string) =>
        ["image/png", "image/jpeg", "image/jpg"].includes(mimeType)

    const sendFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files!.length) {
            return
        }

        const file = event.target.files![0]
        const fileLimit: number = 20971520 // 20Mb

        if (
            file.size >= fileLimit ||
            file.size === 0 ||
            !checkMimeType(file.type)
        ) {
            return toast.error("Файл должен быть PNG и весить не более 20 МБ")
        }

        const formData = new FormData()
        formData.append("file", file)

        await toast.promise(
            api.post<any, { id: number }>(
                "file/upload/door-layer",
                {},
                formData
            ),
            {
                loading: "Загрузка...",
                success: ({ data }) => {
                    onUploaded(data.id)
                    return <b>Файл успешно загружен</b>
                },
                error: (response) => {
                    const error =
                        response ?? "Не удалось загрузить, неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )
    }

    return (
        <input
            className="form-control form-control-sm d-inline-block"
            type="file"
            style={{
                width: "auto",
            }}
            onChange={(e) => sendFile(e)}
        />
    )
}
