import { Uuid } from "types"
import { Attribute, ItemsGroupEntity } from "types/api"

export interface AttributeGroupProducerLink {
    attribute: Attribute
    datatypeOptions: Record<Uuid, string>
}

export interface ItemGroup {
    code: Uuid
    id: Uuid
    isActive: boolean
    name: string
    __isCloning: boolean
}

export interface Model {
    code: Uuid
    id: Uuid
    isPublished: boolean
    itemGroup: ItemGroup
    __isCloning: boolean
}

export interface Producer {
    code: Uuid
    id: Uuid
    isPublished: boolean
    name: string
}

export interface Series {
    code: Uuid
    id: Uuid
    isPublished: boolean
    name: string
    producer: {
        data: Producer
    }
}

export interface ModelAttribute {
    attribute: Attribute
    attributeConfigurable: boolean
    attributeId: Uuid
    configurableAttributeValues: Uuid[]
    id: Uuid
    model: Model
    modelId: Uuid
    namingApplicableAttributeValues: Uuid[]
    series: Series
    itemsGroup: Pick<ItemsGroupEntity, 'code' | 'id' | 'name'>
}

export interface ModelAttributeLink {
    id: Uuid
    modelExportable: boolean
    attributeExportable: boolean
    attributeConfigurable: boolean
    configurableAttributeValues: Uuid[]
    exportableAttributeValues: Uuid[]
    namingApplicableAttributeValues: Uuid[]
}

export enum EntityType {
    series = "series",
    model = "model",
}
export interface ActiveEntityType {
    entity: EntityType
    id: Uuid
    parentId?: Uuid
}
