import { useQuery } from "@tanstack/react-query"
import { Select, SelectProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_CURRENT_VALUES } from "store/fields/actions"
import { Uuid } from "types"
import { Entity, ListingResponse, ModelEntity } from "types/api"

type AttributeValue = {
    attributeValuesObject: Record<Uuid, string>
    id: Uuid
    isActive: boolean
    isRequired: boolean
    name: string
}

export default function ModelAttributes({ data }: { data: ModelEntity }) {
    const [search, setSearch] = useState("")

    const { attributeValuesIds = [], attributeValues = {} } = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues?.["main"] ?? {}
    )

    const dispatch = useDispatch()

    const { data: attributesLinks, isFetching } = useQuery<Entity[]>({
        queryKey: ["model-attributes", data?.id],
        enabled: !!data?.id,
        queryFn: () =>
            api
                .getTyped<ListingResponse>(`listing/model-attribute`)
                .then((data) => data.entities),
    })

    const onChange: SelectProps["onChange"] = (v) => {
        dispatch(
            SET_CURRENT_VALUES(
                {
                    attributeValues: v,
                    attributeValuesIds: v,
                },
                "main"
            )
        )
    }

    useEffect(() => {
        if (!attributeValues || !attributeValuesIds) return
        if (Object.keys(attributeValues).length === attributeValuesIds.length)
            return
        const newValues = Object.keys(attributeValues)
        dispatch(
            SET_CURRENT_VALUES({
                attributeValues: newValues,
                attributeValuesIds: newValues,
            })
        )
    }, [attributeValues, attributeValuesIds])

    const { data: optionsData, isFetching: isModelAttributesLoading } =
        useQuery<SelectProps["options"]>({
            queryKey: ["model-attributes-value", attributesLinks],
            enabled: !!attributesLinks,
            queryFn: () => {
                return Promise.all(
                    attributesLinks!.map((el) =>
                        api.getTyped<AttributeValue>(
                            `v1/model-attribute/${getFieldValue(
                                el.fields,
                                "id"
                            )}`
                        )
                    )
                ).then((data) =>
                    data.map((el) => {
                        return {
                            label: el.name,
                            options: Object.entries(
                                el.attributeValuesObject
                            ).map(([id, name]) => ({
                                label: name,
                                value: id,
                            })),
                        }
                    })
                )
            },
            initialData: [],
        })

    const filtredOptions = useMemo(() => {
        if (!search) return optionsData ?? []
        const _search = search.toLowerCase()
        return (optionsData ?? [])
            .map((el) => {
                if (el.label?.toString().toLowerCase().includes(_search)) {
                    return el
                }
                const options = el.options.filter((option: DefaultOptionType) =>
                    option.label?.toString().toLowerCase().includes(_search)
                )
                return {
                    ...el,
                    options,
                }
            })
            .filter((el) => el.options?.length)
    }, [search, optionsData])

    if (isModelAttributesLoading || !filtredOptions.length) {
        return null
    }

    return (
        <div className="row align-items-center">
            <label className="col-xl-3 col-form-label">Свойства</label>

            <div className="col-xl-9">
                <Select
                    mode="multiple"
                    className="fw"
                    size="large"
                    value={
                        attributeValuesIds.length
                            ? attributeValuesIds
                            : Object.keys(attributeValues)
                    }
                    onChange={onChange}
                    options={filtredOptions}
                    filterOption={false}
                    showSearch
                    searchValue={search}
                    onSearch={(v) => setSearch(v)}
                />
            </div>
        </div>
    )
}
