import { PayloadAction } from "store/types"
import { UserAction } from "./actions"
import { User } from "./types"

export type UserState = {
    user: User | null
    pending: boolean,
    error: string | null
}
/** ATTRIBUTES */
const initialState = {
    user: null,
    pending: false,
    error: null,
} as UserState

export const userReducer = (
    state = initialState,
    action: PayloadAction<UserAction>
) => {
    switch (action.type) {
        case UserAction.SET_USER: {
            return {
                ...state,
                user: action.payload,
            }
        }
        case UserAction.SET_PENDING: {
            return {
                ...state,
                pending: action.payload,
            }
        }
        case UserAction.SET_ERROR: {
            return {
                ...state,
                error: action.payload,
            }
        }
        default:
            return state
    }
}
