export const collapseTree = (event: any) => {
    if (!event.currentTarget.parentNode.nextSibling) return false
    const currentHeight =
        event.currentTarget.parentNode.nextSibling.clientHeight

    if (currentHeight !== 0) {
        event.currentTarget.parentNode.nextSibling.style.height = 0
        event.currentTarget.firstChild.style.transform = "rotate(-90deg)"
    } else if (currentHeight === 0) {
        event.currentTarget.parentNode.nextSibling.style.height = "auto"
        event.currentTarget.firstChild.style.transform = "rotate(-360deg)"
    }
}
