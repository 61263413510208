import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { ReactElement } from "react";

export default function withQueryBuilder<T extends object>(
  Component: React.ComponentType<T>
) {
  return (props: T) : ReactElement => {

    return <QueryBuilderAntD>
      <Component {...props} />
    </QueryBuilderAntD>
  }
}