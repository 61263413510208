import { Col, Row } from "antd"
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react"
import { GroupSelect } from "./GroupSelect"
import { ModelSelect } from "./Model"
import { ProducerSelect } from "./ProducerSelect"
import { SeriesSelect } from "./Series"
import { SelectEntity } from "./types"
import { Uuid } from "types"

type Enitity = "model" | "series" | "producer"

type OnChangeEntity = (v: SelectEntity | null) => void

export type EntitySelectEmptyValues = {
    group: SelectEntity | null
    producer: SelectEntity | null
    series: SelectEntity | null
    model: SelectEntity | null
}

interface EntitiesSelectProps {
    entities: Enitity[]
    onSelectGroup?: OnChangeEntity
    onSelectProducer?: OnChangeEntity
    onSelectSeries?: OnChangeEntity
    onSelectModel?: (v: SelectEntity | null) => void
    onSelectModels?: (v: SelectEntity[] | null) => void
    layout?: "vertical" | "horizontal"
    targetKey?: Enitity
    outSideEntity?: SelectEntity
    onEmptyValues?: EntitySelectEmptyValues,
    resetValue?: SelectEntity | null
    containerStyles?: HTMLAttributes<any>['style']
}

export default function 
EntitiesSelect({
    entities,
    onSelectGroup,
    onSelectModel,
    onSelectProducer,
    onSelectSeries,
    onSelectModels,
    layout = "horizontal",
    targetKey,
    onEmptyValues,
    resetValue,
    containerStyles = {}
}: EntitiesSelectProps) {
    const [activeGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [activeProducer, setActiveProducer] = useState<SelectEntity | null>(
        null
    )
    const [activeSeries, setActiveSeries] = useState<SelectEntity | null>(null)
    const [activeModel, setActiveModel] = useState<SelectEntity | null>(null)
    const [activeModels, setActiveModels] = useState<SelectEntity[] | null>(
        null
    )

    const _setActiveModel = useCallback(
        (v?: SelectEntity) => {
            setActiveModel(v ?? null)
            onSelectModel && onSelectModel(v ?? null)
        },
        [onSelectModel, setActiveModel]
    )

    const _setActiveModels = useCallback((v?: SelectEntity[]) => {
        setActiveModels(v ?? null)
        onSelectModels && onSelectModels(v ?? null)
    }, [])

    const _setActiveSeries = useCallback(
        (v?: SelectEntity) => {
            setActiveSeries(v ?? null)
            onSelectSeries && onSelectSeries(v ?? null)
            _setActiveModel()
            _setActiveModels()
        },
        [onSelectSeries, setActiveSeries, _setActiveModel, _setActiveModels]
    )

    const _setActiveProducer = useCallback(
        (v?: SelectEntity) => {
            setActiveProducer(v ?? null)
            onSelectProducer && onSelectProducer(v ?? null)
            _setActiveSeries()
        },
        [onSelectProducer, setActiveProducer, _setActiveSeries]
    )

    const _setActiveGroup = useCallback(
        (v?: SelectEntity) => {
            setActiveGroup(v ?? null)
            onSelectGroup && onSelectGroup(v ?? null)
            _setActiveProducer()
        },
        [onSelectGroup, setActiveGroup, _setActiveProducer]
    )

    const showProducer = entities.includes("producer")
    const showSeries = entities.includes("series")
    const showModel = entities.includes("model")
    
    useEffect(() => {
        if (targetKey === "model") {
            onSelectModel && onSelectModel(activeModel)
        }
        if (targetKey === "series") {
            onSelectSeries && onSelectSeries(activeSeries)
        }
        if (targetKey === "producer") {
            onSelectProducer && onSelectProducer(activeProducer)
        }
    }, [targetKey])

    useEffect(() => {
        if (onEmptyValues) {
            setActiveGroup(onEmptyValues?.group) 
            setActiveModel(null)
            setActiveModels(null);
        }
    }, [onEmptyValues])

    useEffect(() => {
        if (resetValue) return;
            setActiveModel(null)
            setActiveSeries(null);
            setActiveProducer(null);
            setActiveGroup(null);
    }, [resetValue])

    const skipValues: Uuid[] = useMemo(() => {
        if (
            targetKey === "model" &&
            onEmptyValues?.model &&
            activeSeries?.value === onEmptyValues?.series?.value
        ) {
            return [onEmptyValues?.model.value]
        }
        return []
    }, [targetKey, onEmptyValues, activeSeries])

    useEffect(() => {
        if (onEmptyValues) {
            setActiveGroup((prev) =>
                !prev && onEmptyValues?.group ? onEmptyValues.group : prev
            )
            setActiveProducer((prev) =>
                !prev && onEmptyValues?.producer ? onEmptyValues.producer : prev
            )
            setActiveSeries((prev) =>
                !prev && onEmptyValues?.series ? onEmptyValues.series : prev
            )
        }
    }, [onEmptyValues])

    return (
        <Row gutter={[0, 15]} key={activeGroup?.value} style={{ ...containerStyles }}>
            <Col span={24}>
                <GroupSelect
                    activeGroup={activeGroup || undefined}
                    setActiveGroup={_setActiveGroup}
                    required
                    layout={layout}
                />
            </Col>
            {showProducer && (
                <Col span={24}>
                    <ProducerSelect
                        key={activeProducer?.value}
                        activeGroupId={activeGroup?.value}
                        activeProducer={activeProducer || undefined}
                        setActiveProducer={_setActiveProducer}
                        required
                        layout={layout}
                    />
                </Col>
            )}
            {showSeries && (
                <Col span={24}>
                    <SeriesSelect
                        key={activeSeries?.value}
                        activeGroupId={activeGroup?.value}
                        activeProducerId={activeProducer?.value}
                        activeSeries={activeSeries || undefined}
                        setActiveSeries={_setActiveSeries}
                        layout={layout}
                    />
                </Col>
            )}
            {showModel && (
                <Col span={24}>
                    <ModelSelect
                        activeGroupId={activeGroup?.value}
                        activeSeriesId={activeSeries?.value}
                        activeMultiModel={activeModels || undefined}
                        setMultiActiveModel={_setActiveModels}
                        layout={layout}
                        skipValues={skipValues}
                    />
                </Col>
            )}
        </Row>
    )
}
