import { MenuProps, MenuItemProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

export enum AttributeCopyKeys {
  model = 'model',
  modelSeries = 'model-series',
  modelProducer = 'model-producer',
}

export const menuData: ItemType[] = [
  {
    label: 'Модель',
    key: AttributeCopyKeys.model
  },
  {
    label: 'Модель - серия',
    key: AttributeCopyKeys.modelSeries
  },
  {
    label: 'Модель - поставщик',
    key: AttributeCopyKeys.modelProducer
  },
]