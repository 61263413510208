import { Flex } from "components/UI/Flex"
import { PropsWithChildren } from "react"

type ContainerLayoutProps = {
    pageTitle: string
    className?: string
}

export function ContainerLayout({
    pageTitle,
    children,
    className,
}: PropsWithChildren<ContainerLayoutProps>) {
    return (
        <Flex.Col
            styles={{ maxWidth: 1300 }}
            fullWidth
            gap={20}
            className={className}
        >
            <h1
                style={{ width: "100%", textAlign: "start" }}
                className="h3 mb-3"
            >
                {pageTitle}
            </h1>
            {children}
        </Flex.Col>
    )
}
