import { Tag } from "antd"
import { Attribute } from "pages/CostCollector/CostCollectorAttributes/CostCollectorAttributes.types"

export const AttributeListCell = ({ value }: { value: Attribute[] }) => {
    return (
        <div>
            {value?.map((el) => (
                <Tag key={el.id}>{el.name}</Tag>
            ))}
        </div>
    )
}
