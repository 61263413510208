import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import withQueryBuilder from "hocs/withQueryBuilder"
import { useMemo } from "react"
import QueryBuilder, { Field, QueryBuilderProps } from "react-querybuilder"
import { QueryGetEnitiesResponse } from "./QueryBuilder"
import { inputOperators, notFilterFields } from "./types"

type QueryFormFieldProps = QueryBuilderProps & {
    getEntitiesURL: string
}

const QueryFormField = (props: QueryFormFieldProps) => {
    const { getEntitiesURL } = props

    const { data: queryBuilderData } = useQuery<QueryGetEnitiesResponse>({
        queryKey: [getEntitiesURL],
        enabled: !!getEntitiesURL,
        queryFn: () =>
            api.getTyped<QueryGetEnitiesResponse>(getEntitiesURL!, {}),
    })

    const { combinators, operators = [], items } = queryBuilderData || {}

    const fields: Field[] = useMemo(() => {
        if (!items) return []
        return Object.entries(items).map(
            ([key, value]) =>
                ({
                    name: key,
                    label: value,
                } as Field)
        )
    }, [items])

    return (
        <QueryBuilder
            {...props}
            fields={fields}
            combinators={combinators}
            getOperators={(field) => {
                if (notFilterFields.includes(field)) {
                    return operators
                }
                return operators.filter(
                    (el) => !inputOperators.includes(el.name)
                )
            }}
            operators={operators}
        />
    )
}

export default withQueryBuilder(QueryFormField)
