import { Card, Divider } from "antd"
import { Layout } from "components/layouts"
import { EnititesSelect } from "components/Selects/newSelects/EntitiesSelect"
import { useEntitiesData } from "hooks/useEntitiesData"
import { pageIDs } from "pageConfig"
import NomenclaturePricesTable from "./NomenclaturePricesTable"

export default function SetNomenclaturePrices() {
    const {
        setGroup,
        setModel,
        setProducer,
        setSeries,
        series,
        model,
        group,
        producer,
    } = useEntitiesData()

    return (
        <Layout.Detail
            pageTitle="Ручная установка цен"
            pageId={pageIDs.setNomenclaturePrices}
            noReturnToList
        >
            <Card style={{ maxWidth: 1000 }} className="fw">
                <EnititesSelect
                    onModelChange={setModel}
                    onSeriesChange={setSeries}
                    onGroupChange={setGroup}
                    onProducerChange={setProducer}
                    containerStyles={{ maxWidth: 1000 }}
                />
            </Card>

            <Divider className="fw" />

            <NomenclaturePricesTable
                activeModel={model}
                activeProducer={producer}
                activeGroup={group}
                activeSeries={series}
            />
        </Layout.Detail>
    )
}
