import { Col, Row } from "antd"
import { AsyncSelect, Text } from "components/UI"
import { SelectEntity } from "./types"
import { Uuid } from "types"

interface ModelSelectProps {
    activeModel?: SelectEntity
    activeMultiModel?: SelectEntity[]
    setActiveModel?: (v?: SelectEntity) => void
    setMultiActiveModel?: (v: SelectEntity[]) => void
    activeGroupId?: Uuid
    activeSeriesId?: Uuid
    required?: boolean
    layout?: "vertical" | "horizontal"
    disabledModels?: SelectEntity[]
    skipValues?: Uuid[]
}

export function ModelSelect({
    activeModel,
    setActiveModel,
    activeMultiModel,
    setMultiActiveModel,
    required,
    activeGroupId,
    activeSeriesId,
    layout = "horizontal",
    skipValues,
}: ModelSelectProps) {
    const labelSpan = layout === "horizontal" ? 5 : 24
    const inputSpan = layout === "horizontal" ? 19 : 24

    const onChange = (e: any) => {
        if (setMultiActiveModel) {
            setMultiActiveModel(e)
        }
        if (setActiveModel) {
            setActiveModel(e)
        }
    }

    return (
        <Row gutter={[0, 10]} style={{ zIndex: 5 }}>
            <Col xs={24} md={labelSpan}>
                <Text.FormLabel required={required}>Модель</Text.FormLabel>
            </Col>
            <Col xs={24} md={inputSpan}>
                <AsyncSelect
                    value={activeMultiModel ?? activeModel}
                    isDisabled={!activeSeriesId}
                    onChange={onChange}
                    isClearable
                    dataUrl="model"
                    isMulti={!!setMultiActiveModel}
                    params={{
                        series: activeSeriesId,
                        itemsGroups: activeGroupId,
                    }}
                    skipValues={skipValues}
                />
            </Col>
        </Row>
    )
}
