import ValuesAsyncSelector from "components/QueryBuilder/ValueAsyncSelector"
import { ValueEditorProps } from "react-querybuilder"

function RequesitesValueSelector(props: ValueEditorProps) {
    const { field } = props

    return (
        <ValuesAsyncSelector
            {...props}
            requestData={{
                attribute: field,
            }}
            optionUrl="v1/query-builder/nomenclature/props/values-list"
        />
    )
}

export default RequesitesValueSelector
