import { useQuery } from "@tanstack/react-query"
import { Card, Checkbox, DatePicker } from "antd"
import { Layout } from "components/layouts"
import { Flex } from "components/UI/Flex"
import { FormLine } from "components/UI/FormLine"
import dayjs from "dayjs"
import api from "helpers/api"
import { useRegion } from "hooks/useRegion"
import PriceRecalculateModal from "./PriceRecaclulate.Modal"
import PriceRecalculateQueryBuilder from "./PriceRecalculate.QueryBuilder"
import { usePriceRecalculateState } from "./PriceRecalculate.state"
import PricesRecalculateHistory, {
    PriceRecalulateHistoryItem,
} from "./PricesRecalculate.History"

export default function PricesRecalculate() {
    const { selectedDate, setSelectedDate, onlyCostPrice, setOnlyCostPrice } =
        usePriceRecalculateState()

    const { activeRegion } = useRegion()

    const { data, isFetching, refetch } = useQuery<
        PriceRecalulateHistoryItem[]
    >({
        queryKey: ["nomenclature/get-price-recounter-log", activeRegion],
        queryFn: () => api.getTyped("nomenclature/get-price-recounter-log"),
        initialData: [],
    })

    return (
        <Layout.Container pageTitle="Пересчет цен">
            <Card className="fw">
                <Flex.Col fullWidth gap={20}>
                    <Flex.Col fullWidth gap={20}>
                        <FormLine label="Дата расчета">
                            <DatePicker
                                value={selectedDate}
                                onChange={setSelectedDate}
                                disabledDate={(current) => {
                                    const today = dayjs()
                                    return (
                                        current &&
                                        current < today.startOf("day")
                                    )
                                }}
                                style={{ minWidth: 300 }}
                            />
                        </FormLine>

                        <FormLine label="Рассчитать только цену от поставщика">
                            <Checkbox
                                checked={onlyCostPrice}
                                onChange={(e) =>
                                    setOnlyCostPrice(e.target.checked)
                                }
                            />
                        </FormLine>
                    </Flex.Col>
                    <PriceRecalculateQueryBuilder />
                    <PriceRecalculateModal onSuccess={refetch} />
                </Flex.Col>
            </Card>

            <PricesRecalculateHistory
                data={data}
                isFetching={isFetching}
                refetch={refetch}
            />
        </Layout.Container>
    )
}
