import React from "react"

export type Margin = string | number[]

const getMargin = (margin: Margin) => {
    if (typeof margin === "string") return margin

    return margin.map((el) => el + "px").join(" ")
}

export const getMarginProp = ({
    style,
    margin,
}: {
    style?: React.CSSProperties
    margin?: Margin
}) => {
    const newStyles = style ?? ({} as React.CSSProperties)

    if (!margin) return {}

    return {
        style: {
            ...newStyles,
            margin: getMargin(margin),
        },
    }
}
