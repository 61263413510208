import { SelectEntity } from "components/Selects/types"

export enum RegionStateAction {
    SET_REGION = "SET_REGION",
}

export const SET_CURRENT_REGION = (payload: SelectEntity | null) => {
    return {
        type: RegionStateAction.SET_REGION,
        payload,
    }
}
