import { FormProps } from "antd";

export const formLayout: Partial<FormProps>= {
  labelCol: {
    md: { span: 5 },
    xs: { span: 24}
  },
  wrapperCol: {
    md: { span: 19 },
    xs: { span: 24}
  },
  labelAlign: 'left',
}