import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_COMPARE_TYPE } from '../../../store/compareType/actions';
import { SET_CURRENT_VALUES } from '../../../store/fields/actions';

interface CompareTypeSelectorProps {
  name: string;
  inputType: string;
  filterType?: string;
  multiSelect?: boolean;
  select?: boolean;
}

const CompareTypeSelector: React.FC<CompareTypeSelectorProps> = ({ name, inputType, filterType, multiSelect, select }) => {
  const compareValuesForNumber = [
    { id: 1, label: 'Равно', value: 'eq' },
    { id: 2, label: 'Не равно', value: '!eq' },
    { id: 3, label: 'Больше', value: 'gt' },
    { id: 4, label: 'Меньше', value: 'lt' },
    { id: 5, label: 'Диапазон', value: 'range' },
    { id: 6, label: 'Задано', value: 'not_empty' },
    { id: 7, label: 'Не задано', value: '!not_empty' },
  ];

  const compareValuesForString = [
    { id: 1, label: 'Равно', value: 'eq' },
    { id: 2, label: 'Не равно', value: '!eq' },
    { id: 3, label: 'Содержит', value: multiSelect ? 'collection' : 'contain' },
    { id: 4, label: 'Не содержит', value: multiSelect ? '!collection' : '!contain' },
    { id: 6, label: 'Задано', value: 'not_empty' },
    { id: 7, label: 'Не задано', value: '!not_empty' },
  ];

  const dispatch = useDispatch();

  const compareType = useSelector((state: { [key: string]: any }) => state.compareType[name]) || '';

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'not_empty' || event.target.value === '!not_empty') {
      filterType === 'attributeFilter'
        ? dispatch(SET_CURRENT_VALUES({ [name]: event.target.value }, 'attributeFilter'))
        : dispatch(SET_CURRENT_VALUES({ [name]: event.target.value }, 'filter'));
      if (event.target.value.length > 0) {
        dispatch(SET_COMPARE_TYPE({ [name]: event.target.value }));
      }
    } else {
      filterType === 'attributeFilter'
        ? dispatch(SET_CURRENT_VALUES({ [name]: '' }, 'attributeFilter'))
        : dispatch(SET_CURRENT_VALUES({ [name]: '' }, 'filter'));
      dispatch(SET_COMPARE_TYPE({ [name]: event.target.value }));
    }
  };

  const handleValue = () => {
    if (inputType === 'number') {
      dispatch(SET_COMPARE_TYPE({ [name]: 'eq' }));
      return 'eq'
    }
    
    if (inputType === 'text') {
      if (select) {
        dispatch(SET_COMPARE_TYPE({ [name]: 'collection' }));
        return 'collection'
      } else {
        dispatch(SET_COMPARE_TYPE({ [name]: 'contain' }));
        return 'contain'
      }
    }
  }

  return (
    <select className="form-select" value={compareType ? compareType : handleValue()} onChange={(event) => handleChange(event)}>
      {(inputType === 'number' ? compareValuesForNumber : compareValuesForString).map((item) => {
        if ((multiSelect && item.value === 'eq') || (multiSelect && item.value === '!eq')) {
          return null;
        }
        return (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};

export default CompareTypeSelector;
