import { useMutation, useQuery } from "@tanstack/react-query"
import { AsyncSelect } from "components/UI"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import ContentForm from "../../../components/ContentForm"
import { SaveMenu } from "../../../components/UI"
import api from "../../../helpers/api"
import { isEmptyObject } from "../../../helpers/isEmptyObject"
import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../../../store/fields/actions"
import toast from "react-hot-toast"
// import { ProducerAttributesChooser } from "./ProducerAttributesChooser"
// import { ProducerAttributesValuesChooser } from "./ProducerAttributesValuesChooser"
// import { ProducerDetailsNamingChipsInput } from "./ProducerNaming/ProducerDetailsNamingChipsInput"

const useStyles = createUseStyles({
    linkingHeadline: {
        fontSize: "21px",
        marginBottom: 10,
    },
})

export const PicturesSettings: React.FC = () => {
    const [search, setSearch] = useState("")

    const { data: assignedSorts, refetch: refetchAssignedSorts } = useQuery<
        { index: number; attribute: { id: number } }[]
    >({
        queryKey: ["pictures-attributes-sorts"],
        initialData: [],
        queryFn: () =>
            api.getTyped("v1/pictures-generator/list-attributes-sorts"),
    })

    const setSortMutation = useMutation<
        unknown,
        unknown,
        { attribute: number; index: number }
    >(({ attribute, index }) => {
        return api.put(
            `v1/pictures-generator/set-attribute-sort/${attribute}`,
            {},
            {
                index,
            }
        )
    })

    const handleSetSort = async (attribute: number, index: number) =>
        toast.promise(
            setSortMutation.mutateAsync(
                {
                    attribute,
                    index,
                },
                {
                    onSuccess: () => refetchAssignedSorts(),
                }
            ),
            {
                loading: "Cохранение...",
                success: <b>Успешно сохранено!</b>,
                error: ({ response: { data: serverTextResponse } }) => {
                    const error =
                        serverTextResponse ??
                        "Не удалось применить изменения, неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )

    const { data } = useQuery<Record<string, string | boolean | number>[]>({
        queryKey: ["listing/attribute", search],
        initialData: [],
        queryFn: () =>
            api
                .getTyped("listing/attribute", {
                    sort: "name",
                    method: "asc",
                    page: 1,
                    count_on_page: 20,
                    q: search || undefined,
                })
                .then((v) =>
                    v.entities.map((entity: { fields: any[] }) =>
                        entity.fields.reduce(
                            (prev, curr) => ({
                                ...prev,
                                [curr.name]: curr.value,
                            }),
                            {}
                        )
                    )
                ),
    })

    return (
        <>
            <div className="control row">
                <div className="col-6">
                    <nav>
                        <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                        >
                            <button
                                className="nav-link active"
                                id="edit-settings-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#edit-settings"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                            >
                                Очередность свойств
                            </button>
                        </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="edit-settings"
                            role="tabpanel"
                            aria-labelledby="edit-settings-tab"
                        >
                            <div
                                className="control-view"
                                style={{ display: "block" }}
                            >
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Поиск"
                                    value={search}
                                    onChange={({ target }) =>
                                        setSearch(target.value)
                                    }
                                />

                                {!data?.length && "Ничего не найдено"}

                                {data?.map((v) => (
                                    <div className="col-12 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {v.name}
                                                </h5>
                                                <p className="card-text">
                                                    Очередь:{" "}
                                                    {assignedSorts?.find(
                                                        ({ attribute }) =>
                                                            attribute.id ===
                                                            v.id
                                                    )?.index ?? "по умолчанию"}
                                                </p>

                                                <div className="d-flex justify-content-end align-items-center">
                                                    <a
                                                        href="#"
                                                        className="btn btn-outline-primary"
                                                        onClick={() =>
                                                            handleSetSort(
                                                                v.id as number,
                                                                Number(
                                                                    prompt(
                                                                        "Введите число"
                                                                    )
                                                                ) ?? 1
                                                            )
                                                        }
                                                    >
                                                        Изменить
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PicturesSettings
