import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getListing } from "../store/listing/actions"

export const useSortingHelper = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const filterQuery =
        useSelector(
            (state: { [key: string]: any }) => state.bufferTemp?.filter
        ) || ""
    const attributesQuery =
        useSelector(
            (state: { [key: string]: any }) => state.bufferTemp?.attributes
        ) || ""
    const categoryID = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.category
    )
    const paginationSettings =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.pagination
        ) || {}

    return (apiPath: string, header: string, sortMethod: string) => {
        let query = `count_on_page=${
            paginationSettings.pageSize || 20
        }&method=${sortMethod}&page=${
            paginationSettings.currentPage || 1
        }&sort=${header}`
        let filtersURL = `${
            filterQuery.length > 0 ? `&filter=${filterQuery}` : ""
        }`
        let attributesURL = `${
            attributesQuery.length > 0
                ? `&attributeFilter=${attributesQuery}`
                : ""
        }`

        query = query + filtersURL + attributesURL

        if (categoryID && categoryID !== 1) {
            dispatch(getListing(`${apiPath}?cat=${categoryID}&${query}`))
            history.push(`?cat=${categoryID}&` + query)
        } else {
            dispatch(getListing(`${apiPath}?${query}`))
            history.push("?" + query)
        }
    }
}
