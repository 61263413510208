import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Producer } from "types/api"

export const useSeriesUpdateData = () => {
    const params = useParams() as { id?: number }

    const producer = useSelector(
        (state: any) => state.fields?.currentValues?.main?.producer
    ) as Producer | undefined

    return {
        id: params.id,
        producerId: producer?.id,
    }
}
