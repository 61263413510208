import InputAttributeGlassing from "components/inputs/InputAttributeGlassing"
import InputAttributeMaterial from "components/inputs/InputAttributeMaterial"
import InputCurrencySelect from "components/inputs/InputCurrencySelect"
import InputModelSelect from "components/inputs/InputIModelSelect"
import InputMeasureSelect from "components/inputs/InputMeasureSelect"
import MarginTypeSelect from "components/inputs/MarginTypeSelect"
import ParentGroupSelect from "components/inputs/ParentGroupSelect"
import PriceTypeSelect from "components/inputs/PriceTypeSelect"
import RegionInput from "components/inputs/RegionInput"
import { FC } from "react"
import { InputProps } from "types/interfaces"
import { SelectCategories } from "../components/Import/SelectCategories"
import InputAttributeSelect from "../components/inputs/InputAttributeSelect"
import InputCheckbox from "../components/inputs/InputCheckbox"
import { InputCollection } from "../components/inputs/InputCollection"
import InputCountrySelect from "../components/inputs/InputCountrySelect"
import { InputCSVFile } from "../components/inputs/InputCSVFile"
import InputFile from "../components/inputs/InputFile"
import InputGlassingSelect from "../components/inputs/InputGlassingSelect"
import InputItemsGroupMultiSelect from "../components/inputs/InputItemsGroupMultiSelect"
import InputItemsGroupSelect from "../components/inputs/InputItemsGroupSelect"
import InputMaterialSelect from "../components/inputs/InputMaterialSelect"
import { InputMultipleFile } from "../components/inputs/inputMultipleFile/InputMultipleFile"
import InputNumber from "../components/inputs/InputNumber"
import InputOkrbSelect from "../components/inputs/InputOkrbSelect"
import InputProducerSelect from "../components/inputs/InputProducerSelect"
import InputSelect from "../components/inputs/InputSelect"
import InputSelectAccess from "../components/inputs/InputSelectAccess"
import InputSeriesSelect from "../components/inputs/InputSeriesSelect"
import InputText from "../components/inputs/InputText"
import { InputTextArea } from "../components/inputs/InputTextArea"
import InputTnvedSelect from "../components/inputs/InputTnvedSelect"
import { KeyValue } from "../components/inputs/KeyValue"
import { KeyValueWithSelect } from "../components/inputs/KeyValueWithSelect"
import MultipleInputText from "../components/inputs/MultipleInputText"
import { MultipleList } from "../components/inputs/MultipleList"
import RolesCheckboxes from "../components/inputs/RolesCheckboxes"

export const formElementComponents = {
    text: InputText,
    password: InputText,
    input: InputText,
    uuid: InputText,
    multipleText: MultipleInputText,
    multipleNumber: MultipleInputText,
    multipleFile: InputMultipleFile,
    number: InputNumber,
    integer: InputText,
    select: InputSelect,
    series: InputSeriesSelect,
    tnved: InputTnvedSelect,
    okrb: InputOkrbSelect,
    attribute: InputAttributeSelect,
    itemsGroup: InputItemsGroupSelect,
    itemsGroupMulti: InputItemsGroupMultiSelect,
    producer: InputProducerSelect,
    attributeFirstValue: InputMaterialSelect,
    attributeFirst: InputAttributeMaterial,
    attributeSecond: InputAttributeGlassing,
    attributeSecondValue: InputGlassingSelect,
    producingCountry: InputCountrySelect,
    importCountry: InputCountrySelect,
    country: InputCountrySelect,
    currency: InputCurrencySelect,
    multipleRolesCollection: InputSelectAccess,
    multipleSelect: InputSelect,
    textarea: InputTextArea,
    checkbox: InputCheckbox,
    rolesCheckbox: RolesCheckboxes,
    file: InputFile,
    csvFile: InputCSVFile,
    collection: InputCollection,
    valuesCollection: MultipleInputText,
    attributeValuesObject: MultipleInputText,
    list: MultipleList,
    map: KeyValueWithSelect,
    KeyValue: KeyValue,
    multipleSelectCategories: SelectCategories,
    measureUnit: InputMeasureSelect,
    measure_unit: InputMeasureSelect,
    notFound: (() => null) as FC<InputProps>,
    entity: null,
    parentGroup: ParentGroupSelect,
    priceType: PriceTypeSelect,
    marginTypeSelect: MarginTypeSelect,
    region: RegionInput,
    model: InputModelSelect,
}

export type FormElementComponents = typeof formElementComponents
export type FormElementType = keyof FormElementComponents
