import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_VALUES } from '../../../../store/fields/actions';
import CompareTypeSelector from '../../filterComponents/CompareTypeSelector';
import { Uuid } from 'types';

interface InputSelectProps {
  id: Uuid;
  name: string;
  isMultiple: boolean;
  listValues: [string | number];
}

export const InputSelect: React.FC<InputSelectProps> = ({ id, name, isMultiple, listValues }) => {
  const dispatch = useDispatch();

  const value =
    useSelector((state: { [key: string]: any }) => state.fields.currentValues['attributeFilter']?.[id]) || [];
  const compareType = useSelector((state: { [key: string]: any }) => state.compareType[id]) || '';
  const currentValuesAttributeFilter = useSelector(
    (state: { [key: string]: any }) => state.fields?.currentValues.attributeFilter
  );

  const optionHandler = (event: any) => {
    let value: any;
    value = [];
    const option: any = Array.from(event.target.selectedOptions);
    option.forEach((element: any) => {
      value.push(element.value);
    });
    if (event.target.selectedOptions.length > 0) {
      dispatch(SET_CURRENT_VALUES({ [id]: value }, 'attributeFilter'));
    } else {
      const copy = currentValuesAttributeFilter;
      delete copy[id];
      dispatch(SET_CURRENT_VALUES(copy, 'attributeFilter'));
    }
  };

  // React.useEffect(() => {
  //   dispatch(SET_COMPARE_TYPE({ [id]: 'collection' }));
  // }, [])

  return (
    <div className="row mb-3">
      <label className="col-xl-2 col-form-label">{name}</label>
      <div className="col-xl-10">
        <div className="row">
          <div className="col-xl-4">
            <CompareTypeSelector name={id} inputType="text" filterType="attributeFilter" multiSelect select={true} />
          </div>
          {compareType !== 'not_empty' && compareType !== '!not_empty' && (
            <div className="col-xl-8">
              <select
                className="form-select"
                multiple={compareType !== 'eq' && compareType !== '!eq' ? true : false}
                value={value}
                onChange={(event) => optionHandler(event)}
                name={id}
                size={5}
              >
                <option value="">Не выбрано</option>
                {listValues.map((item, index: number) => {
                  if (item === null) return null;
                  return (
                    <option key={`${id}-${index}`} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
