import { useQuery } from "@tanstack/react-query"
import { Layout } from "components/layouts"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import UNumberInput from "components/UNumberItem"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useNotifications } from "hooks/useNotifications"
import { useMemo, useRef, useState } from "react"
import { Uuid } from "types"
import { Attribute, ListingResponse } from "types/api"
import StallerSelectModel from "./Staller.selectModel"

const attributeId = "bd033c4a-8947-4efa-acd1-7b87ff17cf3d"

type TableRow = {
    attributeValue: Uuid
    attributeName: string
    value?: number
    id?: Uuid
}

export default function Staller() {
    const { data, isFetching, dataUpdatedAt } = useQuery<Attribute>({
        queryKey: ["attribute", attributeId],
        queryFn: () => api.getTyped<Attribute>("attribute/" + attributeId),
    })

    const { handleApiError, showNotification } = useNotifications()
    const [activeRow, setActiveRow] = useState<Uuid | null>(null)
    const [activeModel, setActiveModel] = useState<SelectEntity | null>(null)
    const activeRowValue = useRef<any>()
    const {
        data: listingData,
        refetch,
        isFetching: listingFetching,
        dataUpdatedAt: listingDataUpdatedAt,
    } = useQuery<ListingResponse>({
        queryKey: ["listing/model-value-staller", activeModel],
        queryFn: () =>
            api.getTyped<ListingResponse>("listing/model-value-staller", {
                model: activeModel?.value,
            }),
    })

    const tableData: TableRow[] = useMemo(() => {
        if (!data?.valuesList) return []
        const entities = listingData?.entities
        return Object.entries(data.valuesList).map(
            ([attributeValue, attributeName]) => {
                const record = entities?.find((el) => {
                    const recordAttributeValue = getFieldValue<
                        Record<Uuid, string>
                    >(el.fields, "attributeValue")
                    return (
                        attributeValue === Object.keys(recordAttributeValue!)[0]
                    )
                })

                return {
                    attributeValue,
                    attributeName,
                    value: record
                        ? getFieldValue<number>(record.fields, "value")
                        : undefined,
                    id: record?.id,
                }
            }
        )
    }, [data, listingData])

    const mutate = ({
        attributeValue,
        value,
        id,
    }: {
        attributeValue: Uuid
        value: number
        id?: Uuid
    }) => {
        const action = id ? api.put.bind(api) : api.post.bind(api)
        action(
            "reference/model-value-staller" + (id ? `/${id}` : ""),
            {},
            {
                attributeValue,
                value,
                model: activeModel?.value,
            }
        )
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Успех",
                })
            })
            .catch(handleApiError)
            .finally(refetch)
    }

    return (
        <Layout.Container pageTitle="Сталлер. Расчет от площади превышения.">
            <Flex.Row fullWidth justify="start" gap={20} styles={{ zIndex: 2 }}>
                <StallerSelectModel setModel={setActiveModel} />
            </Flex.Row>
            <UTable
                key={dataUpdatedAt + listingDataUpdatedAt}
                isRowActive={(row) => row.attributeValue === activeRow}
                onRowClick={(row) => setActiveRow(row.attributeValue)}
                data={tableData}
                loading={isFetching || listingFetching}
                columns={[
                    {
                        columnName: "Название",
                        render: (row) => row.attributeName,
                        width: 50,
                    },
                    {
                        columnName: "Допустимая площадь, м.2",
                        width: 50,
                        render: (row) => (
                            <UNumberInput
                                value={row.value}
                                noBackground
                                floatRank={2}
                                min={0}
                                noFormControl
                                onPressEnter={(value) => {
                                    if (activeRowValue.current !== value) {
                                        mutate({
                                            ...row,
                                            value,
                                        })
                                    }
                                    return
                                }}
                                focused={row.attributeValue === activeRow}
                                handleFocus={(e) => {
                                    setActiveRow(row.attributeValue)
                                    activeRowValue.current = e
                                }}
                                handleBlur={(value) => {
                                    if (activeRowValue.current !== value) {
                                        mutate({
                                            ...row,
                                            value,
                                        })
                                    }
                                    return
                                }}
                                navigateProps={{
                                    onNext: () => {
                                        const currentIndex =
                                            tableData.findIndex(
                                                (el) =>
                                                    el.attributeValue ===
                                                    activeRow
                                            )
                                        const nextIndex = currentIndex + 1
                                        const index =
                                            nextIndex > tableData.length - 1
                                                ? 0
                                                : nextIndex
                                        setActiveRow(
                                            tableData[index].attributeValue
                                        )
                                    },
                                    onPrev: () => {
                                        const currentIndex =
                                            tableData.findIndex(
                                                (el) =>
                                                    el.attributeValue ===
                                                    activeRow
                                            )
                                        const prevIndex = currentIndex - 1
                                        const index =
                                            prevIndex < 0
                                                ? tableData.length - 1
                                                : prevIndex
                                        setActiveRow(
                                            tableData[index].attributeValue
                                        )
                                    },
                                }}
                            />
                        ),
                    },
                ]}
            />
        </Layout.Container>
    )
}
