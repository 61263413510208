import { SyncOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Button, Modal, Spin, Typography } from "antd"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useEffect, useState } from "react"
import { Uuid } from "types"
import { NomenclatureItem } from "types/api"
import { KitItemData } from "./types"

type Paginator = {
    allPages: number
    currentPage: number
    pageSize: number
    totalCount: number
}

export type NomenclatureResponse = {
    entityList: NomenclatureItem[]
    paginator: Paginator
}

type NomenclatureRequest = {
    countOnPage: number
    page: number
    payload: {
        group: SelectEntity
        query: KitItemData["query"]
    }
}

const defaultPaginator = {
    allPages: 0,
    currentPage: 1,
    pageSize: 100,
    totalCount: 0,
}

export default function KitsItemsShowResult({
    data,
    editItemId,
}: {
    data: KitItemData
    editItemId?: Uuid | null
}) {
    const { group, query } = data
    const [paginator, setPaginator] = useState<Paginator>(defaultPaginator)
    const [openModal, setOpenModal] = useState(false)
    const [tableData, setTableData] = useState<NomenclatureItem[]>([])
    const [disableNextFetch, setDisableNextFetch] = useState(false)
    const [lastFetchedPage, setLastFetchedPage] = useState(0)

    const requestData =
        query?.rules
            ?.map((el: any) =>
                [el.id, el.operator, el.field, el.value].join("-")
            )
            ?.join(",") ?? ""
    const resetPaginator = () => {
        setPaginator(defaultPaginator)
        setTableData([])
    }

    useEffect(() => {
        resetPaginator()
    }, [requestData, editItemId])

    useEffect(() => {
        setLastFetchedPage(0)
    }, [editItemId])

    const { isFetching, isLoading, refetch, remove, dataUpdatedAt } =
        useQuery<NomenclatureResponse>({
            queryKey: [
                group,
                paginator,
                query,
                lastFetchedPage,
                "fetch-kits",
                editItemId,
            ],
            enabled:
                !!group &&
                !!query?.rules?.length &&
                !!query?.rules?.every((el: any) => !!el.value) &&
                lastFetchedPage !== paginator.currentPage,
            queryFn: () => {
                return api
                    .post<NomenclatureRequest, NomenclatureResponse>(
                        `v1/query-builder/kits/result`,
                        {},
                        {
                            page: paginator.currentPage,
                            countOnPage: paginator.pageSize,
                            payload: {
                                query,
                                group: group!,
                            },
                        }
                    )
                    .then((response) => {
                        const data = response.data
                        console.log(data.paginator.currentPage)
                        setPaginator(data.paginator)
                        setTableData((prev) =>
                            data.paginator.currentPage === 1
                                ? data.entityList
                                : [...prev, ...data.entityList]
                        )
                        setDisableNextFetch(true)
                        setLastFetchedPage(data.paginator.currentPage)
                        setTimeout(() => setDisableNextFetch(false), 2000)
                        return response.data
                    })
            },
        })

    const reload = () => {
        remove()
        resetPaginator()
        refetch()
    }

    return (
        <Flex.Row gap={20} justify="end" styles={{ gridColumn: "1 / 4" }}>
            <Button
                disabled={!tableData.length}
                onClick={() => setOpenModal(true)}
            >
                Показать номенклатуру
            </Button>
            <Button
                disabled={!group}
                onClick={reload}
                icon={<SyncOutlined />}
            />
            <Flex.Row justify="center" align="center" styles={{ width: 200 }}>
                {!isFetching && (
                    <Typography.Text>
                        {paginator.totalCount
                            ? `Найдено: ${paginator.totalCount}`
                            : "Не найдено"}
                    </Typography.Text>
                )}
                {isFetching && <Spin />}
            </Flex.Row>
            <Modal
                open={openModal}
                onCancel={() => setOpenModal(false)}
                title="Найденная номенклатура"
                cancelButtonProps={{ hidden: true }}
                okText="Закрыть"
                onOk={() => setOpenModal(false)}
                style={{ height: 700, width: "80vw" }}
                width={"80vw"}
            >
                <UTable
                    key={dataUpdatedAt}
                    resetScrollKey={requestData}
                    height={700}
                    isFetching={isFetching}
                    VIRTUAL_ROW_COUNT={100}
                    onScrollFinish={() => {
                        if (isFetching) return
                        if (paginator.currentPage >= paginator.allPages) return
                        setPaginator((prev) => ({
                            ...prev,
                            currentPage: prev.currentPage + 1,
                        }))
                    }}
                    onRowClick={(row) =>
                        window.open(`/nomenclature/update-${row.id}`, "_blank")
                    }
                    data={tableData}
                    columns={[
                        {
                            render: (row) => row.name,
                            width: 100,
                            columnName: "Название",
                        },
                    ]}
                />
            </Modal>
        </Flex.Row>
    )
}
