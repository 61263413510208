/**
 * Сравнивает два массива объектов на одинаковые элементы
 * для настройки колонок в листинге
 */

export const compare = (source: any[], settings: any[]): any => {
    return source.filter((header: { [key: string]: any }) =>
        settings.some(
            (item: { [key: string]: any }) =>
                item.columnName === header.columnName
        )
    )
}
