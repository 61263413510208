interface GateContainerProps {
  children: React.ReactNode;
  isShow: boolean;
  plug?: any;
}

/**
 * Контейнер для отображения содержимого если isShow === true
 */

export const GateContainer = ({ children, isShow, plug }: GateContainerProps) => {
  if (!isShow) return plug ?? null
  return <>{children}</>
}