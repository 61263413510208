/** LOADER */
export const loaderShowed = (state: any = false, action: any) => {
    switch (action.type) {
        case "SET_LOADER":
            return {
                ...state,
                loaderShowed: true,
            }
        case "CLEAR_LOADER":
            delete state.loaderShowed
            return {
                ...state,
                loaderShowed: false,
            }

        default:
            return state
    }
}
