import { Popover } from "antd"
import clsx from "clsx"
import React, { useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { Uuid } from "types"
import { AttributeGroupProducerSeriesLink } from "./index"

interface AttributesChooserProps {
    attributes: AttributeGroupProducerSeriesLink[]
    checkedValues?: string[]
    onChange: (id: Uuid) => void
    active?: Uuid
    withCheckboxes: boolean
    checked?: Uuid[]
    onBulk?: (state: boolean, attribute: Uuid) => void
    matchAll?: boolean
    onMatchAllChange?: (v: boolean) => void
}

const useStyles = createUseStyles({
    valueCell: {
        width: "100%",
        border: "1px solid #dee2e6",
        borderTop: "none",
        borderBottom: "none",
        cursor: "pointer",
        borderLeft: "none",
    },
    headlineCell: {
        width: "100%",
    },
    searchCell: {
        width: "100%",
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    searchBoxInput: {
        width: "100%",
        outline: "none",
        border: "1px solid #dee2e6",
        borderBottom: "none",
        padding: "5px 10px",
    },
    activeRow: {
        background: "#eee",
    },
    row: {
        width: "100%",
        display: "flex",
        borderTop: "none",
    },
})

export const AttributesChooser: React.FC<AttributesChooserProps> = (props) => {
    const classes = useStyles()
    const [search, setSearch] = useState("")

    const filteredRows = useMemo(
        () =>
            props.attributes
                .filter((item: AttributeGroupProducerSeriesLink) => {
                    if (search === "") return true
                    return item.attribute.name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                })
                .map((row) => ({
                    ...row,
                    isChecked: !Object.keys(
                        props.attributes?.find(
                            (v) => v.attribute.id === row.attribute.id
                        )?.datatypeOptions ?? {}
                    ).some((value) => !props.checkedValues?.includes(value)),
                })),
        [props.attributes, search, props.checkedValues]
    )

    return (
        <>
            <div>
                <input
                    className={classes.searchBoxInput}
                    type="text"
                    value={search}
                    placeholder="Поиск"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table
                className="table table-bordered table-users"
                style={{
                    display: "block",
                    width: "100%",
                }}
            >
                <thead
                    style={{
                        width: "100%",
                        display: "inline-table",
                    }}
                >
                    <tr>
                        <th>
                            <Popover title="Все со всем" placement="left">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{
                                        opacity: props.onMatchAllChange ? 1 : 0,
                                    }}
                                    checked={props.matchAll}
                                    onChange={(e) =>
                                        props.onMatchAllChange?.(
                                            e.target.checked
                                        )
                                    }
                                />
                            </Popover>
                        </th>

                        <th className={classes.headlineCell}>
                            {props?.checked && `[${filteredRows.length}] `}
                            Свойство
                        </th>
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {filteredRows.map(({ attribute, isChecked }) => (
                        <tr
                            key={attribute.id}
                            className={clsx(
                                classes.row,
                                props.active === attribute.id &&
                                    classes.activeRow
                            )}
                        >
                            <td>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() =>
                                        props.onBulk &&
                                        props.onBulk(!isChecked, attribute.id)
                                    }
                                    style={{
                                        opacity: props.withCheckboxes ? 1 : 0,
                                    }}
                                />
                            </td>
                            <td
                                className={classes.valueCell}
                                onClick={() => props.onChange(attribute.id)}
                            >
                                {attribute.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
