import type { RuleGroupType } from "react-querybuilder"

export type QueryBuilderItemData<T> = {
    query?: RuleGroupType
} & T

export const inputOperators = [
    "contains",
    "doesNotContain",
    "beginsWith",
    "doesNotBeginWith",
    "endsWith",
    "doesNotEndWith",
]

export const notFilterFields = ["ig", "m", "s", "p"]
