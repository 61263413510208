import { Precision } from "./CostCollector.store"

export const applyPrecision = (value: number, initPrecision?: Precision) => {
  const precision = initPrecision ?? 0;
  const ratio = Math.pow(10, precision ?? 0)
  if (precision >= 0) {
      return Math.round(value / ratio) * ratio
  } else {
    const _value = Math.round(value * (1 / ratio)) / ( 1 / ratio );
    return _value
  }
}
