import React from "react"
import { Filter } from "types"
import { InputDate } from "./filterComponents/InputDate"
import { InputNumber } from "./filterComponents/InputNumber"
import { InputSelect } from "./filterComponents/InputSelect"
import { InputText } from "./filterComponents/InputText"

interface FilterComponentSelecterProps {
    field: Filter
    onClickHandler?: any
}

const renderAssociate: { [key: string]: any } = {
    text: InputText,
    number: InputNumber,
    boolean: InputSelect,
    date: InputDate,
    // 'list': InputCheckbox,
}

export const FilterComponentSelecter: React.FC<
    FilterComponentSelecterProps
> = ({ field, onClickHandler }) => {

    for (const key in renderAssociate) {
        const ComponentName = renderAssociate[key]
        if (key === field.filter.type)
            return <ComponentName {...field} onClickHandler={onClickHandler} />
    }

    return null
}
