import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isEmptyObject } from '../helpers/isEmptyObject';
import ContentForm from '../components/ContentForm';
import { SaveMenu } from '../components/UI/SaveMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentValues, INIT_STATE_GROUP } from '../store/fields/actions';

export const AccessControlPage: React.FC = () => {
  const { initFields, currentValues } = useSelector((state: { [key: string]: any }) => state?.fields);

  const first_name = currentValues['main']?.first_name || '';
  const last_name = currentValues['main']?.last_name || '';

  const name: string = currentValues['main']?.name || `${first_name} ${last_name}` || '';

  const location: { [key: string]: any } = useLocation();
  const pageID: string = location.state.pageID || '';

  const dispatch = useDispatch();
  const routerParams: { [key: string]: string } = useParams();

  const title: string = routerParams.id ? 'Редактирование' : 'Создание';

  const onModifySendData = React.useCallback((data: any) => {
    for (const code in data) {
      if (code === 'id') {
        delete data[code];
      }
      if (code === 'created_at') {
        delete data[code];
      }
      if (code === 'updated_at') {
        delete data[code];
      }
      if (code === 'password' && routerParams.id) {
        delete data[code];
      }
      if (code === 'roles') {
        data[code] = data[code].filter((item: any) => item.isActive).map((item: any) => item.name);
      }
    }
  }, []);

  useEffect(() => {
    document.title = title;
    dispatch(INIT_STATE_GROUP({ main: {} }));

    // ? Это вроде как не надо || dispatch(getFieldsFromServer(`${pageID}/fields`));
    dispatch(getCurrentValues(`${pageID}`, routerParams.id, 'main'));
  }, []);

  return (
    <>
      <h1 className="h3 mb-3">
        {title}
        {routerParams.id ? ` "${name || '...'}"` : null}
      </h1>
      <SaveMenu url={`${pageID}`} onModify={onModifySendData} />
      <div className="control">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="edit-settings-tab"
              data-bs-toggle="tab"
              data-bs-target="#edit-settings"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Настройка
            </button>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="edit-settings"
            role="tabpanel"
            aria-labelledby="edit-settings-tab"
          >
            <div className="control-view">
              <div className="control-view__main">
                <div className="control-in">
                  <h5>Основное</h5>
                  {isEmptyObject(currentValues) ? (
                    <div>Загрузка...</div>
                  ) : (
                    <ContentForm isCreate={!routerParams.id} group={'main'} fields={initFields} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaveMenu url={pageID} onModify={onModifySendData} />
    </>
  );
};
