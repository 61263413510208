import { pageIDs } from "pageConfig"
import React from "react"
import PrivateRoute from "../PrivateRoute"
import { WithHistory } from "./withHistory"

export type CreateRouteData = {
    pathPrefix?: string
    isUpdate?: boolean
    isCreate?: boolean
    pageID: keyof typeof pageIDs
    element: React.ComponentType<any>
    apiPathPrefix?: string
    apiPathOn?: boolean
}

const createPathBase = ({
    pathPrefix,
    pageID,
}: Pick<CreateRouteData, "pageID" | "pathPrefix">) => {
    const pageSlug = pageIDs[pageID]
    return pathPrefix ? `/${pathPrefix}/${pageSlug}` : `/${pageSlug}`
}

const getPath = ({
    pageID,
    pathPrefix,
    isCreate,
    isUpdate,
}: Pick<
    CreateRouteData,
    "pageID" | "isCreate" | "isUpdate" | "pathPrefix"
>) => {
    const paths: string[] = []
    const base = createPathBase({ pathPrefix, pageID })
    if (!isCreate && !isUpdate) return [base]
    if (isCreate) {
        paths.push(`${base}/create`)
    }
    if (isUpdate) {
        paths.push(`${base}/update:id`)
    }
    return paths
}

export const CreateRoute = ({
    pageID,
    pathPrefix,
    element,
    apiPathPrefix,
    isCreate,
    isUpdate,
    apiPathOn,
    credentials,
}: CreateRouteData & { credentials?: string[] }) => {
    return ({ setLocationState, key }: WithHistory & { key: any }) => (
        <PrivateRoute
            exact
            path={getPath({ pageID, pathPrefix, isUpdate, isCreate })}
            component={element}
            apiPath={
                apiPathOn || apiPathPrefix
                    ? (apiPathPrefix ? apiPathPrefix + "/" : "") +
                      pageIDs[pageID]
                    : undefined
            }
            credentials={credentials}
            requiredPageIDs={[
                `${pathPrefix ? pathPrefix + "/" : ""}${pageIDs[pageID]}`,
            ]}
            pageIds={pageID}
            key={key}
        />
    )
}
