import { Flex } from "components/UI/Flex"
import RequisitesRegions from "./Requesites.region"

export default function RequesitesStock() {
    return (
        <Flex.Col gap={20} fullWidth>
            <RequisitesRegions />
        </Flex.Col>
    )
}
