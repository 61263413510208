import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import ContentForm from "../components/ContentForm"
import { SaveMenu } from "../components/UI/SaveMenu"
import { useDispatch, useSelector } from "react-redux"
import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../store/fields/actions"
import { isEmptyObject } from "../helpers/isEmptyObject"

export const Attributes: React.FC = () => {
    const state = useSelector((state: { [key: string]: any }) => state)
    const fields: object[] = state.fields.initFields
    const currentValues = state.fields.currentValues?.["main"]
    const attributeName: string = state.fields.currentValues["main"]?.name || ""

    const dispatch = useDispatch()
    const routerParams: { [key: string]: string } = useParams()

    const title: string = routerParams.id
        ? "Редактирование свойства"
        : "Создание свойства"

    useEffect(() => {
        document.title = title
        dispatch(
            INIT_STATE_GROUP({
                main: {},
                settings: {},
                history: {},
                defaultView: {},
            })
        )
        if (routerParams.id) {
            dispatch(getFieldsFromServer("attribute/fields?id=" + routerParams.id))
        }
        dispatch(getCurrentValues("attribute", routerParams.id))
    }, [dispatch, routerParams.id, title])

    const onModifySendData = (data: any) => {
        for (const code in data) {
            // * Clear ID
            if (code === "id") {
                delete data[code]
            }
            if (typeof data[code] === "object" && data[code]?.id) {
                data[code] = data[code].id
            }
        }
    }

    return (
        <>
            <h1 className="h3 mb-3">
                {title}
                {routerParams.id ? ` "${attributeName || "..."}"` : null}
            </h1>
            <SaveMenu url={"attribute"} onModify={onModifySendData} />
            <div className="control">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="attribute-settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#attribute-settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Настройки свойства
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="attribute-settings"
                        role="tabpanel"
                        aria-labelledby="attribute-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>
                                    {isEmptyObject(currentValues) ? (
                                        <div>Загрузка...</div>
                                    ) : (
                                        <ContentForm
                                            group={"main"}
                                            isCreate={!routerParams.id}
                                            fields={fields}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="control-view__settings">
                                <div className="control-in">
                                    <h5>Настройки</h5>
                                    <div className="control-view__id">
                                        {routerParams.id
                                            ? `ID: ${routerParams.id}`
                                            : null}
                                    </div>
                                    <ContentForm
                                        group={"settings"}
                                        fields={fields}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SaveMenu url={"attribute"} onModify={onModifySendData} />
        </>
    )
}
