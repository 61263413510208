import { useQuery } from "@tanstack/react-query"
import React, { useMemo, useState } from "react"
import Select from "react-select"
import { useSet } from "react-use"
import { Uuid } from "types"
import { AsyncSelect } from "../../components/UI"
import api from "../../helpers/api"
import MassGenerationHisotry from "./MassGeneration/MassGenerationHistory"
import MassGenerationPicker from "./MassGeneration/MassGenerationValuesPicker"

export const MassGeneration: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [lastMessage, setLastMessage] = useState(
        "Нажмите кнопку справа, чтобы начать процесс"
    )
    const [unitType, setUnitType] = useState<{
        label: string
        value: string
    }>({
        value: "custom",
        label: "заказная",
    })
    const [selectedValues, selectValuesActions] = useSet(new Set<string>([]))

    const [activeProducer, setActiveProducer] = useState<
        | {
              label: string
              value: number
          }
        | undefined
    >(undefined)
    const [activeGroup, setActiveGroup] = useState<
        | {
              label: string
              value: number
          }
        | undefined
    >(undefined)
    const [activeSeries, setActiveSeries] = useState<
        | {
              label: string
              value: number
          }
        | undefined
    >(undefined)
    const [activeModel, setActiveModel] = useState<
        {
            label: string
            value: number
        }[]
    >([])

    const isEverythingFilled = useMemo(
        () => !!(activeModel && activeSeries && activeGroup && activeProducer),
        [activeSeries, activeGroup, activeModel, activeProducer]
    )

    const { data: attributes } = useQuery<
        {
            attribute: {
                name: string
                valuesList: {
                    [uuid: Uuid]: string
                }
            }
        }[]
    >({
        queryKey: ["configurator-attributes", activeProducer, activeModel],
        enabled: !!(activeProducer?.value && activeModel.length),
        queryFn: () =>
            api.getTyped("v1/nomenclature/configurator-attributes", {
                producer: activeProducer!.value,
                models: activeModel.map(({ value }) => value).join(","),
            }),
        initialData: [],
        onSuccess: (data) => {
            selectValuesActions.reset()
            data.forEach(({ attribute }) =>
                Object.keys(attribute.valuesList).forEach((id) =>
                    selectValuesActions.add(id)
                )
            )
        },
    })

    const handleSubmit = async () => {
        setIsLoading(true)
        setLastMessage(
            "Запущено! Ожидайте, в среднем процесс может занимать до 5-и минут, в зависимости от моделей. " +
                "Процесс можете отслеживать ниже в Истории задач."
        )

        try {
            await api.post<{}, string>(
                "v1/nomenclature/mass-generate",
                {},
                {
                    producer: activeProducer!.value,
                    models: activeModel!.map(({ value }) => value),
                    values: Array.from(selectedValues),
                    type: unitType.value,
                }
            )

            // setLastMessage(data)
        } catch (e: any) {
            setLastMessage(e.response.data)
        }

        setIsLoading(false)
    }

    const handleToggleAllValues = () => {
        const valuesList = attributes.reduce<string[]>(
            (prev, curr) => [
                ...prev,
                ...Object.keys(curr.attribute.valuesList),
            ],
            []
        )

        const isSomethingNotChecked = valuesList.some(
            (valueId) => !selectValuesActions.has(valueId)
        )

        if (isSomethingNotChecked) {
            return valuesList.forEach((v) => selectValuesActions.add(v))
        }

        return selectValuesActions.reset()
    }

    return (
        <>
            <div className="control row">
                <div className="col-xl-10 col-md-12">
                    <nav>
                        <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                        >
                            <button
                                className="nav-link active"
                                id="edit-settings-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#edit-settings"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                            >
                                Массовая генерация номенклатур
                            </button>
                        </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="edit-settings"
                            role="tabpanel"
                            aria-labelledby="edit-settings-tab"
                        >
                            <div
                                className="control-view"
                                style={{ display: "block" }}
                            >
                                <div className="control-in">
                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Номенклатурная группа
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(e) => {
                                                    setActiveProducer(undefined)
                                                    setActiveSeries(undefined)
                                                    setActiveModel([])
                                                    setActiveGroup(
                                                        e as typeof activeGroup
                                                    )
                                                    selectValuesActions.reset()
                                                }}
                                                dataUrl="items-group"
                                                isDisabled={isLoading}
                                                page={1}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Поставщик
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeGroup?.value ||
                                                    isLoading
                                                }
                                                onChange={(e) => {
                                                    setActiveSeries(undefined)
                                                    setActiveModel([])
                                                    setActiveProducer(
                                                        e as typeof activeProducer
                                                    )
                                                    selectValuesActions.reset()
                                                }}
                                                dataUrl="producer"
                                                params={{
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Серия
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeGroup?.value ||
                                                    !activeProducer?.value ||
                                                    isLoading
                                                }
                                                onChange={(e) => {
                                                    setActiveSeries(
                                                        e as typeof activeSeries
                                                    )
                                                    setActiveModel([])
                                                    selectValuesActions.reset()
                                                }}
                                                dataUrl="series"
                                                params={{
                                                    producer:
                                                        activeProducer?.value,
                                                    itemsGroup:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Модель
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeSeries?.value ||
                                                    isLoading
                                                }
                                                isMulti={true}
                                                onChange={(e) => {
                                                    setActiveModel(
                                                        e as typeof activeModel
                                                    )
                                                    selectValuesActions.reset()
                                                }}
                                                dataUrl="model"
                                                key={activeSeries?.value}
                                                params={{
                                                    series: activeSeries?.value,
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {!!attributes.length && (
                                        <div className="row mb-3">
                                            <label className="col-xl-3 col-form-label">
                                                Значения
                                                <div
                                                    style={{
                                                        color: "#0d6efd",
                                                        fontSize: "12px",
                                                        cursor: "pointer",
                                                        fontWeight: "400",
                                                    }}
                                                    onClick={() =>
                                                        handleToggleAllValues()
                                                    }
                                                >
                                                    переключить
                                                </div>
                                            </label>
                                            <div className="col-xl-9">
                                                <MassGenerationPicker
                                                    // key={Array.from(
                                                    //     selectedValues
                                                    // ).toString()}
                                                    onChange={(id, state) =>
                                                        state
                                                            ? selectValuesActions.add(
                                                                  id
                                                              )
                                                            : selectValuesActions.remove(
                                                                  id
                                                              )
                                                    }
                                                    selectedIds={selectedValues}
                                                    attributes={attributes.map(
                                                        ({ attribute }) => ({
                                                            label: attribute.name,
                                                            values: attribute.valuesList,
                                                        })
                                                    )}
                                                    isDisabled={isLoading}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="row">
                                        <label className="col-xl-3 col-form-label">
                                            Характеристика
                                        </label>
                                        <div className="col-xl-9">
                                            <Select
                                                onChange={(e) =>
                                                    setUnitType(
                                                        e as typeof unitType
                                                    )
                                                }
                                                value={unitType}
                                                isDisabled={isLoading}
                                                options={[
                                                    {
                                                        value: "custom",
                                                        label: "заказная",
                                                    },
                                                    {
                                                        value: "warehouse",
                                                        label: "складская",
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 200px",
                                        gridGap: "10px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ fontFamily: "monospace" }}>
                                        <textarea
                                            value={lastMessage}
                                            style={{
                                                width: "100%",
                                                height: "300px",
                                                resize: "none",
                                            }}
                                        ></textarea>
                                    </div>
                                    <button
                                        className="btn btn-danger"
                                        disabled={
                                            !isEverythingFilled || isLoading
                                        }
                                        onClick={() => handleSubmit()}
                                    >
                                        Начать генерацию
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MassGenerationHisotry />
        </>
    )
}

export default MassGeneration
