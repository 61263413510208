import { combineReducers } from "redux"
import { isSearchResult } from "./isSearchResult/reducer"
import { selectStatus } from "./selected/reducer"

import { attributes } from "./attributes/reducer"
import { bufferTemp } from "./bufferTemp/reducer"
import { category } from "./category/reducer"
import { compareType } from "./compareType/reducer"
import { detailAdditionalReducer } from "./detailAdditional/reducer"
import { errors } from "./errors/reducer"
import { fields } from "./fields/reducer"
import { goods } from "./goods/reducer"
import { imports } from "./imports/reducer"
import { inlineData } from "./inlineData/reducer"
import { listing } from "./listing/reducer"
import { loaderShowed } from "./loader/reducer"
import { modalState } from "./modalState/reducer"
import { regionReducer } from "./region/reducer"
import { savedUserSettings } from "./saveData/reducer"
import { userReducer } from "./user/reducer"

export const allReducers: any = combineReducers({
    loaderShowed,
    errors,
    fields,
    attributes,
    category,
    goods,
    modalState,
    imports,
    listing,
    savedUserSettings,
    inlineData,
    bufferTemp,
    selectStatus,
    compareType,
    isSearchResult,
    user: userReducer,
    detailAdditional: detailAdditionalReducer,
    region: regionReducer,
})
