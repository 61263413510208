import { pageIDs } from "pageConfig"
import { useMemo } from "react"
import { usePageData } from "./usePageData"
import { useParams } from "react-router-dom"
import { Uuid } from "types"

export const useDetailData = () => {
    const { slug } = usePageData()
    const params = useParams() as { id?: Uuid }

    const pageTitle = useMemo(() => {
        return params?.id ? 'Редактирование' : 'Создание'
    }, [params?.id])

    const showNamingSelect = useMemo(
        () => [pageIDs.series].includes(slug) ? slug : undefined,
        [slug]
    )

    return {
        showNamingSelect,
        pageTitle,
        id: params.id,
    }
}
