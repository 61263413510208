import { useCallback, useEffect, useRef, useState } from "react"

export const useScrollFinish = ({
    onScrollFinish,
}: {
    onScrollFinish?: () => void
}) => {
    const [scrollFinished, setScrollFinished] = useState(false)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        if (!scrollFinished || !onScrollFinish) return
        setScrollFinished(false)
        onScrollFinish()
        setScrollFinished(false)
    }, [onScrollFinish, scrollFinished])

    const calclulateScroll = useCallback(
        ({
            containerHeight,
            itemsHeight,
            scrollTop,
        }: {
            containerHeight: number
            itemsHeight: number
            scrollTop: number
        }) => {
            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
                setScrollFinished(
                    containerHeight + scrollTop < itemsHeight ? false : true
                )
            }, 200)
        },
        [setScrollFinished]
    )

    return {
        calclulateScroll,
    }
}
