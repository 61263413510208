import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Required } from '../UI/Required';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

export const InputTextArea: React.FC<InputProps> = (props) => {
	const { propertyContainer, textError, group } = props;

	const state = useSelector((state: { [key: string]: any }) => state);
	const value = state.fields.currentValues[group]?.[propertyContainer.getName()] || '';

	const dispatch = useDispatch();

	const isInvalid = textError && "is-invalid";

	useEffect(() => {
		if (typeof value !== 'string') {
			dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: '' }, group))
		}
	}, [value])

	return (
		<div className="row mb-3">
			<label htmlFor={propertyContainer.getId()} className="col-xl-3 col-form-label">
				{propertyContainer.get('required') ? <Required /> : null}
				{propertyContainer.get('label')}
			</label>
			<div className="col-xl-9">
				<textarea
					className={`form-control ${isInvalid}`}
					id={propertyContainer.getId()}
					placeholder={propertyContainer.get('placeholder')}
					disabled={propertyContainer.get('readonly')}
					value={value}
					onChange={
						(event: React.ChangeEvent<HTMLTextAreaElement>) => {
							dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: event.target.value }, group))
						}}
				></textarea>
				{textError && <TextError text={textError} />}
			</div>
		</div>
	);
};
