import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

export const MultipleList: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;
  const state = useSelector((state: { [key: string]: any }) => state)

  const dispatch = useDispatch()
  const initialState = state.fields.currentValues[group][propertyContainer.getName()] || [''];
  
  const [elements, setElements] = useState(initialState)

  const isInvalid = textError && "is-invalid";

  const addValuesHandler = () => {
    setElements([...elements, ''])
  }

  const inputHandler = (index: number, event?: any) => {
    elements[index] = event.target.value;
    dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: [...elements] }))
  }

  const parsedValues = elements && elements.map((item: string, index: number) => {

    return (
      <div className="row" key={index}>
        <div className="col-xl-9 direct-breadcrumbs">
          <input
            className={`form-control ${isInvalid}`}
            type="text"
            id={`${index}`}
            value={item}
            name={`${propertyContainer.getName()}[${index}]`}
            placeholder={propertyContainer.get('placeholder')}
            disabled={propertyContainer.get('readonly')}
            onChange={event => { inputHandler(index, event) }}
          />
          {textError && <TextError text={textError} />}
        </div>
        <div className="col-xl-3">
          <button type="button" className="btn btn-outline-primary " data-bs-toggle="dropdown" >...</button>
          <ul className="dropdown-menu" >
            <li className="dropdown-item" onClick={() => {
              // ????? dispatch(DEL_CATEGORY(item.id))
            }}>Удалить</li>
          </ul>
        </div>
      </div>
    )
  })

  return (
    <div className="row mb-3">
      <label htmlFor="inputtest18" className="col-xl-3 col-form-label">{propertyContainer.get('label')}</label>
      <div className="col-xl-9">
        {parsedValues}
        <button onClick={addValuesHandler} type="button" className="btn btn-outline-primary">Еще</button>
      </div>
    </div>
  );
};
