import { SelectEntity } from "components/Selects/types"
import { PayloadAction } from "store/types"
import { RegionStateAction } from "./actions"

export type RegionState = {
    region: SelectEntity | null
}

export const regionState = {
    region: {
        label: "Беларусь",
        value: "BLR",
    },
} as {
    region: SelectEntity | null
}

export const regionReducer = (
    state: RegionState = regionState,
    action: PayloadAction<any>
) => {
    switch (action.type) {
        case RegionStateAction.SET_REGION:
            return {
                ...state,
                region: action.payload,
            }
        default:
            return state
    }
}
