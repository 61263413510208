/** compareType */
const initialState = {}

export const compareType = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SET_COMPARE_TYPE":
            return {
                ...state,
                ...action.payload,
            }

        case "RESET_COMPARE_TYPE":
            return {}

        default:
            return state
    }
}
