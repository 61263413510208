import api from "helpers/api"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { RootState } from "store/types"
import { Uuid } from "types"

type SaveFilterRequest = {
    modelId: Uuid
    payload: Array<{
        measureUnitId: Uuid
        attributeValueId: Uuid
    }>
}

const saveMeasureUnit = async (data: SaveFilterRequest) => {
    return api.post<SaveFilterRequest, any>(
        "v1/model/measure-unit/filter/save",
        {},
        data
    )
}

export const useAdditionalActions = () => {
    const location = useLocation()
    const { pageID } = location.state as { pageID?: string }
    const params = useParams() as { id?: Uuid }
    const detailAdditional = useSelector(
        (state: RootState) => state.detailAdditional
    )

    const onSave = useCallback(async (props?: Record<string, any>) => {
        switch (pageID) {
            case "model":
                if (!params.id) return;
                const units = detailAdditional.measureUnits.units
                if (!units.every((unit) => Object.values(unit).every(Boolean)))
                    return

                return saveMeasureUnit({
                    modelId: params.id ?? props?.modelId ?? '',
                    payload: units.map((el) => ({
                        attributeValueId: el.value!,
                        measureUnitId: el.id!,
                    })),
                })
            default:
                return
        }
    }, [pageID, detailAdditional, params])

    return {
        onSave,
    }
}
