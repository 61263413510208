import { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { useRegion } from "hooks/useRegion"
import { useState } from "react"
import {
    AsyncPaginate as AsyncSelect,
    AsyncPaginateProps,
} from "react-select-async-paginate"

type USelectProps<ResponseType> = {
    value: any
    handleOnChange: (v: any) => void
    regionDependent?: boolean
    optionUrl: string
    transformResponse?: (v: ResponseType) => DefaultOptionType[]
    externalOptions?: DefaultOptionType[]
    requestParams?: object
} & Omit<AsyncPaginateProps<any, any, any, any>, "loadOptions">

const USelect = (props: USelectProps<any>) => {
    const {
        value,
        handleOnChange,
        optionUrl,
        externalOptions = [],
        regionDependent,
        requestParams = {},
        ...rest
    } = props

    const [loading, setLoading] = useState(false)

    const { activeRegion } = useRegion()

    const loadedOptions = async (search: string, page: number) => {
        setLoading(true)
        try {
            const response = await api.getTyped<Record<string, string>>(
                optionUrl,
                {
                    query: search,
                    page: page,
                    count_on_page: 200,
                    ...requestParams,
                }
            )
            const options = props.transformResponse
                ? props.transformResponse(response)
                : Object.entries(response).map(
                      ([key, value]) =>
                          ({
                              label: value,
                              value: key,
                          } as DefaultOptionType)
                  )
            return {
                options: options,
                hasMore: options.length >= 200,
                additional: {
                    page: page + 1,
                },
            }
        } catch (err: any) {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1,
                },
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <AsyncSelect
            key={`${activeRegion?.value ?? "none"}`}
            styles={{
                valueContainer: (provided) => ({
                    ...provided,
                    height: "32px",
                    padding: "0 6px",
                }),
                input: (provided) => ({
                    ...provided,
                    height: "32px",
                    margin: 0,
                }),
                indicatorSeparator(base, props) {
                    return {
                        ...base,
                        height: "100%",
                        marginTop: 0,
                    }
                },
                indicatorsContainer: (base) => ({
                    ...base,
                    height: "100%",
                }),
                control: (provided) => ({
                    ...provided,
                    height: "32px",
                    minHeight: "32px",
                }),
                container: (base) => ({
                    ...base,
                    height: "32px",
                }),
            }}
            className="fw"
            debounceTimeout={500}
            placeholder="Выберите..."
            loadingMessage={({ inputValue }) => {
                return (
                    <span>
                        {inputValue ? `Поиск : "${inputValue}"` : "Загрузка..."}
                    </span>
                )
            }}
            value={value}
            loadOptions={(inputValue, _, options) => {
                console.log(options, _)
                return loadedOptions(inputValue, options?.page || 1)
            }}
            isLoading={loading}
            onChange={(value) => {
                handleOnChange(value)
            }}
            isSearchable
            pageSize={200}
            {...rest}
        />
    )
}

export default USelect
