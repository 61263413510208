import React, { useEffect, useState } from 'react';
import { Required } from '../UI/Required';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../helpers/api';
import TextError from '../UI/TextError';
import { DEL_KEYVALUE_FROM_CURRENT, SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

export const KeyValueWithSelect: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;
  const state = useSelector((state: { [key: string]: any }) => state);

  let values = (state.fields.currentValues[group]?.[propertyContainer.getName()]) || [];
  if (!values.length) values = [{ 'key': '', 'value': '' }];
  
  const [pricesNames, setPricesNames] = useState<[]>([]);

  const dispatch = useDispatch();

  const isInvalid = textError && "is-invalid";

  const reference = propertyContainer.get('reference');

  useEffect(() => {
    (function (reference: string) {
      if (!reference) return;
      api
        .get<any>(`reference/${reference}`)
        .then(response => {
          setPricesNames(response.data)
        })
        .catch(() => null);
    }(reference));

  }, [reference])

  const addValuesHandler = () => {
    let clonedState = JSON.parse(JSON.stringify(values));
    clonedState = [...clonedState, { 'key': '', 'value': '' }];
    dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: clonedState }));
  }

  const parsePricesNames = (id: number) =>
    pricesNames && pricesNames.map((item: { [key: string]: any }) =>
      <option value={item.name} selected={item.id === id} key={item.id} id={item.id}>{item.name}</option>);

  const parseValues = values && values.map((item: { key: number, value: number | string }, index: number) => {
    return (
      <div className="row" key={index}>
        <div className="col-5 direct-breadcrumbs">
          <select
            className={`form-select ${isInvalid}`}
            onChange={(event) => {
              values[index].key = +event.target.selectedOptions[0].id;
              let clonedState = JSON.parse(JSON.stringify(values));
              clonedState = [...clonedState];
              dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: clonedState }));
            }}
          >
            <option value={0}></option>
            {parsePricesNames(item.key)}
          </select>
          {textError && <TextError text={textError} />}
        </div>
        <div className="col-5 direct-breadcrumbs">
          <input
            type="number"
            className={`form-control ${isInvalid}`}
            onChange={(event) => {
              values[index].value = event.target.value;
              let clonedState = JSON.parse(JSON.stringify(values));
              clonedState = [...clonedState];
              dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: clonedState }));
            }}
            value={item.value}
          />
          {textError && <TextError text={textError} />}
        </div>
        <div className="col-2 direct-breadcrumbs position-relative">
          <div className="buttons-nowrap">
            <button type="button" className="btn btn-outline-primary " data-bs-toggle="dropdown" >...</button>
            <ul className="dropdown-menu" >
              <li className="dropdown-item"
                onClick={() => {
                  dispatch(DEL_KEYVALUE_FROM_CURRENT({ id: item.key, fieldName: propertyContainer.getName() }));
                }}>Удалить</li>
            </ul>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="row mb-3">
      <label htmlFor={propertyContainer.getId()} className="col-xl-3 col-form-label">
        {propertyContainer.get('required') ? <Required /> : null}
        {propertyContainer.get('label')}
      </label>
      <div className="col-xl-9">
        {parseValues}
        <button type="button" className="btn btn-outline-primary" onClick={addValuesHandler}>Еще</button>
      </div>
    </div>
  );
};
