import { SelectEntity } from "components/Selects/types"
import { useCallback, useState } from "react"

export const useEntitiesData = () => {
  const [group, setGroup] = useState<SelectEntity | null>(null)
  const [series, setSeries] = useState<SelectEntity | null>(null)
  const [model, setModel] = useState<SelectEntity | null>(null)
  const [producer, setProducer] = useState<SelectEntity | null>(null)
  const [resetValue, setResetValue] = useState<Symbol>(Symbol());

  const reset = useCallback(() => {
    setResetValue(Symbol())
  }, [setResetValue])

  return {
    group,
    series,
    model,
    producer,
    setGroup,
    setSeries,
    setModel,
    setProducer,
    resetValue,
    reset,
  }
}