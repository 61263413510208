import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    ExclamationCircleTwoTone,
    WarningTwoTone,
} from "@ant-design/icons"
import {
    Col,
    Collapse,
    Divider,
    Result,
    ResultProps,
    Row,
    Table,
    TableProps,
    Typography,
} from "antd"
import ResizeContainer from "rc-resize-observer"
import { PropsWithChildren, useMemo } from "react"
import { Uuid } from "types"
import { Model, Producer, SeriesEnity } from "types/api"

type DestinationItem = {
    code: Uuid
    name: string
}

type DestinationModelSet = {
    models: DestinationItem[]
}

type DestinationSeries = SeriesEnity

type DestinationModel = Model

type DestinationProducer = Producer

type MissingValue = {
    attribute: { code: Uuid; name: string }
    code: Uuid
    value: string
}

type CopiedItem = {
    attribute: { code: Uuid; name: string }
    code: Uuid
    value: string
}

type WarningReason = {
    missingValues: MissingValue[]
    reason: "MISSING_VALUES"
    subject: DestinationItem
}

enum DestinationType {
    Model = "MODEL",
    ModelSet = "MODEL_SET",
    Series = "SERIES",
    Producer = "PRODUCER",
}

enum CopyResult {
    Waring = "WARNING",
    Success = "SUCCESS",
    AlreadyDone = "ALREADY_DONE",
    Failed = "FAILED",
    PartialSuccess = "PARTIAL_SUCCESS",
}

export type CopyCompatibilitesResponse = {
    copiedCount: number
    destinationType: DestinationType
    destination:
        | DestinationModelSet
        | DestinationModel
        | DestinationSeries
        | DestinationProducer
    failReason: null
    innerResults: CopyCompatibilitesResponse[] | null
    result: CopyResult
    source: DestinationItem
    skippedCount: number
    warningReason: WarningReason | null
}

type DataProps = {
    data: CopyCompatibilitesResponse
}

const Title = ({ data }: DataProps) => {
    let targetType = ""
    let targetName = ""

    switch (data.destinationType) {
        case DestinationType.Producer:
            const producer = data.destination as Producer
            targetType = "в поставшика"
            targetName = producer.name
            break
        case DestinationType.Series:
            const series = data.destination as SeriesEnity
            targetType = "в серию"
            targetName = series.name
            break
        case DestinationType.Model:
            const model = data.destination as Model
            targetType = "в модель"
            targetName = model.name
            break
        case DestinationType.ModelSet:
            const { models } = data.destination as DestinationModelSet
            targetType = "в модели"
            targetName = models.map((el) => el.name).join(", ")
            break
    }

    const copyResultMessage = useMemo(() => {
        return {
            [CopyResult.Success]: (
                <Typography.Text className="text-align-start">
                    Сочетания значений модели <b>{data.source.name}</b>{" "}
                    скопированы {targetType} <b>{targetName}</b>
                </Typography.Text>
            ),
            [CopyResult.AlreadyDone]: (
                <Typography.Text className="text-align-start">
                    Сочетания значений модели <b>{data.source.name}</b> уже были
                    скопированы {targetType} <b>{targetName}</b>
                </Typography.Text>
            ),
            [CopyResult.Waring]: (
                <Typography.Text className="text-align-start">
                    Сочетания значений модели не полностью{" "}
                    <b>${data.source.name}</b> скопированы {targetType}{" "}
                    <b>{targetName}</b>
                </Typography.Text>
            ),
            [CopyResult.Failed]: (
                <Typography.Text className="text-align-start">
                    Невозможно скопировать сочетания значений модели{" "}
                    <b>${data.source.name}</b> {targetType} <b>{targetName}</b>
                </Typography.Text>
            ),
            [CopyResult.PartialSuccess]: (
                <Typography.Text className="text-align-start">
                    Частично скопированы сочетания значений модели{" "}
                    <b>${data.source.name}</b> {targetType} <b>{targetName}</b>
                </Typography.Text>
            ),
        }
    }, [data.result])

    return copyResultMessage[data.result]
}

const Icon = ({ data }: DataProps) => {
    switch (data.result) {
        case CopyResult.Success:
            return <CheckCircleTwoTone rev={undefined} />
        case CopyResult.Waring:
            return <ExclamationCircleTwoTone rev={undefined} />
        case CopyResult.Failed:
            return <CloseCircleTwoTone rev={undefined} />
        default:
            return <WarningTwoTone rev={undefined} />
    }
}

const DataTable = ({ data }: { data: CopiedItem[] }) => {
    const columns: TableProps<CopiedItem>["columns"] = [
        {
            title: "Название свойства",
            render: (record: CopiedItem) => (
                <Typography.Text>{record.attribute.name}</Typography.Text>
            ),
        },
        {
            title: "Значение",
            render: (record: CopiedItem) => (
                <Typography.Text>{record.value}</Typography.Text>
            ),
        },
    ]

    return <Table dataSource={data} columns={columns} size="small" />
}

const MissingValues = ({ data }: DataProps) => {
    if (!data.warningReason) return null

    return (
        <>
            <Divider>
                <Typography.Text type="warning">
                    Значения свойств, не хватающих для проведения полноценной
                    копии, которые были пропущены.
                </Typography.Text>
            </Divider>
            <DataTable data={data.warningReason.missingValues} />
        </>
    )
}

function InnerResult({
    data,
    children,
}: PropsWithChildren<{ data: CopyCompatibilitesResponse }>) {
    return (
        <Collapse
            items={[
                {
                    key: "inner-result-1",
                    label: (
                        <div className="attribute-copy-result-header">
                            <Icon data={data} />
                            <Title data={data} />
                        </div>
                    ),
                    children: children,
                },
            ]}
        />
    )
}

function Info({ data }: DataProps) {
    return (
        <>
            <Row
                align={"middle"}
                justify={"start"}
                className="m-bottom-md"
                gutter={[10, 0]}
            >
                <Col>
                    <Typography.Text className="text-align-start">
                        Количество скопированных записей -{" "}
                    </Typography.Text>
                    <Typography.Text strong>{data.copiedCount}</Typography.Text>
                </Col>
                <Col>
                    <Typography.Text className="text-align-start">
                        Количество пропущенных записей -{" "}
                    </Typography.Text>
                    <Typography.Text strong>
                        {data.skippedCount}
                    </Typography.Text>
                </Col>
            </Row>
            <Row className="fw">
                {data.innerResults && (
                    <Col span={24}>
                        {data.innerResults.map((el, idx) => (
                            <InnerResult
                                data={el}
                                key={`${data.source.code}-${idx}`}
                            >
                                <Info data={el} />
                            </InnerResult>
                        ))}
                    </Col>
                )}
            </Row>
            <MissingValues data={data} />
        </>
    )
}

const resultContent = {
    [CopyResult.Success]: {
        icon: "success",
        text: "Успех",
    },
    [CopyResult.Failed]: {
        icon: "error",
        text: "Ошибка",
    },
    [CopyResult.Waring]: {
        icon: "warning",
        text: "Скопировано с ошибками",
    },
} as Record<CopyResult, { icon: ResultProps["status"]; text: string }>

export default function AttributeCopyResult(props: {
    data: CopyCompatibilitesResponse
}) {
    const { data } = props
    const { result } = data

    return (
        <ResizeContainer>
            <Result
                className="copy-result-container"
                status={resultContent[result]?.icon}
                title={resultContent[result]?.text}
                extra={<Info data={data} />}
            />
        </ResizeContainer>
    )
}
