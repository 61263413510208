import { Col, Row } from "antd"
import { PropsWithChildren } from "react"

export const FormLine = ({
    children,
    label,
}: PropsWithChildren<{
    label?: string
}>) => {
    return (
        <Row className="fw" align={"middle"} gutter={[10, 0]}>
            <Col xs={24} md={5}>
                <span style={{ fontSize: 16 }}>{label ?? ""}</span>
            </Col>
            <Col xs={24} md={17}>
                {children}
            </Col>
        </Row>
    )
}
