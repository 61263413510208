import { useQuery } from "@tanstack/react-query"
import clsx from "clsx"
import React, { useCallback, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import api from "../../../helpers/api"
import LoadingOverlay from "react-loading-overlay-ts"
import {
    ActiveEntityType,
    EntityType,
    ModelAttribute,
    ModelAttributeLink,
} from "./types"
import { Uuid } from "types"

interface ItemsGroupRowsChooserProps {
    onChangeActive: (selected: ActiveEntityType) => void
    active?: ActiveEntityType
    loading?: boolean
    activeLink?: ModelAttribute
    groupId: Uuid
    producerId: Uuid
}

const useStyles = createUseStyles({
    valueCell: {
        width: "100%",
        border: "1px solid #dee2e6",
        borderTop: "none",
        borderBottom: "none",
    },
    headlineCell: {
        width: "100%",
    },
    searchCell: {
        width: "100%",
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        overflow: "scroll",
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    searchBoxInput: {
        width: "100%",
        outline: "none",
        border: "1px solid #dee2e6",
        borderBottom: "none",
        padding: "5px 10px",
    },
    activeRow: {
        boxShadow: "14px -1px 0px -11px rgb(173 173 173 / 75%) inset",
    },
    modelRow: {
        background: "#f7f7f7",
        cursor: "pointer",
        width: "100%",
        display: "flex",
        borderTop: "none",
    },
    seriesRow: {
        width: "100%",
        display: "flex",
        borderTop: "none",
        /* border-left: 1px solid #DEE2E7; */
    },
})

interface ModelsBySeriesRow {
    modelId: Uuid
    seriesId: Uuid
    seriesName: string
    modelName: string
}
export const ModelsChooser: React.FC<ItemsGroupRowsChooserProps> = (props) => {
    const classes = useStyles()
    const [search, setSearch] = useState("")
    const [openedSeries, setOpenedSeries] = useState('')

    const { data: modelsBySeries, isLoading } = useQuery<{
        [seriesId: string]: ModelsBySeriesRow[]
    }>({
        initialData: {},
        enabled: !!props.producerId && !!props.groupId,
        queryKey: ["models-by-series", props.producerId, props.groupId],
        queryFn: () =>
            api.getTyped("model/list-by-series", {
                producer: props.producerId,
                group: props.groupId,
            }),
    })

    const isMeActive = useCallback((entity: EntityType, id: Uuid) => {
        return props.active?.id === id && props.active?.entity === entity
    }, [props.active])

    const models = useMemo(() => {
        const items = Object.keys(modelsBySeries)
        return search
            ? items.filter((id) => {
                  const { seriesId, seriesName } = modelsBySeries[id][0]
                  return (
                      seriesName.toLowerCase().includes(search.toLowerCase()) ||
                      seriesId.toString().includes(search)
                  )
              })
            : items
    }, [search, modelsBySeries])

    return (
        <LoadingOverlay
            active={isLoading}
            styles={{
                overlay: {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    textAlign: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: "800",
                    transition: "opacity 500ms ease-in",
                    opacity: 1,
                    backdropFilter: "blur(15px) grayscale(1)",
                },
            }}
            spinner={
                <div
                    className="spinner-border text-primary"
                    role="status"
                ></div>
            }
        >
            <h5>Свойства и модели</h5>
            <div>
                <input
                    className={classes.searchBoxInput}
                    type="text"
                    value={search}
                    placeholder="Поиск"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table
                className="table table-bordered table-users"
                style={{
                    display: "block",
                    width: "100%",
                }}
            >
                <thead
                    style={{
                        width: "100%",
                        display: "inline-table",
                    }}
                >
                <tr>
                    <th className={classes.headlineCell}>Серия</th>
                </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {models.map((seriesId, idx) => {
                        const models = modelsBySeries[seriesId]
                        return (
                            <>
                                <tr
                                    key={'model-series' + idx}
                                    className={clsx(
                                        classes.seriesRow,
                                        isMeActive(
                                            EntityType.series,
                                            seriesId
                                        ) && classes.activeRow
                                    )}
                                    onClick={() => {
                                        setOpenedSeries(seriesId)
                                        props.onChangeActive({
                                            entity: EntityType.series,
                                            id: seriesId,
                                        })
                                    }}
                                >
                                    <td className={classes.valueCell}>
                                        {models[0].seriesName}
                                    </td>
                                </tr>
                                {models
                                    .filter(
                                        ({ modelId }) =>
                                            openedSeries === seriesId && modelId
                                    )
                                    .map((model, modelId) => {
                                        return (
                                        <tr
                                            key={'model' + idx + modelId}
                                            className={clsx(
                                                classes.modelRow,
                                                isMeActive(
                                                    EntityType.model,
                                                    model.modelId
                                                ) && classes.activeRow
                                            )}
                                            onClick={() =>
                                                props.onChangeActive({
                                                    entity: EntityType.model,
                                                    id: model.modelId,
                                                    parentId: seriesId,
                                                })
                                            }
                                        >
                                            <td className={classes.valueCell}>
                                                {model.modelName}
                                            </td>
                                        </tr>
                                    )})}
                            </>
                        )
                    })}
                </tbody>
            </table>
        </LoadingOverlay>
    )
}