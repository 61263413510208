import axios from "axios"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import { setLocale } from "yup"
import App from "./App"
import { allReducers } from "./store/reducers"
//@ts-ignore fixme
import * as Sentry from "@sentry/react"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage"
import ru from "yup-locale-ru"

setLocale(ru)

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, allReducers) as any

let reduxDevTools = compose
if (
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    process.env.NODE_ENV === "development"
) {
    reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__()
}

export const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk), reduxDevTools)
)

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN ?? "",
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

axios.interceptors.response.use(
    (response) => response,
    function (error) {
        if (
            error?.response?.status === 401 &&
            !window.location.href.includes("SESSION_EXPIRED")
        ) {
            localStorage.clear()

            const cookies = document.cookie.split(";")
            for (let i = 0; i < cookies.length; i++) {
                document.cookie =
                    cookies[i] + "=; expires=" + new Date(0).toUTCString()
            }

            window.location.href =
                "/?reason=SESSION_EXPIRED&redirect_to=" +
                window.location.pathname
            return
        }

        return Promise.reject(error)
    }
)

ReactDOM.render(
    <PersistGate loading={null} persistor={persistStore(store)}>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </PersistGate>,
    document.getElementById("root")
)
