import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import DoubleScrollbar from 'react-double-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { SET_SELECT_STATUS } from '../../../store/selected/actions'

export const Table = ({selectedForRemove, autoRemove}: any) => {
  const selectAll = useSelector((state: { [key: string]: any }) => state.selectStatus.status);

  const dispatch = useDispatch();

  const onSelectAll = () => {
    if (selectAll === false) {
      dispatch(SET_SELECT_STATUS(true))
    }
    if (selectAll === true) {
      dispatch(SET_SELECT_STATUS(false))
    }
  }


  return (
    <DoubleScrollbar>
      <table className="table table-bordered table-users">
        <tbody>
          <tr>
            <th scope="col" style={{width: '34px'}}><input type="checkbox" className="form-check-input" checked={selectAll} onChange={onSelectAll} /></th>
            <th scope="col" className="users-td-action"></th>
            <TableHeader />
          </tr>
          <TableRow selectedForRemove={selectedForRemove} autoRemove={autoRemove}/>
        </tbody>
      </table>
    </DoubleScrollbar>
  )
}
