import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"

const MATERIAL_ID = '949bb121-c4dc-4ec3-a994-af5f6984ce42'

const InputAttributeMaterial: React.FC<InputProps> = ({
    propertyContainer,
    group,
}) => {
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: MATERIAL_ID },
                group
            )
        )
    }, [])

    return null
}

export default InputAttributeMaterial

