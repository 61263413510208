import React from "react"
import { useDispatch } from "react-redux"
import api from "../../../helpers/api"
import { SET_CURRENT_VALUES } from "../../../store/fields/actions"
import { useNotifications } from "../../../hooks/useNotifications"
import { Uuid } from "types"
interface UploadFileProps {
    id: Uuid
    name: string
}

export const UploadFile: React.FC<UploadFileProps> = ({ id, name }) => {
    const dispatch = useDispatch()
    const { showNotification } = useNotifications()
    const checkMimeType = (mimeType: string) =>
        mimeType === "text/plain" ||
        mimeType === "text/csv" ||
        mimeType === "application/vnd.ms-excel" ||
        mimeType === "application/vnd.oasis.opendocument.spreadsheet" ||
        mimeType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    const sendFile = (event: any) => {
        const file = event.target.files[0]

        const fileLimit: number = 20971520 // 20Mb

        if (
            file.size >= fileLimit ||
            file.size === 0 ||
            !checkMimeType(file.type)
        ) {
            showNotification({
                type: "danger",
                message: "Неверный формат файла, или размер!",
            })

            return false
        }

        const formData: any = new FormData()
        formData.append("file", file)

        api.post("file/upload", {}, formData).then((response: any) => {
            dispatch(
                SET_CURRENT_VALUES({ fileId: response.data.id }, "listing")
            )
        })
    }

    return (
        <input
            className={`form-control`}
            name={name + id}
            type="file"
            onChange={(event: any) => sendFile(event)}
        />
    )
}
