import { PriceMarginPresetTableItem } from "components/tables/PriceMarginPreset.Table"
import { Uuid } from "types"

export type CalculationType = "basePrice" | "actualPrice"

type Value = {
    calculationType: CalculationType
    id: Uuid
    priceMarginPreset: PriceMarginPresetTableItem
}

export const PriceCalculationRulePriceMarginPresetLinkCell = ({
    value,
}: {
    value: Value
}) => {
    return <span>{value.priceMarginPreset.name}</span>
}
