import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Required } from '../UI/Required';
import TextError from '../UI/TextError';
import { uniqueEvents } from '../../helpers/uniqueEvents';
import { useLocation } from 'react-router';
import { RESET_ERROR } from '../../store/errors/actions';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

const InputText: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const value = useSelector((state: { [key: string]: any }) => state.fields.currentValues[group]?.[propertyContainer.getName()]) || '';

  const location: { [key: string]: any } = useLocation();
  const pageID: string = location.state.pageID || '';

  const dispatch = useDispatch();

  const isInvalid = textError && "is-invalid";
  const resetError = () => {
    if (textError && value.length > 0) dispatch(RESET_ERROR())
  };

  return (
    <div className="row mb-3">
      <label htmlFor={propertyContainer.getId()} className="col-xl-3 col-form-label">
        {propertyContainer.get('required') ? <Required /> : null}
        {propertyContainer.get('label')}
      </label>
      <div className="col-xl-9">
        <input
          className={`form-control ${isInvalid}`}
          type={`${propertyContainer.getName() === 'password' ? 'password' : 'text'}`}
          id={propertyContainer.getName()}
          value={value}
          placeholder={propertyContainer.get('placeholder')}
          disabled={propertyContainer.get('readonly')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(SET_CURRENT_VALUES(
              { [propertyContainer.getName()]: event.target.value }, group));
          }}
          onBlur={(event) => {
            dispatch(uniqueEvents(event, 'OnBlur', pageID));
            resetError();
          }}
          onFocus={() => textError && dispatch(RESET_ERROR())}
        />
        {textError && <TextError text={textError} />}
      </div>
    </div>
  );
};

export default InputText;
