import React from "react"
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom"
import { pageIDs } from "./pageConfig"

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>
    credentials?: string[]
    requiredPageIDs: string[]
    apiPath?: string
    pageIds: keyof typeof pageIDs
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    credentials = [],
    requiredPageIDs,
    apiPath,
    pageIds,
    ...rest
}) => {
    const history = useHistory()
    return (
        <Route
            {...rest}
            render={(props) => {
                const currentPath = props.location.pathname
                const requiredPageID = requiredPageIDs.find((pageID) =>
                    new RegExp(pageID.replace(/:\w+/, "\\w+")).test(currentPath)
                )

                if (requiredPageID && credentials.includes(requiredPageID)) {
                    const pageID = pageIDs[pageIds]
                    history.location.state = {
                        pageID: pageID,
                        ...(apiPath && { apiPath: `listing/${pageID}` }),
                    }
                    return <Component {...props} />
                } else {
                    return <Redirect to="/" />
                }
            }}
        />
    )
}

export default PrivateRoute
