import { SyncOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { ButtonProps } from "./type"

export default function RefetchButton({
    disabled,
    onClick,
    loading,
}: ButtonProps) {
    return (
        <Button
            disabled={disabled}
            loading={loading}
            icon={<SyncOutlined />}
            onClick={onClick}
        />
    )
}
