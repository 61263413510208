import { Divider, Typography } from "antd"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import USelect from "components/UI/USelect"
import { getFieldValue } from "helpers/getFieldValue"
import { useRegion } from "hooks/useRegion"
import { useEffect } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import {
    defaultPriceType,
    usePriceRecalculateState,
} from "./PriceRecalculate.state"

export default function PriceRecalculatePriceTypes() {
    const { priceTypes, setPriceTypes, onlyCostPrice } =
        usePriceRecalculateState()

    const { activeRegion } = useRegion()

    useEffect(() => {
        setPriceTypes([defaultPriceType as SelectEntity])
    }, [activeRegion])

    return (
        <>
            <Divider style={{ margin: 0 }} />
            <Flex.Col fullWidth align="start">
                <Typography.Title level={5}>Тип цены</Typography.Title>
                <USelect
                    optionUrl="listing/price-type"
                    handleOnChange={setPriceTypes}
                    value={priceTypes}
                    transformResponse={(response: ListingResponse) =>
                        response.entities.map((el) => {
                            return {
                                value: getFieldValue<Uuid>(el.fields, "id"),
                                label: getFieldValue<string>(el.fields, "name"),
                            }
                        })
                    }
                    isMulti={true}
                    regionDependent
                    externalOptions={[defaultPriceType]}
                />
            </Flex.Col>
        </>
    )
}
