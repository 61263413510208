import React, { useState } from "react"
import ReactPaginate from "react-paginate"
import { useQuery } from "@tanstack/react-query"
import api from "../../../helpers/api"
import useDebouce from "../../../hooks/useDebouce"
import murmurhash from "murmurhash"
import { ConfiguratorParams } from "./index"
import { Uuid } from "types"

export const ConfiguratorListing: React.FC<{
    filled: {
        [attributeId: Uuid]: string
    }
    params: ConfiguratorParams
}> = ({ filled, params }) => {
    const [page, setPage] = useState(1)
    const [debouncedFilledHash, setDebouncedFilledHash] = useState(0)

    const [paginationData, setPaginationData] = useState({
        pages: 1,
        rows: 20,
    })

    useDebouce(() => {
        setDebouncedFilledHash(murmurhash.v3(JSON.stringify(filled)))
    }, 500)

    const { data } = useQuery<{
        entities: {
            fields: {
                label: string
                value: string
                name: string
            }[]
            id: Uuid
        }[]
        pagination: {
            allPages: number
            totalCount: number
        }
    }>({
        queryKey: ["configurator-nomenclatures", page, debouncedFilledHash],
        queryFn: () =>
            api.getTyped("listing/nomenclature", {
                count_on_page: 20,
                sort: "id",
                method: "desc",
                page: page,
                similarity: Object.values(filled).length
                    ? Object.values(filled).join(",")
                    : undefined,
                model: params.model?.value,
            }),
        onSuccess: ({ pagination }) =>
            setPaginationData({
                pages: pagination?.allPages ?? 1,
                rows: pagination?.totalCount ?? 1,
            }),
    })

    if (!(data ?? {}).entities?.length) {
        return <>Схожая номенклатура отсутствует</>
    }

    return (
        <>
            <h4>Схожая номенклатура</h4>
            <table
                className="table table-bordered table-users"
                style={{
                    border: "1px solid #dee2e6",
                }}
            >
                <tbody>
                    <tr>
                        <th style={{ width: "70px" }}>ID</th>
                        <th>Название</th>
                        <th style={{ width: "180px" }}>Дата создания</th>
                    </tr>
                    {(data ?? {}).entities?.map((entity) => (
                        <tr key={entity.id}>
                            <td>{entity.id}</td>

                            <td>
                                {
                                    entity.fields.find((v) => v.name === "name")
                                        ?.value
                                }
                            </td>

                            <td>
                                {new Date(
                                    entity.fields.find(
                                        (v) => v.name === "createdAt"
                                    )?.value ?? "0"
                                ).toLocaleString("ru-RU")}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {paginationData.rows > 20 && (
                <div className="btn-toolbar justify-content-between users-pagination-toolbar mt-3">
                    <ReactPaginate
                        previousLabel={"Назад"}
                        nextLabel={"Вперед"}
                        pageCount={paginationData.pages}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        breakClassName="page-item"
                        breakLabel={<button className="page-link">...</button>}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        onPageChange={({ selected }) => setPage(selected + 1)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                    />

                    <div className="users-total">
                        Всего элементов: <span>{paginationData.rows}</span>
                    </div>
                </div>
            )}
        </>
    )
}
