import { Col, Row } from "antd"
import { AsyncSelect, Text } from "components/UI"
import { SelectEntity } from "./types"
import { Uuid } from "types"

interface ProducerSelectProps {
    activeGroupId?: Uuid
    activeProducer?: SelectEntity
    setActiveProducer: (v?: SelectEntity) => void
    required?: boolean
    layout?: 'vertical' | 'horizontal'
    skipValues?: Uuid[]
}

export function ProducerSelect({
    activeGroupId,
    activeProducer,
    setActiveProducer,
    required,
    layout = 'horizontal',
    skipValues
}: ProducerSelectProps) {

    const labelSpan = layout === 'horizontal' ? 5 : 24;
    const inputSpan = layout === 'horizontal' ? 19 : 24;

    return (
        <Row gutter={[0, 10]}>
            <Col xs={24} md={labelSpan}>
                <Text.FormLabel required={required}>Поставщик</Text.FormLabel>
            </Col>
            <Col xs={24} md={inputSpan}>
                <AsyncSelect
                    value={activeProducer}
                    onChange={(v) =>
                        setActiveProducer(v)
                    }
                    isClearable
                    dataUrl="producer"
                    params={{
                        itemsGroups: activeGroupId,
                    }}
                    isDisabled={!activeGroupId}
                    skipValues={skipValues}
                    placeholder="Выберите поставщика"
                />
            </Col>
        </Row>
    )
}
