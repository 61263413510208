import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_VALUES } from '../../../store/fields/actions';

export const InputSelect: React.FC<any> = ({ name, label }) => {
  const dispatch = useDispatch();

  const value = useSelector((state: { [key: string]: any }) => state.fields.currentValues['filter']?.[name]) || '';

  const optionHandler = (event: any) => {
      dispatch(SET_CURRENT_VALUES({ [name]: event.target.value }, 'filter'));
  };

  return (
    <div className="row mb-3 align-items-center">
      <label className="col-xl-2 col-form-label">{label}</label>
      <div className="col-xl-10">
        <div className="btn-group" role="group" onChange={(event) => optionHandler(event)}>
          <input 
            type="radio" 
            className="btn-check" 
            value="true" name={name} 
            id={`${name}-1`} 
            checked={value === "true" ? true : false}
          />
          <label className="btn btn-outline-primary" htmlFor={`${name}-1`}>
            Да
          </label>

          <input 
            type="radio"
            className="btn-check" 
            value="false" 
            name={name} 
            id={`${name}-2`} 
            checked={value === "false" ? true : false}
          />
          <label className="btn btn-outline-primary" htmlFor={`${name}-2`}>
            Нет
          </label>

          <input
            type="radio"
            className="btn-check"
            value=""
            name={name}
            id={`${name}-3`}
            checked={value.length > 0 ? false : true}
          />
          <label className="btn btn-outline-primary" htmlFor={`${name}-3`}>
            Не выбрано
          </label>
        </div>

        {/* <select className='form-select'
          value={value}
          onChange={(event => optionHandler(event))}
          name={name}
        >
          <option value="">Не выбрано</option>
          <option value="true">Да</option>
          <option value="false">Нет</option>

        </select> */}
      </div>
    </div>
  );
};
