import { useHistory } from "react-router-dom"

export const usePageData = () => {
    const history = useHistory()
    const { location } = history

    const slug = location.pathname.split("/").filter((el) => !!el)[0]

    return {
        slug,
    }
}
