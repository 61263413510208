import { useQuery } from "@tanstack/react-query"
import { InputNumber } from "antd"
import Select, { DefaultOptionType } from "antd/es/select"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { defaultSearchCondition } from "helpers/defaultSearchCondition"
import { getFieldsValues } from "helpers/getFieldValue"
import {
    PriceMarginPreset,
    useCalculationRulesStore,
} from "pages/CalculationRules/CalculationRules.store"
import { useCallback, useMemo } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import { TableRow } from "types/types"

export type PriceMarginPresetTableItem = {
    id: Uuid
    numericCode: number
    name: string
    marginType: string
    doCalculateFromBasePrice: boolean
    useAlternativeMargin: boolean
    value: number
    order: number
}

type PriceMarginPresetCreateItem = {
    id: Uuid
    order: number
    calculationType: (typeof calculationTypes)[keyof typeof calculationTypes]
}

export const calculationTypes = {
    basePrice: "basePrice",
    actualPrice: "actualPrice",
}

const doCalculateFromBasePriceOptions: DefaultOptionType[] = [
    { label: "Да", value: calculationTypes.basePrice },
    { label: "Нет", value: calculationTypes.actualPrice },
]

export const PriceMarginPresetTable = ({
    selectedRows,
    onRowClick,
    onChangeOrder,
    onChangeDoCalulateFromBasePrice,
    initialData,
    showAll,
}: {
    selectedRows?: TableRow[]
    initialData?: PriceMarginPreset[]
    onRowClick?: (row: PriceMarginPresetCreateItem) => void
    onChangeOrder: (rowId: Uuid, order: number | null) => void
    onChangeDoCalulateFromBasePrice: (rowId: Uuid, value: string) => void
    showAll: boolean
}) => {
    const { priceCalculationRulePriceMarginPresetLink } =
        useCalculationRulesStore()

    const { data, isFetching } = useQuery<PriceMarginPresetTableItem[]>({
        queryKey: ["price-margin-preset"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/price-margin-preset", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) =>
                    response.entities.map((el) => {
                        const data = {
                            ...getFieldsValues(
                                el.fields,
                                "id",
                                "numericCode",
                                "name",
                                "marginType",
                                "doCalculateFromBasePrice",
                                "useAlternativeMargin",
                                "value"
                            ),
                            order: 0,
                        }
                        return data
                    })
                ),
        initialData: [],
    })

    const tableData = useMemo(() => {
        if (showAll) return data
        return data.filter((el) =>
            initialData?.some((item) => item.id === el.id)
        )
    }, [data, initialData, showAll])

    const getValueById = useCallback(
        function <T>(id: string, key: "order" | "calculationType") {
            const record = priceCalculationRulePriceMarginPresetLink.find(
                (el) => el.id === id
            )
            if (!record) return
            return record[key] as T
        },
        [priceCalculationRulePriceMarginPresetLink]
    )

    return (
        <UTable
            isRowActive={(row) => {
                return !!selectedRows?.some((el) => el.id === row.id)
            }}
            onRowClick={(row) =>
                onRowClick?.({
                    id: row.id,
                    order: row.order,
                    calculationType: row.doCalculateFromBasePrice
                        ? calculationTypes.basePrice
                        : calculationTypes.actualPrice,
                })
            }
            search
            searchCondition={defaultSearchCondition}
            data={tableData}
            columns={[
                {
                    columnName: "Очередь",
                    render(row) {
                        return (
                            <InputNumber
                                value={
                                    getValueById<number>(row.id, "order") ||
                                    row.order ||
                                    undefined
                                }
                                controls={false}
                                bordered={false}
                                onChange={(v) => onChangeOrder(row.id, v)}
                            />
                        )
                    },
                    width: 10,
                },
                {
                    columnName: "Наценка",
                    render: (row) => row.name,
                },
                {
                    columnName: "Значение",
                    render: (row) => row.value,
                    width: 15,
                },
                {
                    columnName: "Расчёт от базовой цены",
                    render: (row) => (
                        <Flex.Row fullWidth>
                            <Select
                                onClick={(e) => e.stopPropagation()}
                                options={doCalculateFromBasePriceOptions}
                                value={
                                    getValueById(row.id, "calculationType") ||
                                    (row.doCalculateFromBasePrice
                                        ? calculationTypes.basePrice
                                        : calculationTypes.actualPrice)
                                }
                                onChange={(e) =>
                                    onChangeDoCalulateFromBasePrice(row.id, e)
                                }
                                size="small"
                                className="fw"
                            />
                        </Flex.Row>
                    ),
                    width: 25,
                },
            ]}
            loading={isFetching}
        />
    )
}
