import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Required } from '../UI/Required';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from '../../store/fields/actions';
import { InputProps } from '../../types/interfaces';
import { useLocation, useParams } from 'react-router-dom';

const InputCheckbox: React.FC<InputProps> = ({ propertyContainer, textError, group }) => {
  const location: any = useLocation();
  const routerParams: { [key: string]: string } = useParams();
  
  const { pageID } = location.state;
  const { id } = routerParams;

  const isChecked =
    useSelector((state: { [key: string]: any }) => state.fields.currentValues[group]?.[propertyContainer.getName()]) ||
    false;

  const dispatch = useDispatch();

  const isInvalid = textError && 'is-invalid';

  return (
    <div className="row mb-3 align-items-center">
      <label className="col-xl-3 col-form-label">
        {propertyContainer.get('required') ? <Required /> : null}
        {propertyContainer.get('label')}
      </label>
      <div className="col-xl-9">
        <input
          className={`form-check-input me-1 ${isInvalid}`}
          type="checkbox"
          disabled={propertyContainer.get('readonly') || (pageID === 'user' && id === '1')}
          checked={isChecked}
          onChange={() => {
            dispatch(SET_CURRENT_VALUES({ [propertyContainer.getName()]: !isChecked }, group));
          }}
        />
        {textError && <TextError text={textError} />}
      </div>
    </div>
  );
};

export default InputCheckbox;
