import api from "../../helpers/api"
import { Field } from "../../types/api"

/** FIELDS */
export const SET_FIELDS = (payload: Field[]) => ({
    type: "SET_FIELDS",
    payload,
})

/** CURRENT_VALUES */
export const SET_CURRENT_VALUES = (payload: any, group: string = "main") => ({
    type: "SET_CURRENT_VALUES",
    payload,
    group,
})

export const DEL_KEYVALUE_FROM_CURRENT = (payload: any) => ({
    type: "DEL_KEYVALUE_FROM_CURRENT",
    payload,
})

export const DELETE_FIELDS_VALUES = (payload: {
    fields: string[]
    group: string
}) => ({
    type: "DELETE_FIELDS_VALUES",
    payload,
})

export const DEL_FILE_FROM_CURRENT = (payload: any) => ({
    type: "DEL_FILE_FROM_CURRENT",
    payload,
})

export const RESET_CURRENT_VALUES = () => ({
    type: "RESET_CURRENT_VALUES",
})

export const RESET_GOODS_ATTRIBUTES = (group: string = "main") => ({
    type: "RESET_GOODS_ATTRIBUTES",
    group,
})

/** INIT_STATE_GROUP */
export const INIT_STATE_GROUP = (payload: any) => ({
    type: "INIT_STATE_GROUP",
    payload,
})

export const DELETE_FILTER_VALUE = (payload: { key: string }) => ({
    type: "DELETE_FILTER_VALUE",
    payload,
})

export const DELETE_FILTER_VALUES = (payload: { keys: string[] }) => ({
    type: "DELETE_FILTER_VALUES",
    payload,
})

/** THUNK */
export const getFieldsFromServer: any = (url: string) => {
    return (dispatch: any, getState: any) => {
        api.get<any>(url)
            .then((response) => {
                dispatch(SET_FIELDS(response.data.fields))
            })
            .catch((error) => {
                const state = getState()
                const defaultFilters =
                    state.listing?.entities?.[0]?.fields ?? []
                dispatch(SET_FIELDS(defaultFilters))
            })
    }
}

export const getCurrentValues: any = (
    url: string,
    routerParams: any,
    groups: [string] = [""]
) => {
    return async (dispatch: any) => {
        const resData2: any = routerParams
            ? await api.get(`${url}/${routerParams}`)
            : {}

        let query = "?"
        if (resData2.data?.type) {
            query += `type=${resData2.data.datattype}&`
        }

        if (resData2.data?.id) {
            query += `id=${resData2.data.id}&`
        }

        let resData: any = await api.get(`${url}/fields${query}`)
        let result: any = {}

        resData.data.fields.map((field: any) => {
            let value = field.default_value

            if (routerParams && resData2.data.hasOwnProperty(field.name)) {
                value = resData2.data[field.name]
            }
            result[field.name] = value
            return null
        })

        for (const group of groups) {
            if (result.hasOwnProperty(group)) {
                dispatch(SET_CURRENT_VALUES({ ...result[group] }, group))
            }
        }

        dispatch(SET_CURRENT_VALUES({ ...result }, "main"))
        dispatch(SET_FIELDS(resData.data.fields))

        if (routerParams && url === "import") {
            dispatch(
                SET_CURRENT_VALUES({ fileId: resData2.data.file.id }, "main")
            )
            dispatch(
                SET_CURRENT_VALUES(
                    { fieldsMatching: resData2.data.fieldsMatching },
                    "main"
                )
            )
            dispatch(
                SET_CURRENT_VALUES(
                    { columns: [...resData2.data.fieldsMatching] },
                    "import"
                )
            )
        }

        if (routerParams && url === "attribute") {
            dispatch(
                SET_CURRENT_VALUES(
                    { ...result, valuesDictionary: resData2.data.listValues },
                    "main"
                )
            )
        }

        if (!routerParams && url === "role") {
            const { data } = await api.get("roleList")
            dispatch(SET_CURRENT_VALUES({ data: data }, "main"))
        }
    }
}
