/** IS_SEARCH_RESULT */
const initialState: {
    isSearchResult: boolean
} = {
    isSearchResult: false,
}

export const isSearchResult = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_IS_SEARCH_RESULT":
            return {
                ...state,
                isSearchResult: action.payload,
            }

        default:
            return state
    }
}
