import React, { useMemo } from "react"
import { PicturesGeneratorParams } from "./index"
import { PicturesGeneratorPartAttributes } from "./PicturesGeneratorPartAttributes"
import { Uuid } from "types"

export const PicturesGeneratorContainersWrapper: React.FC<{
    params: PicturesGeneratorParams
    filledAttributes: {
        [attributeId: Uuid]: string
    }
    setFilledAttributes: (v: { [attributeId: Uuid]: string }) => void
}> = ({ params, filledAttributes, setFilledAttributes }) => {
    useMemo(() => setFilledAttributes({}), [params])

    const handleFillAttribute = (attributeId: Uuid, valueId?: Uuid) => {
        let curr = filledAttributes

        if (!valueId) {
            curr = Object.keys(curr).reduce((prev, currKey) => {
                if (currKey === attributeId.toString()) {
                    return prev
                }
                return {
                    ...prev,
                    [currKey]: curr[currKey],
                }
            }, {})
        } else {
            curr = {
                ...curr,
                [attributeId]: valueId,
            }
        }

        setFilledAttributes(curr)
    }
    return (
        <>
            <PicturesGeneratorPartAttributes
                target={params.model ? 'model' : 'series'}
                onFillAttribute={handleFillAttribute}
                filled={filledAttributes}
                params={params}
            />
        </>
    )
}
