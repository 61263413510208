import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { uniqueEvents } from "../../helpers/uniqueEvents"
import TextError from "../UI/TextError"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import Select from "react-select"

const options = [
  {
    label: 'Процент',
    value: 'percent'
  }
]

const MarginTypeSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const stateValue = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    ) as string

    const value = useMemo(() => {
      return options.find(el => el.value === stateValue);
    }, [stateValue])


    const location: { [key: string]: any } = useLocation()
    const pageID: string = location.state.pageID || ""
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            uniqueEvents(
                {
                    target: { value, id: propertyContainer.getName() },
                },
                "OnChange",
                pageID
            )
        )
    }, [value])
    
    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <Select
                        isDisabled={propertyContainer.get("readonly")}
                        isMulti={propertyContainer.get("multiple")}
                        name={propertyContainer.getName()}
                        id={propertyContainer.getName()}
                        aria-label="Default select"
                        value={value}
                        placeholder={propertyContainer.get('placeholder')}
                        options={options as any} 
                        onChange={(v) => {
                          const value = v as any
                          dispatch(
                              SET_CURRENT_VALUES(
                                  { [propertyContainer.getName()]: { id: value.value, name: value.label } },
                                  group
                              )
                          )
                        }}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default MarginTypeSelect
