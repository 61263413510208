import { FormItemProps } from "antd"
import { getFieldsValues } from "helpers/getFieldValue"
import { SelectOption } from "types"
import { ListingResponse } from "types/api"
import FormApiSelect from "./FormApiSelect"

export default function FormApiListingSelect(
    props: FormItemProps & {
        target: string
        onChange?: (v: SelectOption<any> | null) => void
        datakeys?: string[]
    }
) {
    return (
        <FormApiSelect
            getOptionUrl={`listing/${props.target}`}
            getOptionProps={{
                method: "asc",
                sort: "name",
                count_on_page: Number.MAX_SAFE_INTEGER,
            }}
            transformResponse={(response: ListingResponse) => {
                return response.entities.map((el) => ({
                    label: el.fields.find((field) => field.name === "name")!
                        .value,
                    value: el.fields.find((field) => field.name === "id")!
                        .value,
                    data: props.datakeys
                        ? getFieldsValues<any>(
                              el.fields,
                              ...(props.datakeys as any)
                          )
                        : {},
                }))
            }}
            {...props}
        />
    )
}
