import React from 'react';
import { TextErrorProps } from '../../types/interfaces';

const TextError: React.FC<TextErrorProps> = ({ text }) => {
  return (
    <div className="invalid-feedback" style={{display: 'block'}}>
      {text}
    </div>
  )
}

export default TextError;
