import { useQuery } from "@tanstack/react-query"
import { Modal } from "antd"
import { UTable } from "components/UTable"
import dayjs from "dayjs"
import api from "helpers/api"
import { formatNumber } from "helpers/getPriceString"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { useAttributeValuesPricesStore } from "./AttributeValuesPrices.store"
import { GetAttributeValuesPriceData } from "./AttributeValuesPricesForm"

export default function AttributeValuesPricesHistoryModal(props: {
    hide: () => void
    showModal: keyof GetAttributeValuesPriceData | null
}) {
    const { activeValues, activeModel } = useAttributeValuesPricesStore()
    const region = useSelector((state: RootState) => state.region.region)

    const { data } = useQuery({
        queryKey: [
            "attribute-combination-history",
            activeValues,
            activeModel,
            region,
            props.showModal,
        ],
        enabled: !!activeModel && activeValues.length > 1 && !!region,
        queryFn: () =>
            api
                .post<any, GetAttributeValuesPriceData[]>(
                    `v1/economics/attribute-combination/get-history`,
                    {},
                    {
                        model: activeModel?.value,
                        region: region?.value,
                        values: activeValues,
                    }
                )
                .then((response) => response.data),
        initialData: [],
    })

    const columns = useMemo(() => {
        const valueCol = {
            columnName: "Цена",
            render: (row: GetAttributeValuesPriceData) =>
                `${formatNumber(row.value ?? 0)} ${row.currency.isoCode}`,
            width: 50,
        }
        const marginCol = {
            columnName: "Процент наценки",
            render: (row: GetAttributeValuesPriceData) => row.marginValue,
            width: 50,
        }

        const firstCol = props.showModal === "value" ? valueCol : marginCol

        return [
            firstCol,
            {
                columnName: "Дата",
                render: (row: GetAttributeValuesPriceData) =>
                    dayjs(row.startAt).format("YYYY-MM-DD HH:mm"),
            },
        ]
    }, [props.showModal])

    return (
        <Modal
            title={`История изменения цен сочетания значений `}
            open={!!props.showModal}
            onCancel={props.hide}
            cancelText="Закрыть"
            okButtonProps={{ hidden: true }}
        >
            <UTable data={data} columns={columns} />
        </Modal>
    )
}
