import { useQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { EnititesSelect } from "components/Selects/newSelects/EntitiesSelect"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useAttributeValuesPricesStore } from "./AttributeValuesPrices.store"
import AttributeValuesPricesForm, {
    GetAttributeValuesListingItem,
} from "./AttributeValuesPricesForm"
import AttriubetValuesTables from "./AttributeValuesTables"
import AttributeValuesPricesListing from "./AttrubuteValuesPrices.Listing"

export default function AttributeValuesPrices() {
    const {
        setActiveModel,
        activeModel,
        setActiveSeries,
        applyed,
        setApplyed,
        setActiveGroup,
        setActiveProducer,
    } = useAttributeValuesPricesStore()

    const { data, isFetching, refetch, dataUpdatedAt } = useQuery<
        GetAttributeValuesListingItem[]
    >({
        queryKey: [
            "v1/economics/attribute-combination/get-model-combinations",
            activeModel,
        ],
        enabled: !!activeModel,
        queryFn: () =>
            api.getTyped(
                "v1/economics/attribute-combination/get-model-combinations/" +
                    activeModel!.value
            ),
        initialData: [],
    })

    return (
        <Flex.Col className="fw" align="start">
            <h3 className="mb-3">Цены на сочетания свойств/значений</h3>
            <div className="control-view fw">
                <div className="control-view__main">
                    <div className="control-in">
                        <EnititesSelect
                            onModelChange={setActiveModel}
                            onSeriesChange={setActiveSeries}
                            onGroupChange={setActiveGroup}
                            onProducerChange={setActiveProducer}
                            showReset={true}
                            onApply={() => {
                                setApplyed(true)
                            }}
                            onApplyIsActive={!!activeModel}
                        />
                    </div>

                    <Flex.Col
                        margin={[20, 0, 0]}
                        fullWidth
                        gap={10}
                        className={clsx({
                            "--visible": applyed,
                            "--hidden": !applyed,
                            "control-in": true,
                        })}
                    >
                        <AttributeValuesPricesListing
                            data={data}
                            loading={isFetching}
                            tableKey={dataUpdatedAt}
                        />
                    </Flex.Col>

                    <Flex.Col
                        margin={[20, 0, 0]}
                        fullWidth
                        gap={10}
                        className={clsx({
                            "--visible": applyed,
                            "--hidden": !applyed,
                            "control-in": true,
                        })}
                    >
                        <AttriubetValuesTables />
                    </Flex.Col>
                </div>
                <div className="control-view__info">
                    {applyed && (
                        <AttributeValuesPricesForm afterSave={refetch} />
                    )}
                </div>
            </div>
        </Flex.Col>
    )
}
