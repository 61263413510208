import { Field } from "types/api"

export const getFieldValue = <ValueType extends any>(
    fields: Field[],
    key: string
): ValueType | undefined => {
    const value = fields.find((el) => el.name === key)?.value as
        | ValueType
        | undefined
    if (Number.isInteger(value)) return value
    return value
}

export const getFieldsValues = <T>(
    fields: Field[],
    ...keys: Array<keyof T>
): T => {
    const obj = {} as any
    ;[...keys].forEach((key) => {
        const value = getFieldValue(fields, key as string)
        if (value) {
            obj[key] = value
        }
    })
    return obj as T
}
