import { SelectProps as AntdSelectProps } from "antd"
import { Uuid } from "types"
import { SelectEntity } from "../types"
import { CurrencySelect } from "./CurrencySelect"
import { RegionSelect } from "./RegionSelect"
import { RoundingSelect } from "./Rounding"

export const Select = {
    Currency: CurrencySelect,
    Region: RegionSelect,
    Rounding: RoundingSelect,
}

export { GroupSelect } from "./GroupSelect"
export { ModelSelect } from "./ModelSelect"
export { ProducerSelect } from "./ProducerSelect"
export { SeriesSelect } from "./SeriesSelect"

type SelectPropsType = "detail"

export const selectProps: Record<SelectPropsType, SelectProps<any>> = {
    detail: {
        inputSpan: 18,
        labelSpan: 6,
        size: "large",
        formLabelFontSize: 16,
        fullWidth: true,
    },
}

export type SelectProps<T> = {
    required?: boolean
    layout?: "vertical" | "horizontal"
    skipValues?: Uuid[]
    labelSpan?: number
    inputSpan?: number
    formLabelFontSize?: number
    fullWidth?: boolean
    containerClassName?: string
    getValueProps?: {
        url: string
        getParams: Record<string, any>
        getPutData: (value: SelectEntity) => Record<string, any>
        enabled?: boolean
    }
} & T &
    Partial<AntdSelectProps>
