import { useQuery } from "@tanstack/react-query"
import { Form, FormItemProps } from "antd"
import Select, { DefaultOptionType } from "antd/es/select"
import api from "helpers/api"
import { useMemo, useState } from "react"
import { SelectOption } from "types"
import { SelectEntity } from "../types"

export default function FormApiSelect<ResponseData>({
    getOptionUrl,
    transformResponse,
    getOptionProps,
    onChange,
    disabled,
    ...props
}: FormItemProps & {
    getOptionUrl: string
    getOptionProps?: object
    transformResponse: (v: any) => SelectOption<any>[]
    disabled?: boolean
    onChange?: (v: SelectEntity | null) => void
}) {
    const [search, setSearch] = useState("")

    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: [getOptionUrl, disabled],
        enabled: !disabled,
        queryFn: () =>
            api
                .getTyped<ResponseData>(getOptionUrl, getOptionProps)
                .then(transformResponse),
        initialData: [],
    })

    const withSearchData = useMemo(() => {
        if (!search) return data
        return data.filter((el) =>
            el.label?.toString().toLowerCase()?.includes(search.toLowerCase())
        )
    }, [search, data])

    return (
        <Form.Item {...props}>
            <Select
                disabled={disabled}
                showSearch
                onSearch={setSearch}
                filterOption={(v) =>
                    v.toLowerCase().includes(search.toLowerCase())
                }
                size="large"
                className="fw"
                options={withSearchData}
                loading={isFetching}
                allowClear
                onChange={(_, option) =>
                    onChange && onChange(option as SelectOption<any> | null)
                }
            />
        </Form.Item>
    )
}
