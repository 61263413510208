import { useMutation, useQuery } from "@tanstack/react-query"
import { Button, Card, TablePaginationConfig } from "antd"
import { EnititesSelect } from "components/Selects/newSelects/EntitiesSelect"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Uuid } from "types"
import type { Entity, ListingResponse } from "types/api"
import KitsItems from "./KitsItems"
import classes from "./KitsItems.module.sass"
import KitsList from "./KitsList"
import StandartKits from "./StandartKits"

const defautPagination = {
    pageSize: 20,
    allPages: 1,
    currentPage: 1,
    totalCount: 20,
}

export default function Kits() {
    const [editItemId, setEditItemId] = useState<Uuid | null>(null)
    const [resetValue, setResetValue] = useState<Symbol | null>(null)
    const [activeSeries, setActiveSeries] = useState<SelectEntity | null>(null)
    const [activeModel, setActiveModel] = useState<SelectEntity | null>(null)
    const [activeProducer, setActiveProducer] = useState<SelectEntity | null>(
        null
    )
    const [activeGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [showKits, setShowKits] = useState(false)
    const [fetchGenerationLog, setFetchGenerationLog] = useState(false)

    const [pagination, setPagination] =
        useState<ListingResponse["pagination"]>(defautPagination)
    const [tablePagination, setTablePagination] =
        useState<TablePaginationConfig>()

    const onChangePage = useCallback(
        (page: number, pageSize: number) => {
            setPagination((prev) => ({ ...prev, currentPage: page, pageSize }))
            setTablePagination((prev) => ({ ...prev, currentPage: page }))
        },
        [setPagination]
    )

    const _setTablePagination = (
        pagination?: ListingResponse["pagination"]
    ) => {
        setTablePagination(
            pagination
                ? {
                      total: pagination.totalCount,
                      pageSize: pagination.pageSize,
                      current: pagination.currentPage,
                      onChange: onChangePage,
                  }
                : defautPagination
        )
    }

    const url = useMemo(() => {
        let base = `listing/kits?&page=${pagination.currentPage}&count_on_page=${pagination.pageSize}`
        if (activeSeries) {
            base += `&filter=series--eq--${activeSeries?.value}`
        }
        if (activeModel) {
            base += `;model--eq--${activeModel.value}`
        }
        return base
    }, [activeModel, activeSeries])

    const { data, isLoading, refetch } = useQuery<Entity[]>({
        queryKey: [pagination, url, activeSeries, showKits],
        retryDelay: 1000,
        enabled: !!activeSeries && showKits,
        queryFn: () =>
            api.getTyped<ListingResponse | []>(url).then((data) => {
                if (Array.isArray(data)) {
                    return [] as []
                } else {
                    _setTablePagination(data?.pagination)
                    return data.entities
                }
            }),
        initialData: [],
    })

    const deleteItemMutation = useMutation(
        (v: Uuid) => api.delete(`v1/kits/delete/${v}`),
        {
            onSuccess: () => {
                refetch()
            },
        }
    )

    const generateStandertKits = () =>
        api.get(["v1/standard-kit/generate", editItemId].join("/")).then(() => {
            setFetchGenerationLog(true)
        })

    const deleteItem = useCallback(
        (itemId: Uuid) => {
            if (editItemId === itemId) {
                setEditItemId(null)
            }
            deleteItemMutation.mutate(itemId)
        },
        [deleteItemMutation, setEditItemId, editItemId]
    )

    const reset = useCallback(() => {
        setResetValue(Symbol())
        setShowKits(false)
        setEditItemId(null)
    }, [setResetValue, setShowKits])

    useEffect(() => {
        setEditItemId(null)
    }, [activeSeries])

    return (
        <>
            <h1 className="h3 mb-3">Комплекты</h1>
            <Flex.Col gap={20} align="start" styles={{ maxWidth: 1300 }}>
                <Card
                    style={{ width: "100%", maxWidth: 1300 }}
                    className={classes.kitsItemsRoot}
                >
                    <Flex.Col
                        gap={20}
                        fullWidth
                        styles={{ maxWidth: 1000 }}
                        align="start"
                    >
                        <EnititesSelect
                            containerStyles={{ maxWidth: 1500, width: "100%" }}
                            entities={["producer", "series", "model"]}
                            filters={{
                                itemsGroup: (value) =>
                                    [
                                        "Дверь межкомнатная",
                                        "Дверь входная",
                                    ].includes(value.label?.toString() ?? ""),
                            }}
                            onSeriesChange={setActiveSeries}
                            onModelChange={setActiveModel}
                            onProducerChange={setActiveProducer}
                            onGroupChange={setActiveGroup}
                            reset={resetValue}
                        />
                        <Flex.Row
                            gap={20}
                            styles={{
                                maxWidth: 1000,
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                type="primary"
                                disabled={!activeSeries}
                                onClick={() => setShowKits(true)}
                            >
                                Применить
                            </Button>
                            <Button
                                type="primary"
                                disabled={!showKits}
                                onClick={reset}
                            >
                                Сбросить
                            </Button>
                        </Flex.Row>
                    </Flex.Col>
                </Card>

                {!!activeSeries && showKits && (
                    <div className={classes.kitsItemsContainer}>
                        <KitsItems
                            currentSeries={activeSeries}
                            currentItemsGroup={activeGroup}
                            currentProducer={activeProducer}
                            currentModel={activeModel}
                            onCreateItem={refetch}
                            editItemId={editItemId}
                            cancelEdit={() => setEditItemId(null)}
                            generateStandertKits={generateStandertKits}
                        />

                        <KitsList
                            items={data}
                            isLoading={isLoading}
                            pagination={tablePagination}
                            editItemId={editItemId}
                            setEditItemId={setEditItemId}
                            deleteItem={deleteItem}
                        />
                    </div>
                )}
                <Card className="fw">
                    <StandartKits lastGeneretedKitId={fetchGenerationLog} />
                </Card>
            </Flex.Col>
        </>
    )
}
