import { Checkbox, Input, Row, Spin } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import React, { useMemo } from "react"
import { Uuid } from "types"

interface ItemsGroupRowsChooserRow {
    id: Uuid
    label: string
    alt?: string
    accounting?: string
}
interface ItemsGroupRowsChooserProps {
    rows: ItemsGroupRowsChooserRow[]
    onChangeAltName: (row: Record<Uuid, string>) => void
    onChangeAccountingName: (row: Record<Uuid, string>) => void
    loading?: boolean
    unpublishedValues: Uuid[]
    unpublishAttribute: (code: Uuid) => void
    unpublishLoading?: Uuid
    setUnpublishLoading: (value: Uuid) => void
}

type TableData = {
    published: boolean
} & ItemsGroupRowsChooserRow

function PublishCheckbox({
    record,
    onChangeRow,
    unpublishLoading,
}: {
    record: TableData
    onChangeRow: (v: Uuid) => void
    unpublishLoading?: Uuid
}) {
    const loading = unpublishLoading === record.id

    return (
        <Row align={"middle"} justify={"center"}>
            {loading && <Spin />}
            {!loading && (
                <Checkbox
                    checked={record.published}
                    onChange={() => onChangeRow(record.id)}
                />
            )}
        </Row>
    )
}

export const ProducerAttributesValuesChooser: React.FC<
    ItemsGroupRowsChooserProps
> = (props) => {
    const {
        unpublishedValues,
        unpublishAttribute,
        unpublishLoading,
        setUnpublishLoading,
    } = props

    const onChangeRow = (code: Uuid) => {
        setUnpublishLoading(code)
        return unpublishAttribute(code)
    }

    const filtredRows: TableData[] = useMemo(() => {
        const rows = props.rows || [];
        return rows
            .map((el) => {
                return {
                    ...el,
                    published: !unpublishedValues.includes(el.id),
                }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
    }, [props.rows, unpublishedValues])

    return (
        <Flex.Col fullWidth gap={10}>
            <UTable
                data={filtredRows}
                search
                searchCondition={(row, search) =>
                    row.label.toLowerCase().includes(search.toLowerCase())
                }
                columns={[
                    {
                        columnName: "ID",
                        width: 15,
                        render: (row) => row.id,
                    },
                    {
                        columnName: "Значение",
                        width: 25,
                        render: (row) => row.label,
                    },
                    {
                        columnName: "Альтернатива",
                        width: 25,
                        render: (item) => (
                            <Input
                                bordered={false}
                                key={item.alt}
                                defaultValue={item.alt}
                                onBlur={(e) =>
                                    props.onChangeAltName({
                                        [item.id]: e?.target.value,
                                    })
                                }
                            />
                        ),
                    },
                    {
                        columnName: "Бух. название",
                        width: 25,
                        render: (item) => (
                            <Input
                                bordered={false}
                                key={item.accounting}
                                defaultValue={item.accounting}
                                onBlur={(e) =>
                                    props.onChangeAccountingName({
                                        [item.id]: e?.target.value,
                                    })
                                }
                            />
                        ),
                    },
                    {
                        columnName: "Опубликовано",
                        render: (item) => (
                            <PublishCheckbox
                                record={item}
                                onChangeRow={onChangeRow}
                                unpublishLoading={unpublishLoading}
                            />
                        ),
                    },
                ]}
            />
        </Flex.Col>
    )
}
