import { Popconfirm } from "antd"
import React, { useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { Uuid } from "types"

interface ItemsGroupRowsChooserRow {
    id: Uuid
    label: string
}
interface ItemsGroupRowsChooserProps {
    rows: ItemsGroupRowsChooserRow[]
    selected: Uuid[]
    onBulk: (state: boolean) => void
    onChecked: (selected: Uuid) => void
    onUnChecked: (selected: Uuid) => void
    headline: string
    loading?: boolean
}

const useStyles = createUseStyles({
    checkboxCell: {
        width: 33,
    },
    valueCell: {
        width: "100%",
    },
    idCell: {
        minWidth: 80,
        textAlign: "center",
    },
    searchInput: {
        border: "none",
        outline: "none",
        marginLeft: 15,
        borderLeft: "1px solid #dee2e6",
        padding: 8,
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    tableClickableRow: {
        cursor: "pointer",
        "&:first-child": {
            borderTop: "none",
        },
        "&:hover": {
            background: "#eee",
        },
    },
})

export const ItemsGroupRowsChooser: React.FC<ItemsGroupRowsChooserProps> = (
    props
) => {
    const classes = useStyles()
    const [search, setSearch] = useState("")
    const [confirmationOpenedAtId, setConfirmationOpenedAtId] = useState("")
    const isAllSelected = useMemo(
        () =>
            !props.rows
                .map(({ id }) => id)
                .filter((rowId) => !props.selected.includes(rowId)).length,
        [props.rows, props.selected]
    )

    const handleToggle = ({ id }: ItemsGroupRowsChooserRow) => {
        const isChecked = props.selected.some((v) => v === id)
        isChecked ? props.onUnChecked(id) : props.onChecked(id)
    }

    const handleOpenConfirmation = (
        newOpen: boolean,
        row: ItemsGroupRowsChooserRow
    ) => {
        if (!newOpen) {
            return setConfirmationOpenedAtId("")
        }

        if (props.selected.some((v) => v === row.id)) {
            setConfirmationOpenedAtId(row.id)
            return
        }

        return handleToggle(row)
    }

    const filteredRows = useMemo(() => {
        return search
            ? props.rows.filter(({ id, label }) => {
                  return (
                      label.toLowerCase().includes(search.toLowerCase()) ||
                      id.toString().includes(search)
                  )
              })
            : props.rows
    }, [search, props.rows])

    return (
        <table
            className="table table-bordered table-users"
            style={{
                display: "block",
                width: "100%",
            }}
        >
            <thead>
                <tr>
                    <th className={classes.checkboxCell}>
                        <input
                            className="form-check-input"
                            onChange={(e) => props.onBulk(e.target.checked)}
                            type="checkbox"
                            checked={isAllSelected}
                        />
                    </th>
                    <th className={classes.idCell}>ID</th>
                    <th className="items-group__search-cell">
                        <div>
                            <span className={classes.searchLabel}>
                                {props.headline}
                            </span>
                            <input
                                type="text"
                                placeholder="Поиск"
                                className="items-group__search-input"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className={classes.tableBody}>
                {filteredRows.map((row) => (
                    <tr key={row.id} className={classes.tableClickableRow}>
                        <td className={classes.checkboxCell}>
                            <Popconfirm
                                title="Сброс доступности"
                                description={
                                    <span>
                                        Вы уверены, что хотите сбросить
                                        доступность свойства <br />
                                        к текущей номенклатуре? Это сделает
                                        свойство недоступным
                                        <br />
                                        на всех уровнях иерархии.
                                    </span>
                                }
                                onConfirm={() => {
                                    setConfirmationOpenedAtId("")
                                    handleToggle(row)
                                }}
                                okText="Да"
                                cancelText="Отмена"
                                open={confirmationOpenedAtId === row.id}
                                onOpenChange={(newOpen) =>
                                    handleOpenConfirmation(newOpen, row)
                                }
                            >
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={props.selected?.some(
                                        (selectedRowID) =>
                                            selectedRowID === row.id
                                    )}
                                    readOnly
                                />
                            </Popconfirm>
                        </td>
                        <td className={classes.idCell}>{row.id}</td>
                        <td className={classes.valueCell}>{row.label}</td>
                    </tr>
                ))}
            </tbody>

            {!filteredRows.length && (
                <p className={classes.searchNotFound}>Тут пусто</p>
            )}
        </table>
    )
}
