import { Typography } from "antd"
import FieldSelector from "components/QueryBuilder/FieldSelector"
import QueryFormField from "components/QueryBuilder/QueryFormFIeld"
import { Flex } from "components/UI/Flex"
import withQueryBuilder from "hocs/withQueryBuilder"
import NomenclatureFiltersValueEditor from "pages/Economic/NomeclatureFilters/NomenclatureFiltersValueEditor"
import { usePriceRecalculateState } from "./PriceRecalculate.state"
import PriceRecalculatePriceTypes from "./PricesRecalculate.PriceTypes"

function PriceRecalculateQueryBuilder() {
    const { query, setQuery } = usePriceRecalculateState()

    return (
        <Flex.Col fullWidth gap={20} justify="start" align="start">
            <Typography.Title level={5}>Фильтр номенклатуры</Typography.Title>
            <QueryFormField
                query={query}
                getEntitiesURL="v1/query-builder/price-nomenclature-filter/entities-list"
                controlElements={{
                    valueEditor: NomenclatureFiltersValueEditor,
                    fieldSelector: FieldSelector,
                }}
                onQueryChange={(q) => {
                    setQuery({
                        ...q,
                        rules: q?.rules ?? [],
                    })
                }}
                translations={{
                    addGroup: { label: "Добавить группу" },
                    addRule: { label: "Добавить правило" },
                }}
            />

            <PriceRecalculatePriceTypes />
        </Flex.Col>
    )
}

export default withQueryBuilder(PriceRecalculateQueryBuilder)
