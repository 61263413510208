// import api from "../../helpers/api";

/** SET_INLINE_DATA */
export const SET_INLINE_DATA = (payload: any) => ({
    type: "SET_INLINE_DATA",
    payload,
})

/** THUNK */
// export const getUserSettings: any = (route: string) => {
//   return async (dispatch: any, getState: any) => {

//     const currentState = getState();
//     const headers = currentState?.listing.headers;

//     api.get(route)
//       .then((result) => {

//         if (result.data.showedColumns.length === 0) {
//           dispatch(SET_INLINE_DATA(result.data));
//           dispatch(SET_INLINE_DATA({ showedColumns: [...headers] }));
//         } else {
//           dispatch(SET_INLINE_DATA(result.data));
//         }

//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// };
