import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"

const GLASSING_ID = '0e23798a-a20f-42fa-b898-d4916f6b46c4'

const InputAttributeGlassing: React.FC<InputProps> = ({
    propertyContainer,
    group,
}) => {
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: GLASSING_ID },
                group
            )
        )
    }, [])

    return null
}

export default InputAttributeGlassing

