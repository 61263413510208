import { DebounceInput } from "components/UI"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import React from "react"
import { Uuid } from "types"

type ItemsGroupRowsChooserRow = {
    id: Uuid
    label: string
    sort: number
}
interface ItemsGroupRowsChooserProps {
    rows: ItemsGroupRowsChooserRow[]
    onChangeSort: (id: Uuid, newValue: Uuid) => void
    onChangeActiveRow: (id: Uuid) => void
    activeRow?: Uuid
    loading?: boolean
}

export const ProducerAttributesChooser: React.FC<ItemsGroupRowsChooserProps> = (
    props
) => {
    return (
        <Flex.Col fullWidth gap={10}>
            <UTable
                data={props.rows}
                disableListVirtualization
                search
                searchCondition={(row, search) =>
                    row.label.toLowerCase().includes(search.toLowerCase())
                }
                isRowActive={(row) => props.activeRow === row.id}
                onRowClick={(row) => props.onChangeActiveRow(row.id)}
                columns={[
                    {
                        columnName: "ID",
                        render: (row) => row.id,
                        width: 10,
                    },
                    {
                        columnName: "Свойство",
                        render: (row) => row.label,
                    },
                    {
                        columnName: "Порядок",
                        render: (row) => (
                            <DebounceInput
                                initValue={row.sort ? `${row?.sort}` : ""}
                                resolve={(v) => props.onChangeSort(row.id, v)}
                                inputProps={{
                                    bordered: false,
                                    type: "numeric",
                                }}
                            />
                        ),
                        width: 30,
                    },
                ]}
            />
        </Flex.Col>
    )
}
