import { Button } from "antd"
import { RegionSelect } from "components/Selects/newSelects/RegionSelect"
import { SelectEntity } from "components/Selects/types"
import Grid from "components/UI/Grid"
import { useCallback, useState } from "react"
import EntityCopyRegionModal from "./EnityCopyRegion.Modal"

export default function EntityCopyRegion({
    activeRegionId,
    entity,
    itemsGroup,
    refetch,
}: {
    activeRegionId?: string
    entity: "series" | "producer"
    itemsGroup: SelectEntity | null
    refetch: () => void
}) {
    const [regionForCopyFrom, setRegionFroCopyFrom] =
        useState<SelectEntity | null>(null)
    const [showModal, setShowModal] = useState(false)

    const hide = useCallback(() => {
        setShowModal(false)
        refetch()
    }, [setShowModal, refetch])

    if (!activeRegionId || !itemsGroup) return null
    return (
        <>
            <Grid fullWidth justify="end" gap={10} cols="1fr 400px">
                <RegionSelect
                    key={activeRegionId}
                    setActiveRegion={setRegionFroCopyFrom}
                    activeRegion={regionForCopyFrom}
                    skipValues={[activeRegionId]}
                />
                <Button
                    disabled={!regionForCopyFrom}
                    onClick={() => setShowModal(true)}
                >
                    Скопировать очередность с другого регоина
                </Button>
            </Grid>
            <EntityCopyRegionModal
                chosenRegion={regionForCopyFrom}
                entity={entity}
                hide={hide}
                visible={showModal}
                itemsGroup={itemsGroup!}
            />
        </>
    )
}
