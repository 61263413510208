import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Required } from "../UI/Required";
import TextError from "../UI/TextError";
import { RESET_ERROR } from "../../store/errors/actions";
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

const InputNumber: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const value =
    useSelector((state: { [key: string]: any }) => {
      return state.fields.currentValues[group]?.[propertyContainer.getName()];
    }) || "";

  const dispatch = useDispatch();

  const isInvalid = textError && "is-invalid";

  return (
    <div className="row mb-3">
      <label
        htmlFor={propertyContainer.getId()}
        className="col-xl-3 col-form-label"
      >
        {propertyContainer.get("required") ? <Required /> : null}
        {propertyContainer.get("label")}
      </label>
      <div className="col-xl-9">
        <div className="d-flex align-items-center">
          <input
            className={`form-control ${isInvalid}`}
            type="number"
            id={propertyContainer.getId()}
            value={value}
            placeholder={propertyContainer.get("placeholder")}
            disabled={propertyContainer.get("readonly")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                SET_CURRENT_VALUES(
                  { [propertyContainer.getName()]: +event.target.value },
                  group
                )
              );
            }}
            onFocus={() => textError && dispatch(RESET_ERROR())}
          />
          {group === "attributeValues" ? (
            <div className="meassure ms-3 me-2">
              {propertyContainer.get("measure")}
            </div>
          ) : null}
        </div>
        {textError && <TextError text={textError} />}
      </div>
    </div>
  );
};

export default InputNumber;
