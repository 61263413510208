import { SearchOutlined } from "@ant-design/icons"
import { Input, InputProps, InputRef } from "antd"
import { useEffect, useRef, useState } from "react"

interface DebounceInputProps {
    initValue: string
    delay?: number
    resolve: (value: string) => void
    inputProps?: InputProps
    style?: React.CSSProperties
    isSearch?: boolean
}

export default function DebounceInput({
    initValue,
    delay = 500,
    resolve,
    inputProps,
    style,
    isSearch,
}: DebounceInputProps) {
    const inputRef = useRef<InputRef>(null)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()

    const [value, setVaLue] = useState(initValue)

    const handleChange: InputProps["onChange"] = (e) => {
        clearTimeout(timerRef.current)
        setVaLue(e.target.value)
        timerRef.current = setTimeout(() => {
            const value = inputRef.current?.input?.value
            if (typeof value !== "undefined") {
                resolve(value)
            }
        }, delay)
    }

    useEffect(() => {
        setVaLue(initValue)
    }, [initValue])

    return (
        <Input
            prefix={isSearch ? <SearchOutlined /> : undefined}
            placeholder={isSearch ? "Поиск" : undefined}
            style={style}
            value={value}
            ref={inputRef}
            onChange={handleChange}
            allowClear
            {...inputProps}
        />
    )
}
