import { PushpinFilled } from "@ant-design/icons"
import { useMutation } from "@tanstack/react-query"
import { Button, List, notification, Popover } from "antd"
import api from "helpers/api"
import { useCallback } from "react"
import { Uuid } from "types"
import { Model } from "types/api"

type Props = {
    measureUnitId: Uuid | undefined
    modelId: Uuid | undefined
}

const SuccessUpdateDataTable = ({ data }: { data: Model[] }) => (
    <List
        style={{ width: "100%" }}
        dataSource={data}
        renderItem={(item) => <List.Item>{item.name}</List.Item>}
    />
)

export default function InputMeasureSelectMassUpdate({
    measureUnitId,
    modelId,
}: Props) {
    const [notificationApi, contextHolder] = notification.useNotification()

    const openSuccessNotification = useCallback((data: Model[]) => {
        notificationApi.success({
            message: "Единицы измерения обновлены у моделей",
            type: "success",
            description: <SuccessUpdateDataTable data={data} />,
            duration: 0,
        })
    }, [])

    const {
        mutate,
        isLoading: mutateLoading,
        data,
    } = useMutation<Model[], any>({
        mutationKey: [measureUnitId, modelId],
        mutationFn: () =>
            api
                .post<Props, Model[]>(
                    `v1/model/mass-update-measure-unit`,
                    {},
                    {
                        measureUnitId,
                        modelId,
                    }
                )
                .then((response) => response.data),
        onSuccess: (data) => {
            openSuccessNotification(data)
        },
    })

    if (!modelId) return null

    return (
        <>
            {contextHolder}
            <Popover title="Установить на всю серию">
                <Button
                    icon={<PushpinFilled />}
                    type="primary"
                    onClick={() => mutate()}
                    style={{
                        height: "100%",
                        padding: "0px 20px",
                    }}
                    loading={mutateLoading}
                    className="flex align-center justify-center"
                    disabled={!measureUnitId}
                />
            </Popover>
        </>
    )
}
