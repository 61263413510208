import murmurhash from "murmurhash"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink, useLocation } from "react-router-dom"
import { RootState } from "store/types"
import { SAVE_FOR_TEMP } from "../../store/bufferTemp/actions"
import { RESET_COMPARE_TYPE } from "../../store/compareType/actions"
import { RESET_ERROR } from "../../store/errors/actions"
import { RESET_CURRENT_VALUES } from "../../store/fields/actions"
import { SET_IS_SEARCH_RESULT } from "../../store/isSearchResult/actions"
import { CLEAR_LISTING } from "../../store/listing/actions"
import { getPermission } from "./permissions"
import { menuData, MenuItemContainerData, MenuItemData } from "./settings"

interface MenuProps {
    credentials: string[]
}

interface MenuItemContainerProps {
    data: MenuItemContainerData
    credentials: string[]
}
type MenuItemProps = {
    data: MenuItemData
    credentials: string[]
}

const MenuItem = ({ data }: MenuItemProps) => {
    const { slug, title, permission } = data

    const dispatch = useDispatch()
    const pathName = useLocation().pathname
    const user = useSelector((state: RootState) => state.user.user)
    const credentials = user?.credentials ?? []

    const resetHandler = useCallback(() => {
        dispatch(RESET_ERROR())
        dispatch(CLEAR_LISTING())
        dispatch(RESET_CURRENT_VALUES())
        dispatch(RESET_COMPARE_TYPE({}))
        dispatch(SAVE_FOR_TEMP({ filter: "", attributes: "", q: "" }))
        dispatch(SET_IS_SEARCH_RESULT(false))
    }, [])

    const itemPathName = "/" + slug

    return !permission ||
        credentials.includes(slug) ? (
        <li>
            <NavLink
                onClick={
                    pathName !== itemPathName
                        ? () => {
                              resetHandler()
                          }
                        : () => {}
                }
                activeClassName="btn-primary"
                to={itemPathName}
                isActive={(_, location) => {
                    return (
                        location.pathname === itemPathName
                        //||  location.pathname.indexOf(itemPathName) === 0
                    )
                }}
                className="link-white rounded"
            >
                {title}
            </NavLink>
        </li>
    ) : null
}

const MenuItemContainer = ({ data }: MenuItemContainerProps) => {
    const { items, title } = data
    const collapseId = useMemo(
        () => "collapse_" + murmurhash.v3(title),
        [title]
    )

    const user = useSelector((state: RootState) => state.user.user)
    const credentials = user?.credentials ?? []

    const permissions = items.map((el) => el.permission)

    const show = permissions.some(
        (el) => !el || credentials.includes(el)
    )
    return (
        <li className="mb-2">
            {show ? (
                <>
                    <button
                        className="btn btn-toggle align-items-center rounded collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${collapseId}`}
                        aria-expanded="false"
                    >
                        {title}
                    </button>
                    <div className="collapse" id={collapseId}>
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            {items.map((el, idx) => (
                                <MenuItem
                                    key={idx}
                                    data={el}
                                    credentials={credentials}
                                />
                            ))}
                        </ul>
                    </div>
                </>
            ) : null}
        </li>
    )
}

const Menu: React.FC<MenuProps> = ({ credentials }) => {
    return (
        <div
            key={"menu-" + credentials.length}
            className="flex-shrink-0 p-3 sidebar"
        >
            <Link
                to="/"
                className="d-flex align-items-center pb-3 mb-3 link-white text-decoration-none border-bottom"
            >
                <span className="fs-6 fw-semibold sidebar-txt">УТК</span>
            </Link>
            <ul className="list-unstyled ps-0">
                {menuData.map((el, idx) => {
                    return (
                        <MenuItemContainer
                            data={el}
                            key={idx}
                            credentials={credentials}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default Menu
