import { SelectEntity } from "components/Selects/types"
import { AsyncSelect } from "components/UI"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { IdNameItem } from "types"
import {
    DELETE_FIELDS_VALUES,
    SET_CURRENT_VALUES,
} from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const transformToIdName = (value: SelectEntity | null): IdNameItem | null =>
    value ? { id: value.value, name: value.label } : value
const transformToSelectEntity = (
    value: IdNameItem | null
): SelectEntity | null =>
    value ? { value: value.id, label: value.name } : value

const InputProducerSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const dispatch = useDispatch()

    const optionHandler = (value: SelectEntity | null) => {
        dispatch(
            SET_CURRENT_VALUES(
                { [propertyContainer.getName()]: transformToIdName(value) },
                group
            )
        )
        dispatch(
            DELETE_FIELDS_VALUES({
                fields: ["series", "model"],
                group,
            })
        )
    }

    const groupObject =
        useSelector((state: any) => {
            return state.fields.currentValues[group]
        }) ?? {}

    const groupDependent = Object.keys(groupObject).includes("itemsGroup")

    const activeGroup = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.itemsGroup
    )

    const activeGroupId = activeGroup?.id ?? activeGroup?.code ?? activeGroup

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <AsyncSelect
                        value={transformToSelectEntity(value)}
                        onChange={optionHandler}
                        name={propertyContainer.getName()}
                        aria-label="Default select"
                        id={propertyContainer.getName()}
                        isClearable
                        dataUrl="producer"
                        isDisabled={groupDependent && !activeGroupId}
                        params={{
                            itemsGroups: groupDependent
                                ? activeGroupId
                                : undefined,
                        }}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputProducerSelect
