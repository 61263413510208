import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Uuid } from "types"
import {
    DELETE_FIELDS_VALUES,
    SET_CURRENT_VALUES,
} from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { AsyncSelect } from "../UI"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

type ItemsGroupItem = {
    label: string
    value: Uuid
}

type IncomingGroupItem = {
    id: Uuid
    name: string
}
const InputItemsGroupMultiSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    ) as Array<IncomingGroupItem | ItemsGroupItem> | IncomingGroupItem

    const dispatch = useDispatch()

    const optionHandler = (value: Array<{ label: string; value: Uuid }>) => {
        dispatch(
            SET_CURRENT_VALUES(
                {
                    [propertyContainer.getName()]: value,
                },
                group
            )
        )
        dispatch(
            DELETE_FIELDS_VALUES({
                fields: ["producer", "series", "model"],
                group,
            })
        )
    }

    const parsedValue = useMemo(() => {
        const array = Array.isArray(value) ? value : [value]
        return array.map((el) => {
            const incomingGroupItem = el as IncomingGroupItem
            if (incomingGroupItem.id || incomingGroupItem.name) {
                const item: ItemsGroupItem = {
                    value: incomingGroupItem.id,
                    label: incomingGroupItem.name,
                }
                return item
            }
            return el
        })
    }, [value])

    useEffect(() => {
        const array = Array.isArray(value) ? value : [value]
        if (array.some((el) => (el as IncomingGroupItem).id)) {
            dispatch(
                SET_CURRENT_VALUES(
                    {
                        [propertyContainer.getName()]: parsedValue,
                    },
                    group
                )
            )
        }
    }, [parsedValue, value])

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    Номенклатурные группы
                </label>
                <div className="col-xl-9">
                    <AsyncSelect
                        placeholder={"Номенклатурные группы"}
                        dataUrl="items-group"
                        noOptionsMessage={() => <>{"Не найдено"}</>}
                        loadingMessage={() => <>{"Загрузка..."}</>}
                        defaultOptions={[]}
                        getOptionValue={(v: ItemsGroupItem) => v.value}
                        getOptionLabel={(v: ItemsGroupItem) => {
                            return v.label
                        }}
                        onChange={optionHandler}
                        isMulti={true}
                        className={"fw"}
                        value={parsedValue}
                        isClearable={undefined}
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputItemsGroupMultiSelect
