import React, { useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { isEmptyObject } from "../../helpers/isEmptyObject"
import ContentForm from "../../components/ContentForm"
import { SaveMenu } from "../../components/UI/SaveMenu"
import { useDispatch, useSelector } from "react-redux"
import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../../store/fields/actions"
import { ItemsGroupRowsChooser } from "./ItemsGroupRowsChooser"
import api from "../../helpers/api"
import AsyncSelect from "react-select/async"
import { preloadListingToAsyncSelect } from "../../helpers/preloadListingToAsyncSelect"
import { useQuery } from "@tanstack/react-query"
import { Uuid } from "types"

export const ItemsGroupDetails: React.FC = () => {
    const history = useHistory()

    const fields: object[] = useSelector(
        (state: { [key: string]: any }) => state.fields.initFields
    )
    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues?.["main"]
    )
    const attributeName: string = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.name
    )

    const location: { [key: string]: any } = useLocation()
    const pageID: string = location.state.pageID || ""

    const dispatch = useDispatch()
    const routerParams: { [key: string]: string } = useParams()

    const title: string = routerParams.id ? "Редактирование" : "Создание"

    const onModifySendData = React.useCallback((data: any) => {
        for (const code in data) {
            // * Clear ID
            if (code === "id") {
                delete data[code]
            }
            if (typeof data[code] === "object" && data[code]?.id) {
                data[code] = data[code].id
            }
        }
    }, [])

    useEffect(() => {
        document.title = title
        dispatch(INIT_STATE_GROUP({ main: {} }))

        dispatch(getFieldsFromServer(`reference/${pageID}/fields`))
        dispatch(
            getCurrentValues(`reference/${pageID}`, routerParams.id, "main")
        )
    }, [routerParams.id])

    const [activeProducer, setActiveProducer] = useState<Uuid | null>(null)
    const activeProducerSelectRef = useRef(null)
    const [selectedGroupAttributes, setSelectedGroupAttributes] = useState<
    Uuid[]
    >([])
    const [linkedAttributes, setLinkedAttributes] = useState<Uuid[]>([])

    const { data: attributes } = useQuery({
        queryKey: ["attributes"],
        queryFn: () =>
            api.getTyped<{ id: Uuid; name: string }[]>("attribute", {}),
        initialData: [],
    })

    useQuery({
        enabled: !!activeProducer,
        queryKey: [
            "items-group-producer",
            routerParams.id,
            activeProducer,
            selectedGroupAttributes,
        ],
        onSuccess: (response) =>
            setLinkedAttributes(response.map(({ attributeId }) => attributeId)),
        queryFn: () =>
            api.getTyped<{ attributeId: Uuid }[]>(
                "attribute/group_producer_attributes_linking_details",
                {
                    group: routerParams.id,
                    producer: activeProducer,
                }
            ),
    })

    useQuery({
        queryKey: ["attributes-by-groups", routerParams.id],
        onSuccess: (response) => {
            setSelectedGroupAttributes(
                response.map(({ attributeId }) => attributeId)
            )
        },
        queryFn: () =>
            api.getTyped<{ attributeId: Uuid }[]>(
                "attribute/linked_items_groups",
                {
                    id: routerParams.id,
                }
            ),
    })

    const groupProducerChooserRows = useMemo(() => {
        if (!activeProducer) return []
        return attributes
            .filter((v) => selectedGroupAttributes.includes(v.id))
            .map(({ id, name: label }) => ({
                id,
                label,
            }))
    }, [activeProducer, attributes, selectedGroupAttributes])

    const handleGroupProducerChecked = (id: Uuid, state: boolean) => {
        api.post(
            `attribute/group_producer_attributes_attach_blueprint/${
                state ? "add" : "remove"
            }`,
            {},
            {
                group: routerParams.id,
                producer: activeProducer,
                attribute: id,
            }
        )

        if (state) {
            return setLinkedAttributes([...linkedAttributes, id])
        }

        return setLinkedAttributes(linkedAttributes.filter((a) => a !== id))
    }

    const handleGroupChecked = (attribute: Uuid, entityId: Uuid) => {
        setSelectedGroupAttributes([...selectedGroupAttributes, attribute])

        return api.post(
            `attribute/lazy-link/group`,
            {},
            { attribute, group: entityId }
        )
    }

    const handleUnGroupChecked = (attribute: Uuid, entityId: Uuid) => {
        setSelectedGroupAttributes(
            selectedGroupAttributes.filter((a) => a !== attribute)
        )
        return api.post(
            `attribute/unlink/group`,
            {},
            { attribute, group: entityId }
        )
    }

    const handleGroupBulk = (state: boolean) => {
        if (state) {
            setSelectedGroupAttributes(attributes.map(({ id }) => id))
            return api.post(
                `v1/attribute/bulk-link/group`,
                {},
                {
                    attributes: attributes
                        .filter(
                            ({ id }) => !selectedGroupAttributes.includes(id)
                        )
                        .map(({ id }) => id),
                    group: routerParams.id,
                }
            )
        }

        api.post(
            `v1/attribute/bulk-unlink/group`,
            {},
            {
                attributes: selectedGroupAttributes,
                group: routerParams.id,
            }
        )
        setSelectedGroupAttributes([])
    }

    const handleGroupProducerBulk = (state: boolean) => {
        if (state) {
            setLinkedAttributes(groupProducerChooserRows.map(({ id }) => id))
            return api.post(
                `v1/attribute/naming/blueprint/bulk-link`,
                {},
                {
                    attributes: groupProducerChooserRows.map(({ id }) => id),
                    group: routerParams.id,
                    producer: activeProducer,
                }
            )
        }

        setLinkedAttributes([])
        api.post(
            `v1/attribute/naming/blueprint/bulk-unlink`,
            {},
            {
                attributes: selectedGroupAttributes,
                group: routerParams.id,
                producer: activeProducer,
            }
        )
    }

    return (
        <>
            <h1 className="h3 mb-3">
                {title}
                {routerParams.id
                    ? ` "${
                          (pageID === "uploaded-attribute" &&
                              routerParams.id) ||
                          attributeName ||
                          "..."
                      }"`
                    : null}
            </h1>
            <SaveMenu url={`reference/${pageID}`} onModify={onModifySendData} />
            <div className="control">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="edit-settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#edit-settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Настройка
                        </button>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    {routerParams.id && (
                                        <div className="row mb-3">
                                            <label className="col-xl-3 col-form-label">
                                                Переключение группы
                                            </label>
                                            <div className="col-xl-9">
                                                <AsyncSelect
                                                    defaultOptions
                                                    placeholder=""
                                                    cacheOptions={false}
                                                    noOptionsMessage={() => (
                                                        <>Не найдено</>
                                                    )}
                                                    loadingMessage={() => (
                                                        <>Загрузка...</>
                                                    )}
                                                    onChange={(v: any) => {
                                                        ;(
                                                            activeProducerSelectRef.current as any
                                                        ).clearValue()

                                                        history.push(
                                                            `/items-group/update-${v.value}`
                                                        )
                                                    }}
                                                    className="mb-2"
                                                    loadOptions={preloadListingToAsyncSelect(
                                                        "items-group"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <h5>Основное</h5>
                                    {isEmptyObject(currentValues) ? (
                                        <div>Загрузка...</div>
                                    ) : (
                                        <div>
                                            <ContentForm
                                                isCreate={!routerParams.id}
                                                group={"main"}
                                                fields={fields}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="control-view__info"></div>

                            {routerParams.id && (
                                <div className="control-view__linking">
                                    <div className="row mt-3 control-view__linking__tables">
                                        <div className="col-6 control-view__linking__tables__item">
                                            <h4 className="linking-headline">
                                                Доступность свойств к текущей
                                                номенклатуре
                                            </h4>

                                            <ItemsGroupRowsChooser
                                                rows={attributes.map(
                                                    ({ id, name: label }) => ({
                                                        id,
                                                        label,
                                                    })
                                                )}
                                                headline="Свойство"
                                                onBulk={handleGroupBulk}
                                                onChecked={(id) =>
                                                    handleGroupChecked(
                                                        id,
                                                        routerParams.id
                                                    )
                                                }
                                                onUnChecked={(id) =>
                                                    handleUnGroupChecked(
                                                        id,
                                                        routerParams.id
                                                    )
                                                }
                                                selected={
                                                    selectedGroupAttributes
                                                }
                                                loading={false}
                                            />
                                        </div>

                                        <div className="col-6 control-view__linking__tables__item">
                                            <h4 className="linking-headline">
                                                Доступность по поставщику
                                            </h4>
                                            <AsyncSelect
                                                defaultOptions
                                                placeholder=""
                                                cacheOptions={false}
                                                noOptionsMessage={() => (
                                                    <>Не найдено</>
                                                )}
                                                loadingMessage={() => (
                                                    <>Загрузка...</>
                                                )}
                                                onChange={(v: any) => {
                                                    if (!v) {
                                                        setActiveProducer(null)
                                                        return
                                                    }

                                                    setActiveProducer(v.value)
                                                }}
                                                className="mb-2"
                                                loadOptions={preloadListingToAsyncSelect(
                                                    "producer"
                                                )}
                                                isClearable
                                                ref={activeProducerSelectRef}
                                            />

                                            {activeProducer ? (
                                                <ItemsGroupRowsChooser
                                                    rows={
                                                        groupProducerChooserRows
                                                    }
                                                    headline="Свойство"
                                                    onBulk={
                                                        handleGroupProducerBulk
                                                    }
                                                    onChecked={(id) => {
                                                        handleGroupProducerChecked(
                                                            id,
                                                            true
                                                        )
                                                    }}
                                                    onUnChecked={(id) => {
                                                        handleGroupProducerChecked(
                                                            id,
                                                            false
                                                        )
                                                    }}
                                                    selected={linkedAttributes}
                                                />
                                            ) : (
                                                "Выберите поставщика"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SaveMenu url={`reference/${pageID}`} onModify={onModifySendData} />
        </>
    )
}
