import { pageIDs } from "../pageConfig"
import { SET_FIELDS } from "../store/fields/actions"
import api from "./api"

const getDispatcher =
    (value: string, fieldName: string, eventName: string, pageID?: string) =>
    (dispatch: any) => {
        const updateListValues = async (value: string) => {
            const { data } = await api.get<any>(
                `${pageIDs.attribute}/fields?type=${value}`
            )

            dispatch(SET_FIELDS(data.fields))
        }

        const uniqEvents: { [key: string]: () => void } = {
            [`${pageIDs.attribute}.datatype.OnChange`]: () =>
                updateListValues(value),
        }

        const eventFunction = `${pageID}.${fieldName}.${eventName}`

        uniqEvents[eventFunction] && uniqEvents[eventFunction]()
    }

export const uniqueEvents: any = (
    event: any,
    eventName: string,
    pageID?: any
) => {
    return getDispatcher(event.target.value, event.target.id, eventName, pageID)
}

export const uniqueEventsAsync: any = (
    value: string,
    fieldName: string,
    eventName: string,
    pageID?: any
) => {
    return getDispatcher(value, fieldName, eventName, pageID)
}
