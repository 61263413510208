import { Spin } from "antd"
import { PropsWithChildren } from "react"
import { Flex } from "./Flex"

export default function Button({
    isLoading,
    children,
    ...props
}: PropsWithChildren<
    {
        isLoading?: boolean
    } & React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
>) {
    return (
        <button
            style={{ position: "relative" }}
            disabled={props.disabled ?? isLoading}
            {...props}
            onClick={isLoading ? undefined : props.onClick}
        >
            <Flex.Col
                align="center"
                justify="center"
                relative
                fullHeight
                fullWidth
                styles={{ opacity: isLoading ? 0 : 1 }}
            >
                {children}
            </Flex.Col>
            <Flex.Col
                align="center"
                justify="center"
                absolute
                top={0}
                left={0}
                fullHeight
                fullWidth
                styles={{ opacity: isLoading ? 1 : 0 }}
            >
                <Spin />
            </Flex.Col>
        </button>
    )
}
