import React from "react"
import { useDispatch } from "react-redux"
import api from "../../helpers/api"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { useNotifications } from "../../hooks/useNotifications"

export const InputCSVFile: React.FC<InputProps> = (props) => {
    const { propertyContainer, textError } = props

    const { showNotification } = useNotifications()

    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const checkMimeType = (mimeType: string) =>
        mimeType === "text/plain" ||
        mimeType === "text/csv" ||
        mimeType === "application/vnd.ms-excel" ||
        mimeType === "application/vnd.oasis.opendocument.spreadsheet" ||
        mimeType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    const sendFile = (file: any) => {
        const fileLimit: number = 20971520 // 20Mb

        if (
            file.size >= fileLimit ||
            file.size === 0 ||
            !checkMimeType(file.type)
        ) {
            showNotification({
                type: "danger",
                message: "Неверный формат файла, или размер!",
            })
            return false
        }

        const formData: any = new FormData()
        formData.append("file", file)

        api.post("file/upload", {}, formData).then((response: any) => {
            dispatch(SET_CURRENT_VALUES({ fileId: response.data.id }, "main"))
        })
    }

    return (
        <>
            <div className="row mb-3">
                <label
                    htmlFor={propertyContainer.getId()}
                    className="col-xl-3 col-form-label"
                >
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <input
                        className={`form-control ${isInvalid}`}
                        type="file"
                        id={propertyContainer.getId()}
                        disabled={propertyContainer.get("readonly")}
                        onChange={(event: any) =>
                            sendFile(event.target.files[0])
                        }
                    />
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}
