import { useVirtualizer } from "@tanstack/react-virtual"
import clsx from "clsx"
import { useEffect, useRef } from "react"
import { TableProps } from "./types"
import { useScrollFinish } from "./useScrollFinish"

type TableRowsProps<T extends object> = Pick<
    TableProps<T>,
    | "columns"
    | "rowStyles"
    | "isRowActive"
    | "onRowClick"
    | "nested"
    | "textAlign"
    | "height"
    | "content"
    | "onScrollFinish"
    | "onScroll"
    | "resetScrollKey"
> & { data: T[] }

export default function VirtualTable<T extends object>({
    columns,
    rowStyles,
    data,
    isRowActive,
    onRowClick,
    nested,
    textAlign,
    height,
    content,
    onScrollFinish,
    onScroll,
    resetScrollKey,
}: TableRowsProps<T>) {
    const gridTemplateColumns = columns
        .map((el) => (el.width ? `${el.width}%` : "auto"))
        .join(" ")

    const parentRef = useRef<HTMLDivElement>(null)

    const rowVirtualizer = useVirtualizer({
        count: data.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35,
        measureElement: (element) => {
            return element.clientHeight
        },
    })

    const initialHeight = height
        ? height
        : data.length * 35 < 600
        ? data.length * 35
        : "auto"

    const { calclulateScroll } = useScrollFinish({ onScrollFinish })

    const items = rowVirtualizer.getVirtualItems()

    useEffect(() => {
        if (resetScrollKey) {
            rowVirtualizer.scrollToIndex(0)
        }
    }, [resetScrollKey, rowVirtualizer])

    return (
        <div
            className="fw"
            ref={parentRef}
            style={{ height: initialHeight, overflow: "auto" }}
            onScroll={(e) => {
                calclulateScroll({
                    containerHeight:
                        typeof initialHeight === "number" ? initialHeight : 0,
                    scrollTop: e.currentTarget.scrollTop,
                    itemsHeight: rowVirtualizer.getTotalSize(),
                })
                if (onScroll) {
                    onScroll()
                }
            }}
        >
            <div
                style={{
                    width: "100%",
                    position: "relative",
                    height: `${rowVirtualizer.getTotalSize()}px`,
                }}
            >
                {items.map((item, itemId) => {
                    const row = data[item.index]
                    const styles = rowStyles ? rowStyles(row) : undefined
                    return (
                        <div
                            className="virtual-table_row-container"
                            key={item.key}
                            data-index={item.index}
                            ref={rowVirtualizer.measureElement}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                transform: `translateY(${item.start}px)`,
                                minHeight: item.size,
                                zIndex:
                                    isRowActive && isRowActive(row)
                                        ? 5
                                        : undefined,
                            }}
                        >
                            <div
                                className="fw"
                                ref={(ref) => {
                                    const height = ref?.clientHeight
                                    if (height) {
                                        rowVirtualizer.resizeItem(
                                            item.index,
                                            height
                                        )
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        gridTemplateColumns,
                                        width: "100%",

                                        ...styles,
                                    }}
                                    className={clsx([
                                        "virtual-table_row",
                                        {
                                            "virtual-table_activeRow":
                                                isRowActive
                                                    ? isRowActive(row)
                                                    : false,
                                        },
                                        {
                                            "virtual-table_pointer":
                                                !!onRowClick,
                                        },
                                        {
                                            "virtual-table_nested": nested
                                                ? nested(row)
                                                : false,
                                        },
                                    ])}
                                    onClick={
                                        onRowClick
                                            ? (e) => {
                                                  const target = e.target as any
                                                  if (
                                                      target.tagName === "INPUT"
                                                  )
                                                      return
                                                  const currentTarget =
                                                      e.currentTarget as any
                                                  currentTarget
                                                      .querySelector(
                                                          "input:not([type='checkbox'])"
                                                      )
                                                      ?.focus()
                                                  onRowClick(row, e)
                                              }
                                            : undefined
                                    }
                                >
                                    {columns.map((column, idx) => {
                                        const value = column.render(row, itemId)
                                        const displayedValue =
                                            typeof value === "boolean"
                                                ? value
                                                    ? "Да"
                                                    : "Нет"
                                                : value
                                        return (
                                            <div
                                                className={clsx([
                                                    "virtual-table_cell",
                                                    column?.className?.(row),
                                                    {
                                                        "virtual-table_cell__isDisabled":
                                                            column.isDisabled,
                                                    },
                                                ])}
                                                style={{
                                                    textAlign:
                                                        textAlign ?? "start",
                                                    ...column.cellStyles,
                                                }}
                                                key={`td-${idx}`}
                                                onMouseOver={(e) => {
                                                    if (!e.ctrlKey) return
                                                    const target =
                                                        e.currentTarget as HTMLElement
                                                    target.classList.add(
                                                        "can-copy"
                                                    )
                                                }}
                                                onMouseLeave={(e) => {
                                                    const target =
                                                        e.currentTarget as HTMLElement
                                                    target.classList.remove(
                                                        "can-copy"
                                                    )
                                                }}
                                                onClick={(e) => {
                                                    if (
                                                        (e.ctrlKey &&
                                                            typeof displayedValue ===
                                                                "string") ||
                                                        typeof displayedValue ===
                                                            "number"
                                                    ) {
                                                        e.stopPropagation()
                                                        navigator.clipboard.writeText(
                                                            displayedValue.toString()
                                                        )
                                                    }
                                                }}
                                            >
                                                {displayedValue}
                                            </div>
                                        )
                                    })}
                                </div>
                                {content &&
                                    content(row) &&
                                    isRowActive &&
                                    isRowActive(row) && (
                                        <div className="virtual-table_content">
                                            {content(row)}
                                        </div>
                                    )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
