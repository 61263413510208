/** SET_USER_SETTINGS */
const initialState = {}

export const savedUserSettings = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "SET_USER_SETTINGS":
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}
