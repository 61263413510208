import { useQuery } from "@tanstack/react-query"
import { Typography } from "antd"
import { QueryGetEnitiesResponse } from "components/QueryBuilder/QueryBuilder"
import { AddItemButton } from "components/UI"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { SET_MEASURE_UNITS } from "store/detailAdditional/actions"
import { RootState } from "store/types"
import { Uuid } from "types"
import { ModelEntity } from "types/api"
import ModelAdditionalDatailItem from "./ModelAdditionalDatailItem"

export type MeasureRulesItem = {
    id: Uuid | null
    attribute: Uuid | null
    value: Uuid | null
}

export type SelectOption = {
    label: string
    value: Uuid
}

const createEmptyItem = (): MeasureRulesItem => {
    return {
        id: null,
        attribute: null,
        value: null,
    }
}

type GetListResponse = Array<{
    attribute: {
        id: Uuid
        name: string
    }
    attributeValue: Record<Uuid, string>
    measureUnit: {
        id: Uuid
        name: string
    }
}>

type SetUnitsProps = (prev: MeasureRulesItem[]) => MeasureRulesItem[]

function ModelAdditionalDatailEdit({ data }: { data: ModelEntity }) {
    const units = useSelector(
        (state: RootState) => state.detailAdditional.measureUnits.units
    )

    const dispatch = useDispatch()

    const setUnits = useCallback(
        (cb: SetUnitsProps) => {
            dispatch(SET_MEASURE_UNITS(cb(units)))
        },
        [dispatch, units]
    )

    const params = useParams() as { id: Uuid }

    const addEmptyItem = useCallback(() => {
        setUnits((prev) =>
            !prev.length
                ? new Array(2).fill(createEmptyItem())
                : [...prev, createEmptyItem()]
        )
    }, [setUnits])

    const { data: attributes } = useQuery<SelectOption[]>({
        queryKey: [params],
        queryFn: () =>
            api
                .getTyped<QueryGetEnitiesResponse>(
                    "v1/model/measure-unit/entities-list",
                    {
                        model: params.id,
                    }
                )
                .then((data) => {
                    return Object.entries(data.items).map(([value, label]) => ({
                        label,
                        value,
                    }))
                }),
        initialData: [],
    })

    useQuery({
        queryFn: () =>
            api.getTyped<GetListResponse>("v1/model/measure-unit/filter", {
                modelId: params.id,
            }),
        onSuccess: (data) => {
            const units: MeasureRulesItem[] = data.map((data) => ({
                id: data.measureUnit.id,
                attribute: data.attribute.id,
                value: Object.keys(data.attributeValue)[0],
            }))
            setUnits(() => (units.length ? units : []))
        },
    })

    const { data: options } = useQuery<SelectOption[]>({
        queryKey: [params.id],
        queryFn: () =>
            api
                .getTyped<Record<Uuid, string>>("v1/model/measure-unit/list")
                .then((response) => {
                    return Object.entries(response).map(([value, label]) => ({
                        label,
                        value,
                    }))
                }),
        initialData: [],
    })

    const onChangeRules = useCallback(
        (
            metricIndex: number,
            type: keyof MeasureRulesItem,
            value: Uuid | null
        ) => {
            setUnits((prev) => {
                const newItems = prev.map((el) => ({ ...el }));
                console.log(newItems);
                console.log(type);
                console.log(value);
                if (!newItems[metricIndex]) {
                    newItems[metricIndex] = createEmptyItem()
                }
                newItems[metricIndex][type] = value
                if (type === "id") {
                    newItems[metricIndex].attribute = null
                    newItems[metricIndex].value = null
                }
                if (type === "attribute") {
                    newItems[metricIndex].value = null
                }
                console.log(newItems);
                return newItems.length ? newItems : []
            })
        },
        [setUnits]
    )

    const removeItem = useCallback(
        (removeItemId: number) => {
            setUnits((prev) => {
                const newItems = prev.filter((_, id) => id !== removeItemId)
                return newItems
            })
        },
        [setUnits]
    )

    const defaultUnits = units?.length ? units : [createEmptyItem()]

    return (
        <Flex.Col fullWidth align="start" styles={{ paddingBottom: 200 }}>
            <Typography.Title level={5}>
                Настройка условий применений других единиц измерения
            </Typography.Title>

            <Flex.Col className="fw" gap={20}>
                {defaultUnits.map((unit, idx) => (
                    <ModelAdditionalDatailItem
                        index={idx}
                        key={idx}
                        data={unit}
                        attributes={attributes}
                        options={options}
                        onChangeRules={onChangeRules}
                        removeItem={removeItem}
                        model={params.id}
                    />
                ))}
            </Flex.Col>

            <AddItemButton onClick={addEmptyItem} />
        </Flex.Col>
    )
}

export default function ModelAdditionalDatail({ data }: { data: ModelEntity }) {
    
    const params = useParams() as { id?: Uuid }

    if (!params.id) return null;

    return <ModelAdditionalDatailEdit data={data} />
}
