import { useQuery } from "@tanstack/react-query"
import { Form } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import { Layout } from "components/layouts"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useDetailData } from "hooks/useDetailData"
import { pageIDs } from "pageConfig"
import { Uuid } from "types"
import { SeriesEnity } from "types/api"
import SeriesDetailPriceSet from "./SeriesDetailPriceSet"

export default function SeriesDetail() {
    const { pageTitle, id } = useDetailData()

    const [form] = useForm<{
        region?: Uuid
        seriesId: Uuid
        seriesName?: string
        group?: Uuid
    }>()

    useQuery({
        queryKey: [id],
        queryFn: () => api.getTyped<SeriesEnity>(`reference/series/${id}`),
        onSuccess: (data) => {
            form.setFieldsValue({
                seriesId: data.id,
                seriesName: data.name,
            })
        },
    })

    const onFinish: FormProps["onFinish"] = (values) => {
        console.log(values)
    }

    return (
        <Layout.Detail pageTitle={pageTitle} pageId={pageIDs.economicSeries}>
            {/* <DetailPageTitle /> */}

            <Flex.Col gap={10} fullWidth>
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="fw"
                    labelCol={{ xl: 6, md: 24 }}
                    labelAlign="left"
                    prefixCls="shyki"
                >
                    <Form.Item label="ID" name="seriesId" className="fw">
                        <input className="fw form-control" disabled />
                    </Form.Item>

                    <Form.Item
                        label="Название"
                        name="seriesName"
                        className="fw"
                    >
                        <input className="fw form-control" disabled />
                    </Form.Item>
                </Form>
            </Flex.Col>
            <SeriesDetailPriceSet />
        </Layout.Detail>
    )
}
