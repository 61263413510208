import { Spin } from "antd"
import { HTMLAttributes, PropsWithChildren } from "react"

interface FlexProps {
    direction?: "row" | "column"
    align?: "center" | "start" | "end"
    justify?: "center" | "start" | "end" | "between"
    fullWidth?: boolean
    fullHeight?: boolean
    gap?: number
    styles?: HTMLAttributes<any>["style"]
    className?: string
    relative?: boolean
    absolute?: boolean
    top?: string | number
    left?: string | number
    right?: string | number
    bottom?: string | number
    margin?: number | number[] | string
    padding?: number | number[] | string
    onClick?: () => void
    loading?: boolean
}

type ComponentProps = PropsWithChildren<FlexProps>

const makeSpacingProperty = (value: number | number[] | string | undefined) => {
    if (typeof value === "number") {
        return `${value}px`
    }

    if (Array.isArray(value)) {
        return value.map((el) => `${el}px`).join(" ")
    }

    return value
}

const Component = (props: PropsWithChildren<FlexProps>) => {
    const {
        align = "center",
        justify = "center",
        direction = "row",
        gap,
        fullWidth,
        fullHeight,
        styles = {},
        className: _className,
        relative,
        absolute,
        top,
        left,
        right,
        bottom,
        margin,
        padding,
        onClick,
        loading,
    } = props

    const directionClassName = `flex-${direction}`
    const alignClassName = `align-${align}`
    const justifyClassName = `justify-${justify}`
    const fullWidthClassName = fullWidth ? "fw" : null
    const fullHeightClassName = fullHeight ? "fh" : null
    const position = relative ? "relative" : absolute ? "absolute" : null

    const classNames = [
        "flex",
        directionClassName,
        alignClassName,
        justifyClassName,
        fullWidthClassName,
        fullHeightClassName,
        _className,
    ]

    if (onClick) {
        classNames.push("cursor-pointer")
    }

    const className = classNames.filter(Boolean).join(" ")

    const collectStyles = {
        top,
        left,
        right,
        bottom,
        position,
        margin: makeSpacingProperty(margin),
        padding: makeSpacingProperty(padding),
    } as Partial<HTMLAttributes<any>["style"]>

    return (
        <div
            className={className}
            style={{ gap, ...collectStyles, ...styles }}
            onClick={onClick}
        >
            {loading ? <Spin style={{ margin: "auto" }} /> : props.children}
        </div>
    )
}

export const Flex = {
    Row: (props: ComponentProps) => <Component {...props} direction="row" />,
    Col: (props: ComponentProps) => <Component {...props} direction="column" />,
}
