import { SelectProps } from "antd"

export const pickListingRecordField = (record: any, field: string) => {
    return record.fields.find((v: { name: string }) => v.name === field)?.value
}

export const filterOption : SelectProps['filterOption'] = (input, option) => {
    return (
        option?.label
            ?.toLocaleString()
            .toLowerCase()
            .includes(input.toLowerCase()) || false
    )
}