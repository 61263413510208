import React from 'react';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_VALUES } from "../../../store/fields/actions";
import TextError from '../../UI/TextError';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export const InputDate: React.FC<any> = ({ name, label, textError }) => {

  const dispatch = useDispatch();

  const [startDate, setStartDate] = React.useState('');

  const isInvalid = textError && "is-invalid";

  return (
    <div className="row mb-2">
      <label htmlFor={name} className="col-xl-2 col-form-label">
        {label}
      </label>
      <div className="col-xl-10">
        <DatePicker
          className={`form-control ${isInvalid}`}
          selected={startDate}
          onChange={(date: any) => {
            setStartDate(date)
            dispatch(SET_CURRENT_VALUES({ [name]: new Date(date).toLocaleString() }, 'filter'));
          }}
          timeInputLabel="Time:"
          dateFormat="dd.MM.yyyy h:mm"
          placeholderText="Выбрать дату"
          showTimeInput
          shouldCloseOnSelect={false}
        />
        {textError && <TextError text={textError} />}
      </div>
    </div>
  );
};
