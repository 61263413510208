import { DeleteFilled } from "@ant-design/icons";
import { Button } from "antd";

export default function RemoveButton ({ onClick } : { onClick: () => void }) {

  return (
    <Button
    onClick={onClick}
    style={{ height: 50, width: 50 }}
    className="flex flex-column flex-justify-center align-center"
>
    <DeleteFilled />
</Button>
  )
}