/** SET_COMPARE_TYPE */
export const SET_COMPARE_TYPE = (payload: any) => ({
    type: "SET_COMPARE_TYPE",
    payload,
})

/** RESET_COMPARE_TYPE */
export const RESET_COMPARE_TYPE = (payload: any) => ({
    type: "RESET_COMPARE_TYPE",
})
