import { Link } from "react-router-dom"
import { Uuid } from "types"

type ObjectColumnData = {
    value: {
        id: Uuid
        name: string
    }
    listing: { [key: string]: any }
}

export default function ObjectColumnCell({
    value: { id, name } = { } as any, listing
}: ObjectColumnData) {
    const href = `series/update-${id}`

    return listing.isLinkToDetail && listing.isLinkToDetail !== "undefined" ? (
        <Link className="" to={href}>
            {name}
        </Link>
    ) : (
        <span>{name}</span>
    )
}
