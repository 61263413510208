import { DeleteFilled, EditFilled } from "@ant-design/icons"
import { Popconfirm, Table, Typography } from "antd"
import type { ColumnsType, TableProps } from "antd/es/table"
import { Flex } from "components/UI/Flex"
import { getFieldValue } from "helpers/getFieldValue"
import { Uuid } from "types"
import type { Entity, Model, SeriesEnity } from "types/api"

type TablePagination = TableProps<Entity>["pagination"]

type FieldKey = keyof Entity["fields"][number]

const getFieldFromFields = (
    fieldName: FieldKey,
    fields: Entity["fields"],
    resolveValue?: FieldKey
) => {
    const field = fields.find((el) => el.name === fieldName)
    return resolveValue ? field?.[resolveValue] : (field?.value as any)
}

export default function KitsList({
    items,
    isLoading,
    pagination,
    editItemId,
    setEditItemId,
    deleteItem,
}: {
    items: Entity[] | undefined
    isLoading: boolean
    pagination: TablePagination
    editItemId: Uuid | null
    setEditItemId: (v: Uuid) => void
    deleteItem?: (v: Uuid) => void
}) {
    const columns: ColumnsType<Entity> = [
        {
            title: "Id",
            key: "id",
            render(_, record) {
                return (
                    <Typography.Text>
                        {getFieldFromFields("id", record.fields)}
                    </Typography.Text>
                )
            },
        },
        {
            title: "Название",
            key: "name",
            render(_, record) {
                return (
                    <Typography.Text>
                        {getFieldFromFields("name", record.fields)}
                    </Typography.Text>
                )
            },
        },
        {
            title: "Серия",
            key: "series",
            render(_, record) {
                const series = getFieldFromFields(
                    "series",
                    record.fields
                ) as SeriesEnity
                return <Typography.Text>{series.name}</Typography.Text>
            },
        },
        {
            title: "Модель",
            key: "model",
            render(_, record) {
                const model = getFieldValue<Model>(
                    record.fields,
                    "model"
                ) as Model
                return <Typography.Text>{model?.name ?? ""}</Typography.Text>
            },
        },
        {
            title: "",
            key: "1",
            render(value, record) {
                return (
                    <Flex.Row gap={20} justify="center">
                        <EditFilled
                            onClick={() =>
                                setEditItemId(record.fields[0].value)
                            }
                        />
                        {deleteItem && (
                            <Popconfirm
                                title="Вы уверены?"
                                onConfirm={() => deleteItem(record.id)}
                                okText="Удалить"
                                cancelText="Отмена"
                            >
                                <DeleteFilled />
                            </Popconfirm>
                        )}
                    </Flex.Row>
                )
            },
        },
    ]

    return (
        <Flex.Col gap={10} fullWidth styles={{ marginTop: 20 }}>
            {!isLoading && !items && (
                <Typography.Text>Нет созданных комплектов</Typography.Text>
            )}
            <Table
                className="fw"
                loading={isLoading}
                rowKey={(record) => record.id}
                dataSource={items}
                columns={columns}
                pagination={pagination}
                onRow={(v) => ({
                    className:
                        v.id === editItemId ? "ant-table-row-selected" : "",
                })}
                rowSelection={{
                    selectedRowKeys: editItemId ? [editItemId] : [],
                    selections: true,
                    type: "radio",
                    renderCell() {
                        return null
                    },
                    getCheckboxProps() {
                        return {
                            disabled: true,
                            value: "checked",
                        }
                    },
                    hideSelectAll: true,
                }}
            />
        </Flex.Col>
    )
}
