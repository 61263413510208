import { Attribute } from "types/api";

const showConditionLinkNames = [
  'Ширина',
  'Высота',
  'Глубина'
]

export const isDiemnsionAttribute = (attribute: Attribute) => {
  return showConditionLinkNames.includes(attribute.name);
}