import { useMutation } from "@tanstack/react-query"
import { Button, Col, Form, Input, Typography } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import cls from "classnames"
import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { useEffect } from "react"
import { Uuid } from "types"

type MassReplaceData = {
    itemsGroup: Uuid
    producer: Uuid
    series?: Uuid
    model?: Uuid
    attributeId: Uuid
    oldValue: string
    newValue: string
}

type MassAttributeModalProps = {
    data: MassReplaceData
    hide: () => void
    activeValue: [string, string]
}

export default function MassAttributeModal(props: MassAttributeModalProps) {
    const [form] = useForm()

    const { data, hide, activeValue } = props

    const { showNotification } = useNotifications()

    const { mutate, isSuccess, isLoading, error, reset } = useMutation({
        mutationFn: (data: MassReplaceData) =>
            api.post(
                "v1/attribute/replace/producer-series-model-nomenclature-value-link",
                {},
                data
            ),
    })

    const onFinish: FormProps["onFinish"] = (values) => {
        mutate({ ...data, ...values })
    }

    useEffect(() => {
        if (error) {
            const _error = error as any
            showNotification({
                type: "danger",
                message: _error.message,
            })
        }
    }, [error])

    useEffect(() => {
        reset()
        form.resetFields()
    }, [activeValue])

    return (
        <Form
            form={form}
            initialValues={{ ...data, newValue: '' }}
            onFinish={onFinish}
            className="mass-attribute__modal"
        >
            <Col
                className={cls({
                    "mass-attribute__modal__content": true,
                    "mass-attribute__modal__content__visible": !isSuccess,
                    "mass-attribute__modal__content__hidden": isSuccess,
                })}
            >
                <Typography.Title level={5}>
                    Свойство к замене:
                </Typography.Title>
                <Typography.Title
                    level={5}
                    className="mass-attribute__modal__value"
                >
                    {activeValue[1]}
                </Typography.Title>

                <Form.Item label="Новое значение" name="newValue">
                    <Input type="text" />
                </Form.Item>
                <Button
                    loading={isLoading}
                    className="mass-attribute__modal__button"
                    type="primary"
                    htmlType="submit"
                >
                    Сохранить
                </Button>
            </Col>

            <Col
                className={cls({
                    "mass-attribute__modal__content": true,
                    "mass-attribute__modal__content__visible": isSuccess,
                    "mass-attribute__modal__content__hidden": !isSuccess,
                })}
            >
                <Typography.Text>
                    Свойство{" "}
                    <span className="mass-attribute__modal__value">
                        {activeValue[1]}
                    </span>{" "}
                    заменено
                </Typography.Text>
                <Button
                    loading={isLoading}
                    className="mass-attribute__modal__button"
                    type="primary"
                    onClick={hide}
                >
                    Закрыть
                </Button>
            </Col>
        </Form>
    )
}
