import { SelectEntityState, StateValue } from "components/Selects/types"
import { Uuid } from "types"
import { create } from "zustand"
import { GetAttributeValuesListingItem } from "./AttributeValuesPricesForm"

interface IAttributeValuesPrices {
    activeModel: SelectEntityState["value"]
    setActiveModel: SelectEntityState["setValue"]
    activeSeries: SelectEntityState["value"]
    setActiveSeries: SelectEntityState["setValue"]
    activeGroup: SelectEntityState["value"]
    setActiveGroup: SelectEntityState["setValue"]
    activeProducer: SelectEntityState["value"]
    setActiveProducer: SelectEntityState["setValue"]
    applyed: StateValue<boolean>["value"]
    setApplyed: StateValue<boolean>["setValue"]
    activeValues: Uuid[]
    setActiveValues: (data: Uuid[]) => void
    toggleActiveValue: (data: Uuid) => void
    activeListingValue: GetAttributeValuesListingItem | null
    setActiveListingValue: (v: GetAttributeValuesListingItem | null) => void
}

export const useAttributeValuesPricesStore = create<IAttributeValuesPrices>(
    (set) => ({
        activeModel: null,
        setActiveModel(v) {
            return set((state) => ({
                ...state,
                activeModel: v,
                activeValues: [],
            }))
        },
        activeSeries: null,
        setActiveSeries(v) {
            return set((state) => ({
                ...state,
                activeSeries: v,
                activeValues: [],
            }))
        },
        activeGroup: null,
        setActiveGroup(v) {
            return set((state) => ({
                ...state,
                activeGroup: v,
                activeValues: [],
            }))
        },
        activeProducer: null,
        setActiveProducer(v) {
            return set((state) => ({
                ...state,
                activeProducer: v,
                activeValues: [],
            }))
        },
        applyed: false,
        setApplyed(v) {
            return set((state) => ({
                ...state,
                applyed: v,
                activeValues: v ? state.activeValues : [],
            }))
        },
        activeValues: [],
        setActiveValues(v) {
            return set((state) => ({
                ...state,
                activeValues: v,
            }))
        },
        toggleActiveValue(v) {
            return set((state) => {
                return {
                    ...state,
                    activeValues: state.activeValues.includes(v)
                        ? state.activeValues.filter((el) => el !== v)
                        : [...state.activeValues, v],
                }
            })
        },
        activeListingValue: null,
        setActiveListingValue(v) {
            return set((state) => ({
                ...state,
                activeListingValue: v,
                activeValues: v ? v.values.map((el) => Object.keys(el)[0]) : [],
            }))
        },
    })
)
