import { Popover } from "antd"
import clsx from "clsx"
import React, { useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { Uuid } from "types"

interface AttributesChooserProps {
    values: { [id: Uuid]: string }
    onChange?: (id: Uuid) => void
    onUseCheckbox?: (id: Uuid, state: boolean) => void
    active?: Uuid
    withCheckboxes: boolean
    checked?: Uuid[]
    onBulk?: (state: boolean) => void
    onChangeAllToAll?: React.Dispatch<React.SetStateAction<string[]>>
    allToAllValues?: Uuid[]
}

const useStyles = createUseStyles({
    valueCell: {
        width: "100%",
        border: "1px solid #dee2e6",
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "none",
    },
    headlineCell: {
        width: "100%",
    },
    searchCell: {
        width: "100%",
    },
    searchLabel: {
        paddingLeft: 15,
    },
    tableBody: {
        height: "450px",
        display: "block",
    },
    searchNotFound: {
        textAlign: "center",
    },
    searchBoxInput: {
        width: "100%",
        outline: "none",
        border: "1px solid #dee2e6",
        borderBottom: "none",
        padding: "5px 10px",
    },
    activeRow: {
        background: "#eee",
    },
    row: {
        width: "100%",
        display: "flex",
        borderTop: "none",
    },
})

export const AttributesValuesChooser: React.FC<AttributesChooserProps> = ({
    values,
    ...props
}) => {
    const classes = useStyles()
    const [search, setSearch] = useState("")

    values = Object.keys(values)
        .sort((a, b) =>
            values[a]
                .toLowerCase()
                .trim()
                .localeCompare(values[b].toLowerCase().trim())
        )
        .reduce((prev, curr) => ({ ...prev, [curr]: values[curr] }), {})

    const filteredRows = useMemo(
        () =>
            Object.keys(values).filter((index) => {
                if (search === "") return true
                return values[index]
                    .toLowerCase()
                    .includes(search.toLowerCase())
            }),
        [values]
    )

    const isAllChosen = useMemo(
        () =>
            !filteredRows.filter((value) => !props?.checked?.includes(value))
                .length,
        [props?.checked, values]
    )

    return (
        <>
            <div>
                <input
                    className={classes.searchBoxInput}
                    type="text"
                    value={search}
                    placeholder="Поиск"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <table
                className="table table-bordered table-users"
                style={{
                    display: "block",
                    width: "100%",
                }}
            >
                <thead
                    style={{
                        width: "100%",
                        display: "inline-table",
                    }}
                >
                    <tr>
                        <th>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isAllChosen}
                                style={{
                                    opacity: props.withCheckboxes ? 1 : 0,
                                }}
                                onChange={(e) =>
                                    props.onBulk &&
                                    props.onBulk(e.target.checked)
                                }
                            />
                        </th>

                        <th className={classes.headlineCell}>Значение</th>
                        {props.onChangeAllToAll && props.allToAllValues && (
                            <Popover title="Все со всем" placement="top">
                                <th
                                    className={classes.headlineCell}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (!props.onChangeAllToAll) return
                                        props.onChangeAllToAll((prev) => {
                                            return prev.length
                                                ? []
                                                : [...filteredRows]
                                        })
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={!!props.allToAllValues?.length}
                                    />
                                </th>
                            </Popover>
                        )}
                    </tr>
                </thead>
                <tbody className={classes.tableBody}>
                    {filteredRows.map((index) => (
                        <tr
                            key={index}
                            onClick={() =>
                                props.onChange && props.onChange(index)
                            }
                            className={clsx(
                                classes.row,
                                props.active === index && classes.activeRow
                            )}
                        >
                            <td>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{
                                        opacity: props.withCheckboxes ? 1 : 0,
                                    }}
                                    checked={props.checked?.some(
                                        (id) => id === index
                                    )}
                                    onChange={(e) =>
                                        props.onUseCheckbox &&
                                        props.onUseCheckbox(
                                            index,
                                            e.target.checked
                                        )
                                    }
                                />
                            </td>
                            <td
                                className={classes.valueCell}
                                style={{
                                    cursor: props.onChange
                                        ? "pointer"
                                        : "default",
                                }}
                            >
                                {values[index]}
                            </td>
                            {props.onChangeAllToAll && props.allToAllValues && (
                                <td
                                    className={classes.valueCell}
                                    style={{
                                        cursor: props.onChange
                                            ? "pointer"
                                            : "default",
                                        width: "auto",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        props.onChangeAllToAll!((prev) => {
                                            return prev.includes(index)
                                                ? prev.filter(
                                                      (el) => el !== index
                                                  )
                                                : [...prev, index]
                                        })
                                    }}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={props.allToAllValues?.includes(
                                            index
                                        )}
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
