import React from 'react';

const PageNotFound: React.FC = () => {
  return (
    <div className="layout-404">
      <h1>404</h1>
      <h2>Страница не найдена</h2>
      <p>
        К сожалению, такой страницы нет на сайте.<br />
        Возможно, вы ввели неправильный адрес<br />
        или страница была удалена с сервера.
      </p>
    </div>
  );
};

export default PageNotFound;
