import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_VALUES } from "../../../../store/fields/actions";
import CompareTypeSelector from '../../filterComponents/CompareTypeSelector';
import RangeInputs from './RangeInputs';
import { Uuid } from 'types';

interface InputTextProps {
  id: Uuid;
  name: string;
  type: string;
  onClickHandler?: any;
}

export const InputText: React.FC<InputTextProps> = ({ id, name, type, onClickHandler }) => {
  const dispatch = useDispatch();
  const value = useSelector((state: { [key: string]: any }) => state.fields.currentValues['attributeFilter']?.[id]) || '';
  const compareType = useSelector((state: { [key: string]: any }) => state.compareType?.[id]) || '';
  const currentValuesAttributeFilter = useSelector(
    (state: { [key: string]: any }) => state.fields?.currentValues.attributeFilter
  );

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onClickHandler();
    }
  }

  const handleChange = (event: any) => { 
    if (event.target.value.length === 0){
      const copy = currentValuesAttributeFilter
      delete copy[id]
      dispatch(SET_CURRENT_VALUES(copy, 'attributeFilter'))
    } else {
      dispatch(SET_CURRENT_VALUES({ [id]: event.target.value }, 'attributeFilter'))
    }
  }
  
  const renderOptions = (compareType: string) => {
    if (compareType === 'range') {
      return <RangeInputs name={id} onClickHandler={onClickHandler} />
    }
     else if (compareType !== 'not_empty' && compareType !== '!not_empty') {
      return (
        <div className="col-xl-8">
              <input
                className="form-control"
                type={type === 'number' ? 'number' : 'text'}
                id={id}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
                onKeyUp={handleKeyPress}
              />
            </div>
      )
    }
    else return null
  }

  return (
    <div className="row mb-2">
      <label htmlFor={id} className="col-xl-2 col-form-label">
        {name}
      </label>
      <div className="col-xl-10">
        <div className="row">
          <div className="col-xl-4">
              <CompareTypeSelector name={id} inputType={type} filterType='attributeFilter'/>
          </div>
          {renderOptions(compareType)}
        </div>
      </div>
    </div>
  );
};
