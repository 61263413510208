import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import { usePaginationHelper } from '../../../hooks/usePaginationHelper';
import { SET_SELECT_STATUS } from '../../../store/selected/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

export const Pagination = ({ apiPath }: any) => {
  const dispatch = useDispatch();
  const resolveRoute = usePaginationHelper();
  const paginationSettings = useSelector((state: { [key: string]: any }) => state.listing?.pagination) || {};

  const onForcePageChange = () => {
    if (typeof paginationSettings.currentPage === 'number' && typeof paginationSettings.pageSize === 'number') {
      return paginationSettings.currentPage - 1
    }
  }

  const history = useHistory();
  const location = useLocation()

  const onPageChange = ({ selected } : { selected: number }) => {
    dispatch(SET_SELECT_STATUS(false));
    const page = selected + 1;
    const searchParams = new URLSearchParams(location.search); // создаем объект для работы с параметрами запроса
    searchParams.set('page', `${page}`); // устанавливаем новое значение параметра
    history.push({ search: '?' + searchParams.toString() })
  }

  const pageNumber = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlPage = searchParams.get('page');
    return urlPage ? +urlPage : null;
  }, [location.search])

  useEffect(() => {
    if (location.search && paginationSettings) {

      const currentPage = paginationSettings.currentPage;
      if (pageNumber && pageNumber !==  currentPage) {
        resolveRoute(apiPath, pageNumber, paginationSettings.pageSize)
      }
    }
  }, [pageNumber])
 
  return (
    <nav 
    aria-label="Navigation"
    className={paginationSettings.allPages <= 1 || isEmptyObject(paginationSettings) ? 'd-none' : ''}
    >
      <ReactPaginate
        previousLabel={'Назад'}
        nextLabel={'Вперед'}
        pageCount={Math.ceil(paginationSettings.totalCount / (paginationSettings.pageSize === null ? 20 : paginationSettings.pageSize))}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        activeClassName={'active'}
        breakClassName="page-item"
        breakLabel={<button className="page-link">...</button>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        initialPage={onForcePageChange()}
        forcePage={onForcePageChange()}
        onPageChange={onPageChange}
      />
    </nav>
  )
}
