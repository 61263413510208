import { Col, Row } from "antd"
import { AsyncSelect, Text } from "components/UI"
import { Flex } from "components/UI/Flex"
import { Uuid } from "types"
import { SelectEntity } from "./types"

interface GroupSelectProps {
    activeGroup?: SelectEntity
    setActiveGroup: (v?: SelectEntity) => void
    required?: boolean
    layout?: "vertical" | "horizontal"
    skipValues?: Uuid[]
    maxWidth?: number
    label?: string
    placeholder?: string
    maxHeight?: number
    labelAdditionalContent?: JSX.Element
}

export function GroupSelect({
    activeGroup,
    setActiveGroup,
    required,
    layout = "horizontal",
    skipValues,
    maxWidth,
    label = "Номенклатурная группа",
    placeholder = "Выберите группу",
    maxHeight,
    labelAdditionalContent,
}: GroupSelectProps) {
    const labelSpan = layout === "horizontal" ? 5 : 24
    const inputSpan = layout === "horizontal" ? 19 : 24

    return (
        <Row gutter={[0, 10]} style={{ width: "100%", maxWidth, maxHeight }}>
            <Col xs={24} md={labelSpan}>
                <Flex.Row fullWidth gap={10} justify="start">
                    <Text.FormLabel required>{label}</Text.FormLabel>
                    {labelAdditionalContent}
                </Flex.Row>
            </Col>
            <Col xs={24} md={inputSpan}>
                <AsyncSelect
                    placeholder={placeholder}
                    value={activeGroup}
                    isClearable
                    onChange={(v) => setActiveGroup(v || undefined)}
                    dataUrl="items-group"
                    required={required}
                    skipValues={skipValues}
                />
            </Col>
        </Row>
    )
}
