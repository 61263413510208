import api from "../../helpers/api"

/** GOODS */
export const GET_GOODS = (payload: any) => ({
    type: "GET_GOODS",
    payload,
})

/** THUNK */
export const getGoods: any = (url: string) => {
    return async (dispatch: any) => {
        await api
            .get(url)
            .then((response) => {
                dispatch(GET_GOODS(response.data))
            })
            .catch((error) => {
                console.log(error)
            })
    }
}
