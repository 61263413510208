import { useQuery } from "@tanstack/react-query"
import { Form, FormItemProps, Select } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { filterOption } from "components/inputs/helpers"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { ListingResponse } from "types/api"
import { SelectProps } from "."

type CurrencySelect = SelectProps<{
    name: string
    label: string
    rules?: FormItemProps["rules"]
}>

export function CurrencyFormSelect({
    rules,
    layout = "horizontal",
    labelSpan,
    inputSpan,
    formLabelFontSize,
    fullWidth,
    containerClassName,
    getValueProps,
    name,
    style,
    label,
    ...rest
}: CurrencySelect) {
    const { data, isFetching } = useQuery<DefaultOptionType[]>({
        queryKey: ["get-currency"],
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/currency", {
                    method: "asc",
                    sort: "name",
                    count_on_page: Number.MAX_SAFE_INTEGER,
                })
                .then((response) => {
                    return response.entities.map(
                        (el) =>
                            ({
                                label: `[${getFieldValue(
                                    el.fields,
                                    "code"
                                )}] ${getFieldValue(el.fields, "name")}`,
                                value: getFieldValue(el.fields, "id"),
                            } as DefaultOptionType)
                    )
                }),
        initialData: [],
    })

    return (
        <Form.Item name={name} label={label} style={style} rules={rules}>
            <Select
                loading={isFetching}
                showSearch
                filterOption={filterOption}
                className="fw"
                placeholder="Выберите валюту"
                options={data}
                allowClear
                {...rest}
            />
        </Form.Item>
    )
}
