import { DeleteOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Button, Spin, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useEffect, useMemo } from "react"
import type {
    Field,
    QueryBuilderProps,
    RuleGroupType,
} from "react-querybuilder"
import QueryBuilder from "react-querybuilder"
import { Uuid } from "types"
import { QueryGetEnitiesResponse } from "./QueryBuilder"
import classes from "./QueryBuilder.module.sass"
import { QueryBuilderItemData } from "./types"

export default function QueryBuilderItem<T>({
    removeItem,
    renderItem,
    data,
    controlElements,
    onQueryChange,
    onDataChange,
    index,
    translations,
    getBuilderEntitiesData,
    noEntitiesText,
    queryItemClassName,
    showDataByFilter,
    context,
    editItemId,
}: {
    data: QueryBuilderItemData<T>
    index: number
    renderItem?: (props: {
        data: QueryBuilderItemData<T>
        index: number
        onDataChange: (index: number, key: keyof T, value: any) => void
    }) => JSX.Element
    removeItem: (index: number) => void
    controlElements?: QueryBuilderProps["controlElements"]
    onQueryChange: (query: RuleGroupType, index: number) => void
    onDataChange: (index: number, key: keyof T, value: any) => void
    showDataByFilter?: (props: {
        data: QueryBuilderItemData<T>
        editItemId?: Uuid | null
    }) => JSX.Element
    translations: QueryBuilderProps["translations"]
    getBuilderEntitiesData?: { url: string; params?: Record<string, string> }
    noEntitiesText: string
    queryItemClassName?: string
    context?: QueryBuilderProps["context"]
    editItemId?: Uuid | null
}) {
    const { url: getBuilderEntitiesDataUrl, params } =
        getBuilderEntitiesData || {}

    const {
        data: queryBuilderData,
        isLoading,
        remove,
    } = useQuery<QueryGetEnitiesResponse>({
        queryKey: [getBuilderEntitiesDataUrl, params],
        enabled: !!getBuilderEntitiesDataUrl,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api.getTyped<QueryGetEnitiesResponse>(
                getBuilderEntitiesDataUrl!,
                params
            ),
    })

    useEffect(() => {
        if (!getBuilderEntitiesDataUrl) {
            remove()
        }
    }, [getBuilderEntitiesDataUrl])

    const { combinators, operators, items } = queryBuilderData || {}

    const fields: Field[] = useMemo(() => {
        if (!items) return []
        return Object.entries(items).map(
            ([key, value]) =>
                ({
                    name: key,
                    label: value,
                } as Field)
        )
    }, [items])

    return (
        <div className={classes.ItemContainer}>
            <div className={queryItemClassName ?? classes.ItemContent}>
                {renderItem && renderItem({ data, index, onDataChange })}

                <div className={classes.queryBuilder}>
                    {queryBuilderData && (
                        <QueryBuilder
                            context={context}
                            translations={translations}
                            combinators={combinators}
                            operators={operators}
                            fields={fields}
                            controlElements={controlElements}
                            query={data.query}
                            onQueryChange={(q) => onQueryChange(q, index)}
                        />
                    )}
                    {!getBuilderEntitiesDataUrl && (
                        <Typography.Text>{noEntitiesText}</Typography.Text>
                    )}
                    {isLoading && !!getBuilderEntitiesDataUrl && <Spin />}
                </div>

                <Flex.Col
                    fullHeight
                    align="center"
                    justify="center"
                    className={classes.addEmptyItem}
                >
                    <Button
                        onClick={() => removeItem(index)}
                        style={{ height: 50, width: 50 }}
                        className="flex flex-column flex-justify-center align-center"
                    >
                        <DeleteOutlined />
                    </Button>
                </Flex.Col>

                {showDataByFilter &&
                    showDataByFilter({ data: { ...data }, editItemId })}
            </div>
        </div>
    )
}
