import { useMutation } from "@tanstack/react-query"
import { Button, Form, Input } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { formLayout } from "helpers/formLayout"
import { InputOptionType, Uuid } from "types"

type CreateGroupRequest = {
    name: string
}

type CreateGroupResponse = {
    id: Uuid
}

export default function NomeclatureFiltersCreateItem({
    onCreate,
}: {
    onCreate: (v: InputOptionType) => void
}) {
    const [form] = useForm<CreateGroupRequest>()

    const createGroup = useMutation({
        mutationFn: (data: CreateGroupRequest) =>
            api.post<CreateGroupRequest, CreateGroupResponse>(
                `v1/price-nomenclature-filters-group/create`,
                {},
                data
            ),
        onSuccess: ({ data }) => onCreate({
            label: form.getFieldValue('name'),
            value: data.id
        }),
    })

    const onFinish: FormProps["onFinish"] = (values) => {
        createGroup.mutateAsync(values)
    }

    return (
        <Flex.Col fullWidth gap={20} margin={[20, 0, 20]}>
            <Form
                form={form}
                className="fw"
                {...formLayout}
                onFinish={onFinish}
            >
                <Form.Item name="name" label="Название группы">
                    <Input className="form-control" />
                </Form.Item>

                <Button
                    type="default"
                    loading={createGroup.isLoading}
                    htmlType="submit"
                >
                    Создать группу
                </Button>
            </Form>
        </Flex.Col>
    )
}
