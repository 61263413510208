import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ru from "antd/locale/ru_RU"
import { Listing } from "components/Listing"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import api from "helpers/api"
import AttributeValuesPrices from "pages/AttributeValuesPrices"
import { Availability } from "pages/Availability"
import CalculationRules from "pages/CalculationRules"
import { GeneralConfigurator } from "pages/Configuration/GeneralConfigurator"
import GeneralConfiguratorWrapper from "pages/Configuration/GeneralConfigurator/GeneralConfiguratorWrapper"
import { CostCollector } from "pages/CostCollector"
import DeliveryTime from "pages/DeliveryTime"
import { Economic } from "pages/Economic"
import Kits from "pages/Kits"
import MassAttributeReplace from "pages/MassAttributeReplace/MassAttributeReplace"
import ModelAttributeValueCriteria from "pages/ModelAttributeValueCriteria"
import PricesRecalculate from "pages/PricesRecalculate"
import StandardKitDetail from "pages/StandardKitDetail/StandardKitDetail"
import Test from "pages/Test"
import { useCallback, useEffect, useLayoutEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Toaster } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch, useHistory } from "react-router-dom"
import { allowedPages } from "routes/allowedPages"
import { catalogRoutes } from "routes/Catalogs.routes"
import { economicRoutes } from "routes/Economic.routes"
import { CreateRoute } from "routes/tools"
import { LocationState } from "routes/withHistory"
import type { RootState } from "store/types"
import { fetchUser } from "store/user/actions"
import Home from "./components/Home"
import Login from "./components/Login"
import PageNotFound from "./components/PageNotFound"
import { NotificationWrapper } from "./hooks/useNotifications"
import { pageIDs } from "./pageConfig"
import { AccessControlPage } from "./pages/AccessControlPage"
import { Attributes } from "./pages/Attributes"
import { AttributeValuesCompatibility } from "./pages/Configuration/AttributeValuesCompatibility"
import { ModelsAttributesValuesBinding } from "./pages/Configuration/ModelsAttributesValuesBinding"
import { Detail } from "./pages/Detail"
import { ItemsGroupDetails } from "./pages/ItemsGroup/ItemsGroupDetails"
import MassGeneration from "./pages/Nomenclature/MassGeneration"
import { NomenclatureDetail } from "./pages/Nomenclature/NomenclatureDetail"
import { PicturesGeneratorDemo } from "./pages/Pictures"
import { PicturesMassUploader } from "./pages/Pictures/MassUploader"
import { ReadyPictureConfigurator } from "./pages/Pictures/ReadyPictureConfigurator"
import PicturesSettings from "./pages/Pictures/Settings"
import { ProducerDetails } from "./pages/Producer/ProducerDetails"
import FatalError from "./partials/FatalError"
import Header from "./partials/Header"
import Loader from "./partials/Loader"
import Menu from "./partials/Menu"
import PrivateRoute from "./PrivateRoute"
import "./styles/global.sass"

dayjs.locale("ru")

const App = () => {
    const dispatch = useDispatch()
    const { pending, user, error } = useSelector(
        (state: RootState) => state.user
    )

    const history = useHistory()

    const region = useSelector((state: RootState) => state.region.region)

    useLayoutEffect(() => {
        api.setRegion(region?.value || null)
    }, [api, region])

    const escFunction = (event: KeyboardEvent) => {
        if (
            event.key === "Escape" ||
            event.key === "Esc" ||
            event.key === "escape" ||
            event.key === "esc"
        ) {
            const evt = new Event("Escape")
            window.dispatchEvent(evt)
        }
    }

    useEffect(() => {
        if (!!error) return
        if ((!user || !user?.credentials) && !pending) {
            dispatch(fetchUser())
        }
        document.addEventListener("keydown", escFunction, false)
        return () => {
            document.removeEventListener("keydown", escFunction, false)
        }
    }, [user, dispatch, pending, fetchUser, error])

    const setLocationState = useCallback(
        (data: LocationState) => {
            history.location.state = data
        },
        [history]
    )

    if (pending)
        return (
            <div style={{ width: "100%", paddingTop: 400 }}>
                <Loader />
            </div>
        )

    if (error)
        return (
            <Login
                onLogin={() => {
                    dispatch(fetchUser())
                }}
            />
        )

    if (history.location.pathname === "/configurator-iframe") {
        return <GeneralConfiguratorWrapper />
    }

    return (
        <ConfigProvider locale={ru}>
            <Toaster />
            <Menu credentials={user?.credentials ?? []} />

            <main className="main">
                <Header />
                <div className="content">
                    <NotificationWrapper>
                        <Switch>
                            <Route exact path={"/"} component={Home} />

                            <PrivateRoute
                                exact
                                path={`/${pageIDs.role}`}
                                apiPath={`listing/${pageIDs.role}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.role}`]}
                                pageIds="role"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.region}`}
                                apiPath={`listing/${pageIDs.region}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.region}`]}
                                pageIds="region"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.role}/update-:id`,
                                    `/${pageIDs.role}/create`,
                                ]}
                                component={AccessControlPage}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.role}/update`,
                                    `${pageIDs.role}/create`,
                                ]}
                                pageIds="role"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.user}`}
                                apiPath={`listing/${pageIDs.user}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.user}`]}
                                pageIds="user"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.user}/update-:id`,
                                    `/${pageIDs.user}/create`,
                                ]}
                                component={AccessControlPage}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.user}/update`,
                                    `${pageIDs.user}/create`,
                                ]}
                                pageIds="user"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.group}`}
                                apiPath={`listing/${pageIDs.group}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.group}`]}
                                pageIds="group"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.group}/update-:id`,
                                    `/${pageIDs.group}/create`,
                                ]}
                                component={ItemsGroupDetails}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.group}/update`,
                                    `${pageIDs.group}/create`,
                                ]}
                                pageIds="group"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.modelAttribute}`}
                                apiPath={`listing/${pageIDs.modelAttribute}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.modelAttribute}`]}
                                pageIds="modelAttribute"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.modelAttribute}/update-:id`,
                                    `/${pageIDs.modelAttribute}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.modelAttribute}/update`,
                                    `${pageIDs.modelAttribute}/create`,
                                ]}
                                pageIds="modelAttribute"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.producer}`}
                                apiPath={`listing/${pageIDs.producer}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.producer}`]}
                                pageIds="producer"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.producer}/update-:id`,
                                    `/${pageIDs.producer}/create`,
                                ]}
                                component={ProducerDetails}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.producer}/update`,
                                    `${pageIDs.producer}/create`,
                                ]}
                                pageIds="producer"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.model}`}
                                apiPath={`listing/${pageIDs.model}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.model}`]}
                                pageIds="model"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.model}/update-:id`,
                                    `/${pageIDs.model}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.model}/update`,
                                    `${pageIDs.model}/create`,
                                ]}
                                pageIds="model"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.currency}/update-:id`,
                                    `/${pageIDs.currency}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.currency}/update`,
                                    `${pageIDs.currency}/create`,
                                ]}
                                pageIds="currency"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.currency}`}
                                apiPath={`listing/${pageIDs.currency}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.currency}`]}
                                pageIds="currency"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.priceType}/update-:id`,
                                    `/${pageIDs.priceType}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.priceType}/update`,
                                    `${pageIDs.priceType}/create`,
                                ]}
                                pageIds="priceType"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.priceType}`}
                                apiPath={`listing/${pageIDs.priceType}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.priceType}`]}
                                pageIds="priceType"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.region}/update-:id`,
                                    `/${pageIDs.region}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.region}/update`,
                                    `${pageIDs.region}/create`,
                                ]}
                                pageIds="region"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.nomenclature}`}
                                apiPath={`listing/${pageIDs.nomenclature}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.nomenclature}`]}
                                pageIds="nomenclature"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.nomenclature}/update-:id`,
                                    `/${pageIDs.nomenclature}/create`,
                                ]}
                                component={NomenclatureDetail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.nomenclature}/update`,
                                    `${pageIDs.nomenclature}/create`,
                                ]}
                                pageIds="nomenclature"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.nomenclature}/mass-generation`}
                                component={MassGeneration}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.nomenclature}/mass-generation`,
                                ]}
                                pageIds="nomenclature"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.series}`}
                                apiPath={`listing/${pageIDs.series}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.series}`]}
                                pageIds="series"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.series}/update-:id`,
                                    `/${pageIDs.series}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.series}/update`,
                                    `${pageIDs.series}/create`,
                                ]}
                                pageIds="series"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.okrb}`}
                                apiPath={`listing/${pageIDs.okrb}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.okrb}`]}
                                pageIds="okrb"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.okrb}/update-:id`,
                                    `/${pageIDs.okrb}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.okrb}/update`,
                                    `${pageIDs.okrb}/create`,
                                ]}
                                pageIds="okrb"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.tnved}`}
                                apiPath={`listing/${pageIDs.tnved}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.tnved}`]}
                                pageIds="tnved"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.tnved}/update-:id`,
                                    `/${pageIDs.tnved}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.tnved}/update`,
                                    `${pageIDs.tnved}/create`,
                                ]}
                                pageIds="tnved"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.classificationAccordance}`}
                                apiPath={`listing/${pageIDs.classificationAccordance}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.classificationAccordance}`,
                                ]}
                                pageIds="classificationAccordance"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.classificationAccordance}/update-:id`,
                                    `/${pageIDs.classificationAccordance}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.classificationAccordance}/update`,
                                    `${pageIDs.classificationAccordance}/create`,
                                ]}
                                pageIds="classificationAccordance"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.uploadedAttribute}`}
                                apiPath={`listing/${pageIDs.uploadedAttribute}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.uploadedAttribute}`,
                                ]}
                                pageIds="uploadedAttribute"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.uploadedAttribute}/update-:id`,
                                    `/${pageIDs.uploadedAttribute}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.uploadedAttribute}/update`,
                                    `${pageIDs.uploadedAttribute}/create`,
                                ]}
                                pageIds="uploadedAttribute"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.tagPage}`}
                                apiPath={`listing/${pageIDs.tagPage}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.tagPage}`]}
                                pageIds="tagPage"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.tagPage}/update-:id`,
                                    `/${pageIDs.tagPage}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.tagPage}/update`,
                                    `${pageIDs.tagPage}/create`,
                                ]}
                                pageIds="tagPage"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.attribute}`}
                                apiPath={`listing/${pageIDs.attribute}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.attribute}`]}
                                pageIds="attribute"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.attribute}/update-:id`,
                                    `/${pageIDs.attribute}/create`,
                                ]}
                                component={Attributes}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.attribute}/update`,
                                    `${pageIDs.attribute}/create`,
                                ]}
                                pageIds="attribute"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.good}`}
                                apiPath={`listing/${pageIDs.good}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.good}`]}
                                pageIds="good"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.category}`}
                                apiPath={`listing/${pageIDs.category}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.category}`]}
                                pageIds="category"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/configuration/${pageIDs.modelsAttributesValuesBindingConfigurator}`,
                                ]}
                                apiPath={`configuration/${pageIDs.modelsAttributesValuesBindingConfigurator}`}
                                component={ModelsAttributesValuesBinding}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `configuration/${pageIDs.modelsAttributesValuesBindingConfigurator}`,
                                ]}
                                pageIds="modelsAttributesValuesBindingConfigurator"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/configuration/${pageIDs.attributeValuesCompatibilityConfigurator}`,
                                ]}
                                apiPath={`configuration/${pageIDs.attributeValuesCompatibilityConfigurator}`}
                                component={AttributeValuesCompatibility}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `configuration/${pageIDs.attributeValuesCompatibilityConfigurator}`,
                                ]}
                                pageIds="attributeValuesCompatibilityConfigurator"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/configuration/${pageIDs.generalConfigurator}`,
                                ]}
                                apiPath={`/configuration/${pageIDs.generalConfigurator}`}
                                component={GeneralConfiguratorWrapper}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `configuration/${pageIDs.generalConfigurator}`,
                                ]}
                                pageIds="generalConfigurator"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/configuration/${pageIDs.generalConfigurator}`,
                                ]}
                                apiPath={`/configuration/${pageIDs.generalConfigurator}`}
                                component={GeneralConfigurator}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.generalConfigurator}`,
                                ]}
                                pageIds="generalConfigurator"
                            />
                            <PrivateRoute
                                exact
                                path={`/pictures/${pageIDs.pictureDemo}`}
                                component={PicturesGeneratorDemo}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `pictures/${pageIDs.pictureDemo}`,
                                ]}
                                pageIds="pictureDemo"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/pictures/${pageIDs.readyPicturesConfigurator}`,
                                ]}
                                component={ReadyPictureConfigurator}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `pictures/${pageIDs.readyPicturesConfigurator}`,
                                ]}
                                pageIds="readyPicturesConfigurator"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/pictures/${pageIDs.pictureMassUploader}`,
                                ]}
                                component={PicturesMassUploader}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `pictures/${pageIDs.pictureMassUploader}`,
                                ]}
                                pageIds="pictureMassUploader"
                            />
                            <PrivateRoute
                                exact
                                path={`/pictures/${pageIDs.picturesSettings}`}
                                component={PicturesSettings}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `pictures/${pageIDs.picturesSettings}`,
                                ]}
                                pageIds="picturesSettings"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.deliveryTime}`}
                                apiPath={`listing/${pageIDs.deliveryTime}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.deliveryTime}`]}
                                pageIds="deliveryTime"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.deliveryTime}/update-:id`,
                                    `/${pageIDs.deliveryTime}/create`,
                                ]}
                                component={DeliveryTime}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.deliveryTime}/update`,
                                    `${pageIDs.deliveryTime}/create`,
                                ]}
                                pageIds="deliveryTime"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.kits}`}
                                component={Kits}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.kits}`]}
                                pageIds="kits"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.massAttributeResplace}`}
                                component={MassAttributeReplace}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.massAttributeResplace}`,
                                ]}
                                pageIds="massAttributeResplace"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.economicSeries}`}
                                apiPath={`listing/${pageIDs.series}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.series}`]}
                                pageIds="series"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.costCollector}`}
                                component={CostCollector}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.costCollector}`]}
                                pageIds="costCollector"
                            />
                            <PrivateRoute
                                exact
                                path={[`/${pageIDs.economicSeries}/update-:id`]}
                                component={Economic.Series}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.economicSeries}/update`,
                                ]}
                                pageIds="economicSeries"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.economicProducer}`}
                                apiPath={`listing/${pageIDs.producer}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.economicProducer}`,
                                ]}
                                pageIds="producer"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.economicProducer}/update-:id`,
                                ]}
                                component={Economic.Procuer}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.economicProducer}/update`,
                                ]}
                                pageIds="economicProducer"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.economicNomeclatureFilters}`}
                                apiPath={`listing/${pageIDs.economicNomeclatureFilters}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.economicNomeclatureFilters}`,
                                ]}
                                pageIds="economicNomeclatureFilters"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.economicNomeclatureFilters}/update-:id`,
                                    `/${pageIDs.economicNomeclatureFilters}/create`,
                                ]}
                                component={Economic.NomenclatureFilters}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.economicNomeclatureFilters}/update`,
                                    `${pageIDs.economicNomeclatureFilters}/create`,
                                ]}
                                pageIds="economicNomeclatureFilters"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.availabilityNomenclatureFilters}`}
                                apiPath={`listing/${pageIDs.availabilityNomenclatureFilters}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.availabilityNomenclatureFilters}`,
                                ]}
                                pageIds="availabilityNomenclatureFilters"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.availabilityNomenclatureFilters}/update-:id`,
                                    `/${pageIDs.availabilityNomenclatureFilters}/create`,
                                ]}
                                component={Availability.NomenclatureFilters}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.availabilityNomenclatureFilters}/update`,
                                    `${pageIDs.availabilityNomenclatureFilters}/create`,
                                ]}
                                pageIds="availabilityNomenclatureFilters"
                            />
                            <PrivateRoute
                                exact
                                path={`/economic/${pageIDs.priceMarginPreset}`}
                                apiPath={`listing/${pageIDs.priceMarginPreset}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `economic/${pageIDs.priceMarginPreset}`,
                                ]}
                                pageIds="priceMarginPreset"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/economic/${pageIDs.priceMarginPreset}/update-:id`,
                                    `/economic/${pageIDs.priceMarginPreset}/create`,
                                ]}
                                component={Detail}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `economic/${pageIDs.priceMarginPreset}/update`,
                                    `economic/${pageIDs.priceMarginPreset}/create`,
                                ]}
                                pageIds="priceMarginPreset"
                            />
                            <PrivateRoute
                                exact
                                path={`/economic/${pageIDs.calculationRules}`}
                                apiPath={`listing/${pageIDs.calculationRules}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `economic/${pageIDs.calculationRules}`,
                                ]}
                                pageIds="calculationRules"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/economic/${pageIDs.calculationRules}/create`,
                                    `/economic/${pageIDs.calculationRules}/update-:id`,
                                ]}
                                component={CalculationRules}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `economic/${pageIDs.calculationRules}/create`,
                                    `economic/${pageIDs.calculationRules}/update`,
                                ]}
                                pageIds="calculationRules"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.modelAttributeValueCriteria}`}
                                apiPath={`listing/${pageIDs.modelAttributeValueCriteria}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.modelAttributeValueCriteria}`,
                                ]}
                                pageIds="modelAttributeValueCriteria"
                            />
                            <PrivateRoute
                                exact
                                path={[
                                    `/${pageIDs.modelAttributeValueCriteria}/update-:id`,
                                    `/${pageIDs.modelAttributeValueCriteria}/create`,
                                ]}
                                component={ModelAttributeValueCriteria}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.modelAttributeValueCriteria}/update`,
                                    `${pageIDs.modelAttributeValueCriteria}/create`,
                                ]}
                                pageIds="modelAttributeValueCriteria"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.pricesRecalculate}`}
                                component={PricesRecalculate}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.pricesRecalculate}`,
                                ]}
                                pageIds="pricesRecalculate"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.attributeValuesPrices}`}
                                component={AttributeValuesPrices}
                                credentials={user?.credentials}
                                requiredPageIDs={[
                                    `${pageIDs.attributeValuesPrices}`,
                                ]}
                                pageIds="attributeValuesPrices"
                            />
                            <PrivateRoute
                                exact
                                path={`/${pageIDs.standardKit}`}
                                apiPath={`listing/${pageIDs.standardKit}`}
                                component={Listing}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.standardKit}`]}
                                pageIds="standardKit"
                            />
                            <PrivateRoute
                                exact
                                path={[`/${pageIDs.standardKit}/update-:id`]}
                                component={StandardKitDetail}
                                credentials={user?.credentials}
                                requiredPageIDs={[`${pageIDs.standardKit}`]}
                                pageIds="standardKit"
                            />
                            <Route
                                exact
                                path={["/test"]}
                                render={() => <Test />}
                            />

                            {[...economicRoutes, ...catalogRoutes].map(
                                (el, idx) => {
                                    const Element = CreateRoute({
                                        ...el,
                                        credentials: user?.credentials
                                            ? [
                                                  ...user.credentials,
                                                  ...allowedPages,
                                              ]
                                            : [...allowedPages],
                                    })
                                    return Element({
                                        key: idx,
                                        setLocationState: setLocationState,
                                    })
                                }
                            )}

                            <Route component={PageNotFound} />
                        </Switch>
                    </NotificationWrapper>
                    <FatalError />
                </div>
            </main>
        </ConfigProvider>
    )
}

const AppWrapper = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    })
    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => {
                    const hashCode = (s: string) => {
                        for (var i = 0, h = 0; i < s.length; i++)
                            h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
                        return h
                    }

                    return (
                        <div
                            style={{ paddingLeft: "5rem", paddingTop: "5rem" }}
                        >
                            <h2>Ой! Произошла ошибка интерфейса</h2>
                            <h4 style={{ opacity: 0.7 }}>
                                Об этой ошибке необходимо сообщить
                                разработчикам,
                                <br />а пока они ее не исправили - можно{" "}
                                <a
                                    href="#"
                                    onClick={() => {
                                        window.location.reload()
                                    }}
                                    style={{ textDecoration: "none" }}
                                >
                                    перезагрузить
                                </a>{" "}
                                страницу.
                            </h4>
                            <pre style={{ color: "red", marginTop: 25 }}>
                                Path: {window.location.pathname}
                                <br />
                                Hash: {hashCode(error.message)}
                                <br />
                                <br />
                                {error.message}
                            </pre>
                        </div>
                    )
                }}
            >
                <App />
            </ErrorBoundary>
        </QueryClientProvider>
    )
}

export default AppWrapper
