import { Form } from "antd"
import UNumberItem from "components/UNumberItem"
import { Flex } from "../../components/UI/Flex"

export default function CurrencyRates() {
    return (
        <>
            <Form.Item label="Курс" className="noMargin" style={{ order: 9 }}>
                <Flex.Row gap={10} className="fw" justify="start">
                    <UNumberItem
                        floatRank={4}
                        useInForm={{
                            name: "inputCurrencyRate",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />

                    <UNumberItem
                        floatRank={0}
                        useInForm={{
                            name: "inputCurrencyAmount",
                            label: "Кратность",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />
                </Flex.Row>
            </Form.Item>

            <Form.Item label="Курс" className="noMargin" style={{ order: 12 }}>
                <Flex.Row gap={10} className="fw" justify="start">
                    <UNumberItem
                        floatRank={4}
                        useInForm={{
                            name: "outputCurrencyRate",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />

                    <UNumberItem
                        floatRank={0}
                        useInForm={{
                            name: "outputCurrencyAmount",
                            label: "Кратность",
                            rules: [
                                {
                                    required: true,
                                    message: "Заполните поле",
                                },
                            ],
                        }}
                    />
                </Flex.Row>
            </Form.Item>
        </>
    )
}
