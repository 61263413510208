/** GOODS */
export const goods = (state: any[] = [], action: any) => {
    switch (action.type) {
        case "GET_GOODS":
            return [...action.payload]

        default:
            return state
    }
}
