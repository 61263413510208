import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGoods } from '../../store/goods/actions';
import { Required } from '../UI/Required';
import TextError from '../UI/TextError';
import { SET_CURRENT_VALUES } from "../../store/fields/actions";
import { InputProps } from "../../types/interfaces";

/**
 * @param relatedIDs - ID товаров которые связаны с текущим товаром
 */

export const InputCollection: React.FC<InputProps> = (props) => {
  const { propertyContainer, textError, group } = props;

  const state = useSelector((state: { [key: string]: any }) => state);
  const relatedIDs = state.fields.currentValues[group]?.[propertyContainer.getName()] || [''];

  const dispatch = useDispatch();

  const isInvalid = textError && "is-invalid";

  const clickHandler = () => dispatch(getGoods('listing/good'));

  const parsedValues = relatedIDs && relatedIDs.map((item: number) => {
    return (
      <div className="row" key={item}>
        <div className="col direct-breadcrumbs">
          <input
            className={`form-control ${isInvalid}`}
            type={(propertyContainer.getType() === 'multipleNumber' ? 'number' : 'text')}
            id={`${item}`}
            value={item}
            name={`${propertyContainer.getName()}[${item}]`}
            placeholder={propertyContainer.get('placeholder')}
            disabled={true}
          />
          {textError && <TextError text={textError} />}
        </div>
        <div className="col-xl-2">
          <div className="buttons-nowrap">
            <div>
              <button type="button" className="btn btn-outline-primary " data-bs-toggle="dropdown" >...</button>
              <ul className="dropdown-menu" >
                <li className="dropdown-item"
                  onClick={() => {
                    const checkedItem = relatedIDs.filter((el: number) => el !== item);
                    dispatch(SET_CURRENT_VALUES({ relatedGoods: [...checkedItem] }));
                  }}>Удалить</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="row mb-3">
        <label htmlFor={propertyContainer.getId()} className="col-xl-3 col-form-label">
          {propertyContainer.get('required') ? <Required /> : null}
          {propertyContainer.get('label')}
        </label>
        <div className="col-xl-9">
          {parsedValues}
          <button onClick={clickHandler} type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addGoods">
            {relatedIDs.length ? 'Еще' : 'Добавить'}
          </button>
        </div>
      </div>

    </>
  );
};
