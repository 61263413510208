import { useQuery } from "@tanstack/react-query"
import { Empty, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useMemo } from "react"
import { Uuid } from "types"

interface LogData {
    id: Uuid
    name: string
    itemsGroup: string
    producer: string
    series: string
    model: string
    props: string
    value: string
}

interface LogError {
    message: string
    values: Array<string | number>
}

const testErrors: LogError[] = [
    {
        values: ["BLR"],
        message:
            "Ошибка обновления реквизитов номенклатуры [018bdccd-7eef-7c85-ad06-06f891c804f2]",
    },
    {
        values: ["BLR"],
        message:
            "Ошибка обновления реквизитов номенклатуры [018bdccd-8d8b-7620-a9c6-54e6800bd9a6]",
    },
]

export default function RequesitesLogItem({ logId }: { logId?: Uuid }) {
    const { data: logData, isFetching } = useQuery({
        queryKey: ["v1/nomenclature/props/log", logId],
        enabled: !!logId,
        queryFn: () =>
            api.getTyped<{
                logs: LogData[]
                errors: LogError[]
            }>(["v1/nomenclature/props/log", logId].join("/")),
    })

    const errorString = useMemo(() => {
        if (!logData?.logs.length && !logData?.errors?.length) return ""
        if (logData?.logs.length && !logData?.errors.length) return "Ошибок нет"
        return "Ошибки:"
    }, [logData])

    return (
        <Flex.Col fullWidth loading={isFetching} gap={10}>
            {!logData?.logs?.length && <Empty description="Нет данных" />}
            {!!logData?.logs?.length && (
                <UTable
                    data={logData.logs}
                    VIRTUAL_ROW_COUNT={10}
                    height={
                        logData.logs?.length > 10
                            ? 350
                            : logData.logs.length * 35
                    }
                    columns={[
                        {
                            columnName: "Группа",
                            render: (row) => row.itemsGroup,
                            width: 15,
                        },
                        {
                            columnName: "Поставщик",
                            render: (row) => row.producer,
                            width: 15,
                        },
                        {
                            columnName: "Серия",
                            render: (row) => row.series,
                            width: 15,
                        },
                        {
                            columnName: "Модель",
                            render: (row) => row.model,
                            width: 15,
                        },
                        {
                            columnName: "Номенклатура",
                            render: (row) => row.name,
                            width: 15,
                        },
                        {
                            columnName: "Реквизит",
                            render: (row) => row.props,
                            width: 15,
                        },
                        {
                            columnName: "Значение",
                            render: (row) => row.value,
                        },
                    ]}
                />
            )}
            {errorString && <Typography.Text>{errorString}</Typography.Text>}

            {!!logData?.errors?.length && (
                <UTable
                    data={logData.errors}
                    columns={[
                        {
                            columnName: "Ошибка",
                            render: (row) => row.message,
                            width: 50,
                        },
                        {
                            columnName: "",
                            render: (row) => row.values.join("; "),
                            width: 50,
                        },
                    ]}
                />
            )}
        </Flex.Col>
    )
}
