import { Switch, Tabs } from "antd"
import { NomenclatureFiltersTable } from "components/tables/NomenclatureFilters.Table"
import { PriceMarginPresetTable } from "components/tables/PriceMarginPreset.Table"
import { Flex } from "components/UI/Flex"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { Uuid } from "types"
import { useCalculationRulesStore } from "./CalculationRules.store"

export default function CalculationRulesTables() {
    const {
        nomenclatureFilters,
        initialNomenclatureFilters,
        priceCalculationRulePriceMarginPresetLink,
        initialPriceCalculationRulePriceMarginPresetLink,
        toggleNomenclatureFilter,
        togglePriceCalculationRule,
        onChangeCalculationRuleOrder,
        onChangeDoCalulateFromBasePrice,
    } = useCalculationRulesStore()

    const { id } = useParams() as { id?: Uuid }

    const isEditPage = !!id

    const [activeKey, setActiveKey] = useState<string>("1")
    const [showAll, setShowAll] = useState(!isEditPage)

    return (
        <Flex.Col fullWidth relative>
            {isEditPage && (
                <Flex.Row
                    fullWidth
                    absolute
                    left={0}
                    top={0}
                    styles={{ minHeight: 50 }}
                    justify="end"
                    gap={10}
                >
                    <Switch
                        size="default"
                        checked={showAll}
                        onChange={(e) => setShowAll(e)}
                        style={{ zIndex: 10 }}
                    ></Switch>
                    <span>Отображать всё</span>
                </Flex.Row>
            )}
            <Tabs
                activeKey={activeKey}
                onChange={(v) => setActiveKey(v)}
                className="fw"
                items={[
                    {
                        key: "1",
                        label: "Список фильтров",
                        children: (
                            <NomenclatureFiltersTable
                                selectedRows={nomenclatureFilters}
                                initialData={initialNomenclatureFilters}
                                onRowClick={toggleNomenclatureFilter}
                                showAll={showAll}
                            />
                        ),
                    },
                    {
                        key: "2",
                        label: "Список наценок",
                        children: (
                            <PriceMarginPresetTable
                                selectedRows={
                                    priceCalculationRulePriceMarginPresetLink
                                }
                                onRowClick={togglePriceCalculationRule}
                                onChangeOrder={onChangeCalculationRuleOrder}
                                onChangeDoCalulateFromBasePrice={
                                    onChangeDoCalulateFromBasePrice
                                }
                                initialData={
                                    initialPriceCalculationRulePriceMarginPresetLink
                                }
                                showAll={showAll}
                            />
                        ),
                    },
                ]}
            ></Tabs>
        </Flex.Col>
    )
}
