import { useQuery } from "@tanstack/react-query"
import { Form, Input, InputNumber } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import clsx from "clsx"
import { Layout } from "components/layouts"
import FieldSelector from "components/QueryBuilder/FieldSelector"
import QueryBuilderResult from "components/QueryBuilder/QueryBuilderResult"
import QueryFormField from "components/QueryBuilder/QueryFormFIeld"
import { RegionFormSelect } from "components/Selects/newSelects/RegionFormSelect"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { formLayout } from "helpers/formLayout"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import { useSaveOrAcceptProps } from "hooks/useSaveOrAccept"
import { pageIDs } from "pageConfig"
import { useCallback, useRef, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { useHistory, useParams } from "react-router-dom"
import { InputOptionType, Uuid } from "types"
import { Country } from "types/api"
import NomeclatureFiltersCreateItem from "./NomeclatureFiltersCreateGroup"
import NomenclatureFiltersValueEditor from "./NomenclatureFiltersValueEditor"

type CreateOrEditFilterData = {
    parentGroup: Uuid
    name: string
    numericCode: number
    payload: {
        query: RuleGroupType<any>
        id?: Uuid
    }
    scope?: string | string[]
}

type GetFilterDataResponse = {
    filter: {
        id: Uuid
        name: string
        numericCode: number
        query?: {
            id: Uuid
            query?: RuleGroupType<any>
        }
        scope?: Country[]
    }
}

function NomeclatureFilters({ showMessage }: WithShowMessage) {
    const [showCreateGroup, setShowCreateGroup] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<null | InputOptionType>(
        null
    )
    const [query, setQuery] = useState<RuleGroupType<any>>()
    const { isLoading, withLoading } = useSaveOrAcceptProps()
    const params = useParams()
    const history = useHistory()
    const { id: itemId } = params as { id?: Uuid }
    const [form] = useForm<{
        parentGroup: InputOptionType
        name: string
        numericCode: number
        scope: string | string[]
        id?: Uuid
        query?: RuleGroupType<any>
    }>()
    const redirectRef = useRef<boolean>(true)

    useQuery<GetFilterDataResponse>({
        queryKey: [itemId],
        enabled: !!itemId,
        queryFn: () =>
            api.getTyped<GetFilterDataResponse>(
                `v1/availability-nomenclature-filter/${itemId}`
            ),
        onSuccess: (data) => {
            const { filter } = data
            form.setFieldsValue({
                name: filter.name,
                numericCode: filter.numericCode,
                query: filter.query,
                scope: filter.scope
                    ? filter.scope.map((el) => el.id)
                    : undefined,
            })
            setQuery(
                filter?.query?.query
                    ? {
                          ...filter.query?.query,
                          rules: filter.query.query.rules ?? [],
                      }
                    : undefined
            )
        },
    })

    const mutations = {
        create: (requestData: CreateOrEditFilterData) =>
            api.post(
                "v1/availability-nomenclature-filter/create",
                {},
                requestData
            ),
        update: (requestData: CreateOrEditFilterData) =>
            api.put(
                `v1/availability-nomenclature-filter/update/${itemId}`,
                {},
                requestData
            ),
    }

    const onFinish: FormProps["onFinish"] = (values) => {
        if (!query) return
        const requestData: CreateOrEditFilterData = {
            parentGroup: selectedGroup?.value || "",
            payload: {
                query: query,
                id: itemId,
            },
            ...values,
        }

        const mutatationKey: keyof typeof mutations = itemId
            ? "update"
            : "create"
        const mutate = mutations[mutatationKey]
        mutate(requestData)
            .then(() => {
                if (redirectRef.current) {
                    setTimeout(() => {
                        history.push(
                            `/${pageIDs.availabilityNomenclatureFilters}`
                        )
                    }, 3000)
                }
                showMessage({
                    type: "success",
                    content: "Успешно",
                })
            })
            .catch((error) => {
                showMessage({
                    type: "error",
                    content: error.message,
                })
            })
    }

    const onSave = useCallback((redirect: boolean) => {
        redirectRef.current = redirect
        form.submit()
    }, [])

    return (
        <Layout.Detail
            pageTitle="Создать фильтр доступности номенклатуры"
            pageId={pageIDs.availabilityNomenclatureFilters}
            onSaveProps={{
                onSave: () => onSave(true),
                disabled: !query,
            }}
            onAcceptProps={{
                onAccept: () => onSave(false),
                disabled: !query,
            }}
        >
            <Flex.Col gap={10} fullWidth>
                <Form
                    form={form}
                    className="fw"
                    {...formLayout}
                    onFinish={onFinish}
                >
                    <Flex.Col gap={10} fullWidth>
                        <Flex.Col
                            fullWidth
                            className={clsx({
                                "--visible": showCreateGroup,
                                "--hidden": !showCreateGroup,
                            })}
                        >
                            <NomeclatureFiltersCreateItem
                                onCreate={(data) => {
                                    setSelectedGroup(data)
                                    setShowCreateGroup(false)
                                }}
                            />
                        </Flex.Col>

                        <Form.Item
                            label="Название"
                            className="fw"
                            name={"name"}
                        >
                            <Input size="large" className="fw" />
                        </Form.Item>

                        <Form.Item
                            label="Код"
                            className="fw"
                            name={"numericCode"}
                        >
                            <InputNumber size="large" className="fw" />
                        </Form.Item>

                        <RegionFormSelect
                            className="fw"
                            isMultiple
                            name="scope"
                        />

                        <Form.Item label="Фильтр" name={"query"} className="fw">
                            <QueryFormField
                                query={query}
                                getEntitiesURL="v1/query-builder/price-nomenclature-filter/entities-list"
                                controlElements={{
                                    valueEditor: NomenclatureFiltersValueEditor,
                                    fieldSelector: FieldSelector,
                                }}
                                onQueryChange={(q) => {
                                    setQuery({
                                        ...q,
                                        rules: q?.rules ?? [],
                                    })
                                }}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                            />
                        </Form.Item>
                        <Flex.Row fullWidth gap={20}>
                            <QueryBuilderResult
                                dataUrl="v1/query-builder/price-nomenclature-filter/result"
                                query={query}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </Form>
            </Flex.Col>
        </Layout.Detail>
    )
}

export default withMessage(NomeclatureFilters)
